import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import loan from '../image/OurService/loan.png';
import income from '../image/OurService/income.png';

const OurService = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: 'column', direction: i18n.language === "fa" ? "rtl" : "ltr", backgroundColor: "background.secondary", padding: "5%" }}>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", padding: '20px' }}>
          <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "18px", sm: "20px", md: "40px" } : { xs: "16px", sm: "20px", md: "38px" }, color: "text.primary" }}>{t("ourServiceTitle")}</Typography>
        </Grid>
        <Grid sx={{ width: { xs: "95%", md: "50%" }, borderBottom: "1px solid #FF9254" }}></Grid>
        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "flex-start", sm: 'center' }, flexDirection: "column", width: { xs: "100%", md: "85%", lg: '60%' } }}>
          <Grid sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" }, flexDirection: "row", width: { sm: "95%", md: "80%" }, margin: "10px", alignItems: 'center' }}>
            <Typography component={"img"} alt="loan" src={loan} sx={{ display: { xs: "none", sm: "flex" }, width: { xs: "10vh", sm: "20vh", md: "20%" } }} />
            <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", margin: { xs: "15px", sm: "40px" } }}>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "16px", md: "32px" } : { xs: "16px", md: "32px" }, color: "text.primary" }}>{t("ourServiceTitle1")}</Typography>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "20px" } : { xs: "11px", md: "20px" }, textAlign: "justify", color: "text.primary" }}>{t("ourServiceContent1")}</Typography>
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-end" }, flexDirection: "row", width: { sm: "95%", md: "80%" }, margin: "10px", alignItems: 'center' }}>
            <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", margin: { xs: "15px", sm: "40px" } }}>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "16px", md: "32px" } : { xs: "16px", md: "32px" }, color: "text.primary" }}>{t("ourServiceTitle2")}</Typography>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "20px" } : { xs: "11px", md: "20px" }, textAlign: "justify", color: "text.primary" }}>{t("ourServiceContent2")}</Typography>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "20px" } : { xs: "11px", md: "20px" }, textAlign: "justify", color: "text.primary" }}>{t("ourServiceContent3")}</Typography>
            </Grid>
            <Typography component={"img"} alt="income" src={income} sx={{ display: { xs: "none", sm: "flex" }, width: { xs: "10vh", sm: "20vh", md: "22%" } }} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default OurService;