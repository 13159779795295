import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Checkbox, IconButton, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { passValidate } from "./modules/validate";
import AlertMessage from "./modules/AlertMessage";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import signinIcon from '../image/register/signin.png';
import { baseUrl } from "./modules/general";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import ModalPass from "./modules/ModalPass";
import { setCanonicalTag } from "./modules/general";

const LoginPass = () => {

  const params = useParams();
  const [captcha, setCaptcha] = useState(false);
  const { t, i18n } = useTranslation();
  const [pass, setPass] = useState("");
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const [showPass, setShowPass] = useState(false);
  const [security, setSecurity] = useState(0);
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});
  const [data, setData] = useState({ secturity: "", mobile: "", email: "", pass: "" });
  const [loading, setLoading] = useState(false);
  const [loginBtn, setLoginBtn] = useState(false);
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const Navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  });

  useEffect(() => {
    document.title = "خرید و فروش رمزارز | کریپتوکامرس"
    document.getElementById("main-heading").innerHTML = "خرید - فروش -رمزارز- کریپتوکامرس";
    document.getElementsByTagName('meta')["description"].content = "";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  function resizeTextFiled() {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }
  useEffect(() => {
    getInfo(params);
  }, [params])

  const passHandeler = (event) => {
    setPass(event.target.value);
    setErrors(passValidate(event.target.value, t))
  }
  const onChangeRechapta = (value) => {
    value != null ? setCaptcha(true) : setCaptcha(false)
  }

  const handleClickShowPass = () => setShowPass((show) => !show);
  const handleMouseDownPass = (event) => {
    event.preventDefault();
  }

  const getInfo = (params) => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/user/get?email=" + params.email,
      method: "GET",
    }))
      .then((res) => {
        setInfo(res.data.data);
        setSecurity(res.data.data.security_id)
      })
  }

  const postData = () => {
    let sendData = { email: params.email, pass: pass }
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/user/login",
      method: "POST",
      data: sendData,
    }))
      .then((res) => {
        localStorage.setItem('TACC', res.data.data.access_token);
        Navigate("/");
        window.location.reload();
        setLoginBtn(false);
        setLoading(false);
      })
      .catch(
        (error) => {
          setLoginBtn(false);
          setLoading(false);
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
          }
        }
      )
  }
  const submitHandler = () => {
    if ((pass === "") || (errors.hasOwnProperty('pass'))) {
      setAlert({ alertCheck: true, message: t("AM_fieldPass"), severity: "error" })
    }
    else if (captcha === false) {
      setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
    }
    else if ((!errors.hasOwnProperty('pass')) && (captcha === true)) {
      if (security === 1) {
        setLoginBtn(true);
        setLoading(true);
        postData();
      }
      else if ((security === 2) || (security === 3) || (security === 4)) {
        setData({ security: security, mobile: info.mobile, email: params.email, pass: pass });
        setOpen(true);
      }
    }
  }

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true })
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };
  const handleCloseModal = () => {
    setOpen(false);
  }
  return (
    <>
      <Grid sx={{
        direction: i18n.language === "fa" ? "rtl" : "ltr", width: "100%", height: "100%", justifyContent: "center",
        display: "flex", flexDirection: { xs: "column", sm: "row" }, alignContent: "center", padding: { xs: "160px 0", sm: "260px 0", md: "230px 0" }, backgroundColor: "background.main"
      }}>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, height: "100%", display: "flex", justifyContent: { xs: "center", md: "end" } }}>
          <Grid sx={{
            bgcolor: "background.secondary", maxWidth: { xs: "95%", sm: "306px", md: "478px" }, minWidth: { xs: "95%", sm: "280px", md: "390px" }, height: '100%',
            boxShadow: "1px 3px 6px rgba(0, 0, 0, 0.25)", borderRadius: { xs: "44px", sm: "50px", md: "15%" }, padding: { xs: "0", sm: "10% 0", md: "5% 0" }
          }}>
            <Grid sx={{ display: "flex", flexDirection: "row", padding: "10% 0 5%", alignItems: "center", justifyContent: "center" }}>
              <Typography component={"p"} sx={{
                fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px", md: "25px" } : { xs: "17px", md: "15px", lg: "20px" }, color: "text.primary"
              }}>{t("titleLogin")}
              </Typography>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px", md: "25px" } : { xs: "17px", md: "15px", lg: "20px" },
                color: "text.greenDark", margin: i18n.language === "fa" ? "0 5px 0 0" : "0 0 0 5px"
              }}>{t("mcc")}
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", padding: "5% 0 0" }} >
              <TextField
                className="textField"
                fullWidth
                type={showPass ? 'text' : 'password'}
                name="pass"
                value={pass}
                onChange={event => passHandeler(event)}
                onFocus={focusHandler}
                label={t("password")}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                variant="outlined"
                size={textFieldSize}
                error={errors.pass && touched.pass}
                helperText={errors.pass && touched.pass && errors.pass}
                sx={{ "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" }, minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" } }}
                InputProps={{
                  endAdornment:
                    <IconButton
                      aria-label="toggle pass visibility"
                      onClick={handleClickShowPass}
                      onMouseDown={handleMouseDownPass}
                      edge="end"
                    >
                      {showPass ? <VisibilityIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} /> : <VisibilityOffIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} />}
                    </IconButton>
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", margin: "5% 0" }} className="g-recaptcha-inner">
              <ReCAPTCHA
                sitekey="6Le7R9UUAAAAAFklnUhPVV2qiSfeLXJHpDgnv6V3"
                onChange={(value) => { onChangeRechapta(value) }}
                className="g-recaptcha-login"
                hl="fa"
              />

            </Grid>
            <Grid sx={{ margin: "0 0 5%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button sx={{
                width: { xs: "50%", sm: "173px", md: "270px" }, height: { xs: "41px", md: "63px" }, background: (theme) => theme.palette.button.primary, borderRadius: "40px !important",
                "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.3)"
              }}
                onClick={submitHandler} disabled={loginBtn ? true : false}>
                <Typography component={"p"} sx={{ fontSize: { xs: "11px", sm: "13px", md: "20px" }, color: "text.white", lineHeight: "18px", padding: { xs: "5px", sm: "10px" } }}>
                  {loginBtn === true && loading === true ? <CircularProgress size={20} color="inherit" /> : t("login")}
                </Typography>
              </Button>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "0 0 10%" }}>
              <NavLink to={`/ForgotPassword/${params.email}`} className="loginNav" >
                <Typography component={"p"} sx={{
                  fontSize: i18n.language === "fa" ? { xs: "8px", sm: "10px", md: "14px" } : { xs: "13px", sm: "11px" }, color: "text.greenDark", borderBottom: "1px solid #0C5463"
                }}>{t("forgetPass")}
                </Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, display: "flex", justifyContent: { xs: "center", md: "start" }, margin: { xs: "0px", md: "0px 30px" } }}>
          <Grid sx={{
            width: { xs: "100%", sm: "98%", md: "95%", lg: "80%" }, display: "flex", padding: { xs: "30px 0px", sm: "0px 2%", md: "20px 5%", lg: "30px" },
            flexDirection: "column", alignItems: "center"
          }}>
            <Grid sx={{ width: "100%", display: { xs: "none", sm: "flex" }, alignItems: "center", justifyContent: "center" }}>
              <Typography component={"img"} alt="signin_Icon" src={signinIcon} sx={{ maxWidth: { sm: "200px", md: "200px" }, maxHeight: { sm: "200px", md: "200px" }, minWidth: { sm: "180px", md: "200px" }, minHeight: { sm: "180px", md: "200px" } }} />
            </Grid>
            <Grid sx={{
              display: "flex", flexDirection: "column", alignItems: "start", minWidth: { xs: "95%", sm: "220px", md: "360px" }, maxWidth: { xs: "95%", sm: "328px", md: "512px" },
              borderRadius: { xs: "18px", sm: "25px", md: "35px" }, maxHeight: { xs: "160px", sm: "174px", md: "272px" }, backgroundColor: "background.secondary", boxShadow: ' 1px 3px 6px rgba(0, 0, 0, 0.25)',
              padding: { xs: i18n.language === "fa" ? "20px 10px 20px 20px" : "20px 20px 10px 20px", sm: i18n.language === "fa" ? "30px 20px 30px 30px" : "20px 20px 20px 10px" }
            }}>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "15px" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }} >{t("roulsPart1")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }}>{t("roulsPart2")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleCloseAlert} severity={alert.severity} page={"loginPass"} />
      <ModalPass open={open} close={handleCloseModal} data={data} type={"L"} />
    </>
  );
}
export default LoginPass;