import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button, Checkbox, CircularProgress } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import { emailValidate } from "./modules/validate";
import signinIcon from '../image/register/signin.png';
import axios from "axios";
import { baseUrl } from "./modules/general";
import AlertMessage from './modules/AlertMessage';
import { useNavigate, useParams } from "react-router-dom";
import { setCanonicalTag } from "./modules/general";
const ForgotPassword = () => {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const [captcha, setCaptcha] = useState(false);
  const [email, setEmail] = useState(params.email);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const [loading, setLoading] = useState(false);
  const [forgotBtn, setForgotBtn] = useState(false);
  const Navigate = useNavigate();
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  });

  useEffect(() => {
    document.title = " فراموشی رمزعبور پروفایل کاربری صرافی ارز دیجیتال کریپتوکامرس"
    document.getElementById("main-heading").innerHTML = "فراموشی رمز ورود به حساب کاربری صرافی ارز دیجیتال کریپتوکامرس";
    document.getElementsByTagName('meta')["description"].content = "برای فراموشی رمز عبور صرافی ارز دیجیتال کریپتوکامرس کافیست ایمیل کاربری خود را وارد کنید.";
    document.getElementsByTagName('meta')["keywords"].content = "رمز عبور را فراموش کردم | فراموشی گذرواژه | فراموشی پسورد حساب کاربری | تعیین مجدد رمز عبور کاربری صرافی | فراموشی رمز ورود به حساب صرافی | دکمه فراموشی رمز | بازیابی رمز ورود به کریپتوکامرس | فراموشی رمز کریپتوکامرس"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }
  //focus in textFiled
  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true })
  }

  const emailHandeler = (event) => {
    setErrors(emailValidate(event.target.value, t))
    setEmail(event.target.value);
  }

  //onChange Recaptcha
  const onChangeRechapta = (value) => {
    value != null ? setCaptcha(true) : setCaptcha(false)
  }

  //landle close Alert
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  const submitHandler = () => {
    if ((Object.keys(errors).length) || (email === "")) {
      setAlert({ alertCheck: true, message: t("AM_email_invalide"), severity: "error" })
    }
    else if (captcha === false) {
      setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
    }
    else if (!Object.keys(errors).length) {
      let existEmail;
      setForgotBtn(true);
      setLoading(true);
      Promise.resolve(axios({
        url: baseUrl + "/api/v4/user/existEmail?email=" + email,
        method: "GET",
      }))
        .then((res) => {
          setForgotBtn(false);
          setLoading(false);
          existEmail = res.data.data;
          if (existEmail === false) {
            Navigate("/ChangePass/" + email)
          }
          else if (existEmail === true) {
            setAlert({ alertCheck: true, message: t("AM_NotFoundEmail"), severity: "error" })
          }
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
          }
        })
    }
  }
  return (
    <>
      <Grid sx={{
        direction: i18n.language === "fa" ? "rtl" : "ltr", width: "100%", height: "100%", justifyContent: "center",
        display: "flex", flexDirection: { xs: "column", sm: "row" }, alignContent: "center", padding: { xs: "160px 0", sm: "260px 0", md: "230px 0" }, backgroundColor: "background.main"
      }}>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, height: "100%", display: "flex", justifyContent: { xs: "center", md: "end" } }}>
          <Grid sx={{
            backgroundColor: "background.secondary", maxWidth: { xs: "95%", sm: "306px", md: "478px" }, minWidth: { xs: "95%", sm: "280px", md: "390px" }, height: '100%',
            boxShadow: "1px 3px 6px rgba(0, 0, 0, 0.25)", borderRadius: { xs: "44px", sm: "50px", md: "15%" }, padding: { xs: "8% 0", sm: "13% 0", lg: "8% 0" }
          }}>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', marginBottom: "8%" }}>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "20px", md: "25px", lg: "30px" } : { xs: "17px", md: "18px", lg: "22px" },
                color: "text.greenDark", lineHeight: "30px", fontWeight: "400", margin: i18n.language === "fa" ? "0 5px 0 0" : "0 0 0 5px"
              }}>{t("mcc")}
              </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', margin: "3%" }}>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "15px", sm: "14px", lg: "15px" } : { xs: "13px", sm: "11px", lg: "14px" },
                lineHeight: "30px", fontWeight: "400", color: "text.primary"
              }}>{t("titleForgotPassword")}
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", padding: "3% 0" }} >
              <TextField
                className="textField"
                fullWidth
                label={t("email")}
                name="email"
                inputMode="email"
                value={email}
                size={textFieldSize}
                onChange={event => emailHandeler(event)}
                onFocus={focusHandler}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email && errors.email}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                variant="outlined"
                sx={{ "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" }, minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" } }}
              />
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", margin: "3% 0" }} className="g-recaptcha-inner">
              <ReCAPTCHA
                sitekey="6Le7R9UUAAAAAFklnUhPVV2qiSfeLXJHpDgnv6V3"
                onChange={(value) => { onChangeRechapta(value) }}
                className="g-recaptcha-login"
                hl="fa"
              />
            </Grid>
            <Grid sx={{ margin: "% 0", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button sx={{
                width: { xs: "50%", sm: "173px", md: "270px" }, height: { xs: "41px", md: "63px" }, background: (theme) => theme.palette.button.primary, borderRadius: "40px !important",
                "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.3)"
              }}
                onClick={submitHandler} disabled={forgotBtn ? true : false}>
                <Typography sx={{ fontSize: { xs: "11px", sm: "13px", md: "20px" }, color: "text.primary", lineHeight: "18px", padding: { xs: "5px", sm: "10px" } }}>
                  {forgotBtn === true && loading === true ? <CircularProgress size={20} color="inherit" /> : t("confirmation")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, display: "flex", justifyContent: { xs: "center", md: "start" }, margin: { xs: "0px", md: "0px 30px" } }}>
          <Grid sx={{
            width: { xs: "100%", sm: "98%", md: "95%", lg: "80%" }, display: "flex", padding: { xs: "30px 0px", sm: "0px 2%", md: "20px 5%", lg: "30px" },
            flexDirection: "column", alignItems: "center"
          }}>
            <Grid sx={{ width: "100%", display: { xs: "none", sm: "flex" }, alignItems: "center", justifyContent: "center" }}>
              <Typography component={"img"} alt="signin_Icon" src={signinIcon} sx={{ maxWidth: { sm: "200px", md: "200px" }, maxHeight: { sm: "200px", md: "200px" }, minWidth: { sm: "180px", md: "200px" }, minHeight: { sm: "180px", md: "200px" } }} />
            </Grid>
            <Grid sx={{
              display: "flex", flexDirection: "column", alignItems: "start", minWidth: { xs: "95%", sm: "220px", md: "360px" }, maxWidth: { xs: "95%", sm: "328px", md: "512px" }, backgroundColor: "background.secondary", boxShadow: ' 1px 3px 6px rgba(0, 0, 0, 0.25)',
              borderRadius: { xs: "18px", sm: "25px", md: "35px" }, maxHeight: { xs: "160px", sm: "174px", md: "272px" },
              padding: { xs: i18n.language === "fa" ? "20px 10px 20px 20px" : "20px 20px 10px 20px", sm: i18n.language === "fa" ? "30px 20px 30px 30px" : "20px 20px 20px 10px" }
            }}>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "15px" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }} >{t("roulsPart1")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }}>{t("roulsPart2")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: "none" }}>
          <Typography sx={{ display: "none" }}>
            درصورتی که که شما در صرافی ارز دیجیتال کریپتوکامرس ثبت نام کرده اما رمز عبور پروفایل کاربری خود را فراموش کرده اید، به صفحه فراموش رمز عبور مراجعه کنید.
          </Typography>
          <Typography sx={{ display: "none" }}>
            توجه داشته باشید برای کار در بزرگترین صرافی ارز دیجیتال ایران، ثبت نام با ایمیل معتبر گوگل و یاهو امکان پذیر است. بعد از ثبت نام برای تایید اکانت کاربری، ایمیل به ادرس کاربری ارسال می شود که با ضربه زدن روی لینک حساب شما تایید می شود.
          </Typography>
          <Typography sx={{ display: "none" }}>
            ممکن است به علت اینکه زمان زیادی وارد حساب کاربری خود در معتبرترین صرافی ارز دیجیتال ایران نشده باشید، رمزعبور حساب کاربری خود را فراموش کرده باشید. برای این منظور لازم است ادرس ایمیل کاربری خود در صرافی رمز ارز کریپتوکامرس را وارد کنید. یا بعضا ممکن است به دلیل انتخاب رمز پیچیده و سخت، دچار فراموشی شوید و یا به دلیل داشتن حساب های زیاد در صرافی های مختلف رمز عبور به حساب کاربری خود در صرافی کریپتوکامرس را فراموش کرده باشید. اصلا نگران نباشید، دارایی های شما متعلق به خود شما می باشد.
          </Typography>
          <Typography sx={{ display: "none" }}>
            صرافی رمزارز کریپتوکامرس برخود واجب دانسته است به هر روش ممکن کاربر را به حساب کاربری متعلق به خودش راهنمایی کند. پس در صورت فراموشی رمز عبور خود در صرافی ارز دیجیتال کریپتوکامرس نیاز است تا ایمیل ثبت نامی کاربری خود را وارد کنید. حال رمز عبور جدید برای خود انتخاب کنید و مجددا آن رمز عبور را وارد کنید. بعد از ثبت رمز عبور جدید، بسته به سطح امنیتی شما از شما تاییدیه به صورت اپلیکیشن گوگل اتنتیکیتور و یا رمز یکبار مصرف پیامکی دریافت می شود. حال لطفا با رمز عبور جدیدی که برای خود انتخاب کردید وارد حساب کاربری خود در معتبرترین صرافی ارز دیجیتال ایران شوید.
          </Typography>
          <Typography sx={{ display: "none" }}>
            از آنجایی که تغییر رمز عبور به معنای این است که رمز عبور قدیم دیگر معتبر نمی باشد لطفا دقت لازم را رعایت فرمایید. رمز های مورد نیاز برای ورود به حساب کاربری شما در صرافی بزرگ ارز دیجیتال کریپتوکامرس بدین شرح است. رمز عبور ایمیل کاربری شما ؛ همان رمزی است که هنگام ثبت نام در صرافی رمزارز کریپتوکامرس وارد کرده اید. درصورتی که اشتباه وارد شوید اجازه ورود به سایت را ندارید و در صورتی که رمز عبور یا گذرواژه یا فراموش کرده اید لطفا ادرس ایمیل کاربری خود را وارد کنید تا لینک تعیین رمز عبور جدید برای شما ارسال شود. پروسه فراموشی رمز عبور و تعیین رمز عبور جدید به طور مفصل بیان شد. از جمله رمز های مورد نیاز برای ورود به پنل کاربری سایت صرافی ارز دیجیتال کریپتوکامرس، رمز یکبار مصرف گوگل اتنتیکیتور است. هنگام فعالسازی امنیتی گوگل اتنتیکیتور عبارتی را به عنوان کلید بازیابی به شما نمایش می دهد. درصورتیکه اپلیکیشن گوگل پاک شد و یا به هر دلیل  به رمز یکبار مصرف گوگل دسترسی نداشتید با کلید بازیابی دوباره اکانت گوگل خود را بسازید. با از دست دادن کلید یا عبارت بازیابی شما در اپلیکیشن گوگل اتنتیکیتور نیاز به ارسال سلفی با مضمون اینکه مسئولیت و عواقب از دست دادن کلید را بر عهده گرفته اید، دارید.
          </Typography>
          <Typography sx={{ display: "none" }}>
            اپلیکیشن گوگل اتنتیکیتور هم بر روی گوشی و هم بر روی سیستم قابل نصب است. سومین رمز مورد نیاز شما برای ورود به حساب کاربری شما در بزرگترین صرافی ارز دیجیتال ایرانی، پیامک یکبار مصرف از سرشماره اختصاصی کریپتوکامرس است. در صورتی که بخواهید شماره همراه خود را در صرافی کریپتوکامرس عوض کنید باید حساب جدیدی بسازید. هر اکانت در صرافی کریپتوکامرس با یک شماره همراه احراز و متمایز می شود و تعویض آن به منزله حذف و غیرفعالسازی حساب شما می باشد.
          </Typography>
          <Typography sx={{ display: "none" }}>
            پس کاربر گرامی معتبرترین صرافی رمز ارز ایرانی کریپتوکامرسی عزیز درصورتیکه رمزعبور به حساب کاربری یا گذرواژه ورود به پنل حساب خود را فراموش کرده اید نگران نباشید دارایی شما نزد کریپتوکامرس محفوظ است. لطفا ایمیل کاربری خود را وارد کنید و رمز عبور جدید و سخت برای خود برگزینید. لطفا رمز عبور جدید خود را در جایی یادداشت کنید که در صورت فراموشی، آن را وارد کنید.
          </Typography>
        </Grid>
      </Grid>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleClose} severity={alert.severity} page={"ForgotPassword"} />
    </>
  );
}

export default ForgotPassword;