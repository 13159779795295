import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { Grid, Typography, Button, Skeleton } from "@mui/material";
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import { baseUrl, separator } from "./modules/general";
import axios from "axios";
import { usePrevious } from "./modules/hook";

export default function LivePrice() {

  const [currentInfo, setCurrentInfo] = useState([]);
  let preInfo = usePrevious(currentInfo);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getPrice();
    const updatePriceInterval = setInterval(() => {
      getPrice();
    }, 87000)

    return () => {
      clearInterval(updatePriceInterval);
    };
  }, []);

  const getPrice = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/general/priceTopCoin",
      method: "GET"
    }))
      .then((res) => {
        setCurrentInfo(res.data.data);
      })
  }

  const createListItem = () => {
    let result = currentInfo.map((topCoin, index) => {
      return (
        <Grid container sx={{ textAlign: "center", margin: "10px 0" }} key={index}>
          <Grid item xs={4} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start" } }}>
            <Typography component={"img"} alt="topCoin" src={topCoin.logo} width="30px" height="auto" />
            <Typography component={"p"} sx={{ margin: "0 25px", fontSize: { xs: "12px", sm: "14px", md: "22px" }, color: "text.primary" }}>
              {topCoin.name}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography component={"p"} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", fontSize: { xs: "10px", sm: "14px", md: "18px" },
              color: (preInfo[index] ? preInfo[index].IRR : topCoin.IRR) === topCoin.IRR ? "text.primary" : preInfo[index].IRR < topCoin.IRR ? "green" : "red"
            }}
            >
              {separator(topCoin.IRR)}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent: "center" }}>
            <Typography component={"p"} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", fontSize: { xs: "10px", sm: "14px", md: "18px" },
              color: (preInfo[index] ? preInfo[index].TRY : topCoin.TRY) === topCoin.TRY ? "text.primary" : preInfo[index].TRY < topCoin.TRY ? "green" : "red"
            }}
            >
              {separator(topCoin.TRY)}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent: "center" }}>
            <Typography component={"p"} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", fontSize: { xs: "10px", sm: "14px", md: "18px" },
              color: (preInfo[index] ? preInfo[index].RUB : topCoin.RUB) === topCoin.RUB ? "text.primary" : preInfo[index].RUB < topCoin.RUB ? "green" : "red"
            }}
            >
              {separator(topCoin.RUB)}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent: "center" }}>
            <Typography component={"p"} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", fontSize: { xs: "10px", sm: "14px", md: "18px" },
              color: (preInfo[index] ? preInfo[index].EUR : topCoin.EUR) === topCoin.EUR ? "text.primary" : preInfo[index].EUR < topCoin.EUR ? "green" : "red"
            }}
            >
              {separator(topCoin.EUR)}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography component={"p"} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", fontSize: { xs: "10px", sm: "14px", md: "18px" },
              color: (preInfo[index] ? preInfo[index].USDT : topCoin.USDT) === topCoin.USDT ? "text.primary" : preInfo[index].USDT < topCoin.USDT ? "green" : "red"
            }}
            >
              {separator(topCoin.USDT)}
            </Typography>
          </Grid>
          <Grid sx={{ height: "1px", bgcolor: "#E0E0E0", width: "100%", margin: "15px 0" }}></Grid>
        </Grid>
      )
    });
    return result;
  }

  return (
    <Grid sx={{ backgroundColor: "background.main" }}>
      <Grid sx={{ padding: { xs: "0 5px", md: "0 30px" }, direction: i18n.language === "fa" ? "rtl" : "ltr", maxWidth: "1340px", margin: "auto" }}>
        <Grid sx={{ bgcolor: "background.main", borderRadius: "13px", padding: { xs: "5% 0px", md: "5% 20px" } }}>
          <Grid sx={{ textAlign: "center" }}>
            <Grid container sx={{ textAlign: "center", marginBottom: { xs: "15px", sm: "20px", lg: "30px" } }}>
              <Grid item xs={4} md={2} sx={{ textAlign: { xs: "center", md: "start" } }}>
                <Typography component={"p"} sx={{ color: "#969696", fontSize: { xs: "11px", sm: "12px", md: "20px" } }}>
                  {t("Cryptocurrency")}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography component={"p"} sx={{ color: "#969696", fontSize: { xs: "11px", sm: "12px", md: "20px" } }}>
                  {t("irrStatic")}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography component={"p"} sx={{ color: "#969696", fontSize: { xs: "11px", sm: "12px", md: "20px" } }}>
                  {t("tryStatic")}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography component={"p"} sx={{ color: "#969696", fontSize: { xs: "11px", sm: "12px", md: "20px" } }}>
                  {t("rubStatic")}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography component={"p"} sx={{ color: "#969696", fontSize: { xs: "11px", sm: "12px", md: "20px" } }}>
                  {t("eurStatic")}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography component={"p"} sx={{ color: "#969696", fontSize: { xs: "11px", sm: "12px", md: "20px" } }}>
                  {t("usdtStatic")}
                </Typography>
              </Grid>
            </Grid>
            {currentInfo.length !== 0 ?
              createListItem()
              : <Grid container sx={{ textAlign: "center", margin: "10px 0" }}>
                <Grid item xs={4} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start" } }}>
                  <Skeleton variant="circular" width="30px" height="30px" />
                  <Typography component={"p"} sx={{ margin: "0 10px", width: "40%" }}>
                    <Skeleton variant="text" sx={{ fontSize: "16px" }} animation="wave" />
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Skeleton variant="text" sx={{ fontSize: "16px", width: "50%" }} animation="wave" />
                </Grid>
                <Grid item xs={4} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Skeleton variant="text" sx={{ fontSize: "16px", width: "50%" }} animation="wave" />
                </Grid>
                <Grid item xs={2} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent: "center" }}>
                  <Skeleton variant="text" sx={{ fontSize: "16px", width: "50%" }} animation="wave" />
                </Grid>
                <Grid item xs={2} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent: "center" }}>
                  <Skeleton variant="text" sx={{ fontSize: "16px", width: "50%" }} animation="wave" />
                </Grid>
                <Grid item xs={2} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent: "center" }}>
                  <Skeleton variant="text" sx={{ fontSize: "16px", width: "50%" }} animation="wave" />
                </Grid>
              </Grid>
            }
            <NavLink to={"Market"} style={{ textDecoration: "none" }}>
              <Button sx={{
                margin: "20px 0", color: "#FCFCFC", background: (theme) => theme.palette.button.primary,
                width: { xs: "137px", md: "270px" }, height: { xs: "40px", md: "55px" }, "&:hover": { background: (theme) => theme.palette.button.primaryHover },
                "&:hover svg": { width: "30px", opacity: "1", color: "#FCFCFC" }, borderRadius: "43px", boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.4)"
              }}>
                <Typography component={"p"} sx={{ color: "#FCFCFC", fontSize: { xs: "10px", md: "18px" } }}>
                  {t("allCryptocurrencies")}
                </Typography>
                <KeyboardDoubleArrowLeftRoundedIcon
                  sx={{ color: "#FCFCFC", fontSize: "30px", width: "0", transition: "0.3s all", opacity: "0", transform: i18n.language === "fa" ? "rotate(0deg)" : "rotate(180deg)", "&:hover": { color: "#FCFCFC" } }}
                />
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}