import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import { Blogs } from './data/Blog/Blogs';
import * as shamsi from 'shamsi-date-converter';
import { NavLink } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CryptoArticlesLanding = () => {
  const { t, i18n } = useTranslation();
  const [articleInfo, setArticleInfo] = useState([]);

  useEffect(() => {
    let result = [];
    let randomList = [];
    for (let i = 1; randomList.length < 3; i++) {
      let randomNum = Math.floor(Math.random() * Blogs.length);
      if ((randomList.find((item) => item === randomNum)) === undefined) {
        randomList.push(randomNum);
      }
    }
    for (let i = 0; i < randomList.length; i++) {
      let randomNum = randomList[i];
      result.push(Blogs[randomNum]);
    }
    setArticleInfo(result);
  }, [])

  const convertDate = (value) => {
    let date = value.split(',');
    let dateFa = `${date[0]}/${date[1]}/${date[2]}`;
    return i18n.language === "fa" ? dateFa : shamsi.jalaliToGregorian(parseInt(date[0]), parseInt(date[1]), parseInt(date[2])).join('/')
  }

  const Card = () => {
    let list = [];
    articleInfo.forEach((item, index) => {
      list.push(
        <Grid title={item.title} key={index} sx={{
          display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "90%", sm: "30%" }, background: `url(${item.image})`, backgroundPosition: "center", backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat", height: { xs: "150px", sm: "160px", md: "200px", lg: "220px", xl: "250x" }, margin: "20px 0", borderRadius: "16px", border: "1.5px solid #FF9254",
          boxShadow: "0px 0px 16px 0px #FF9254", "&:hover": { backgroundSize: "105% 105%" }, transition: "all 0.8s"
        }}>
          <Grid sx={{
            display: "flex", alignItems: "flex-start", justifyContent: "space-evenly", flexDirection: "column", opacity: "0", width: "100%", height: "100%",
            backgroundColor: "background.glass", borderRadius: "16px", "&:hover": { opacity: "1" }, transition: "opacity 0.3s", padding: "0 5%"
          }}>
            <Typography sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px" }, color: "text.primary" }}>{`${t("timeRead")} ${item.timeRead} ${t("minutes")}`}</Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "16px", lg: "20px" }, fontWeight: "700", color: "text.primary" }}>{item.h1}</Typography>
            <Typography sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px" }, color: "text.primary" }}>{item.desc.length <= 80 ? item.desc : (item.desc.substr(0, 80) + "...")}</Typography>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <Typography sx={{ fontSize: { xs: "10px", lg: "18px" }, color: "text.primary", padding: { xs: "unset", sm: "10px 0" } }}>{convertDate(item.date)}</Typography>
              <NavLink to={`/Blog${item.link}`} style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", flexDirection: "row", textDecoration: 'none' }} >
                <Typography component={"p"} sx={{
                  color: "text.primary", fontSize: { xs: "10px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))", fontWeight: '700'
                }}>{t("readMore")}
                </Typography>
                <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "14px", md: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", color: "text.primary" }} />
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      )
    })
    return list;
  }
  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", backgroundColor: "background.main", direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%" }}>
          <Grid sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", padding: "60px 0 40px 0" }}>
            <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "20px", md: "40px" } : { xs: "16px", sm: "20px", md: "25px" }, color: "text.primary" }}>{t("CryptoArticles")}</Typography>
            <Grid sx={{ backgroundColor: "#FF9254", padding: "0.75px", width: "100%", display: { xs: "none", sm: "flex" } }}></Grid>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "space-evenly", flexDirection: { xs: "column", sm: "row" }, alignItems: "center", marginTop: "20px", width: { xs: "100%", md: "95%", lg: '80%' }, flexWrap: "wrap" }}>
            {Card()}
          </Grid>
          <Grid sx={{ width: { xs: "100%", md: "85%", lg: '67%' }, display: "flex", justifyContent: "center", margin: { xs: "0 0 10%", sm: "5% 0 8%", md: "3% 0 6%" } }} >
            <NavLink to="/Blog" >
              <Button sx={{
                margin: "20px 0", color: "#FCFCFC", background: (theme) => theme.palette.button.primary,
                width: { xs: "137px", md: "270px" }, height: { xs: "40px", md: "55px" }, "&:hover": { background: (theme) => theme.palette.button.primaryHover },
                "&:hover svg": { width: "30px", opacity: "1", color: "#FCFCFC" }, borderRadius: "43px", boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.4)"
              }}>
                <Typography component={"p"} sx={{ color: "#FCFCFC", fontSize: { xs: "10px", md: "18px" } }}>
                  {t("َAllArticles")}
                </Typography>
                <KeyboardDoubleArrowLeftRoundedIcon
                  sx={{ color: "#FCFCFC", fontSize: "30px", width: "0", transition: "0.3s all", opacity: "0", transform: i18n.language === "fa" ? "rotate(0deg)" : "rotate(180deg)", "&:hover": { color: "#FCFCFC" } }}
                />
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default CryptoArticlesLanding;