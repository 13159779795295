export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        // 👇 palette values for light mode
        background: {
          main: '#FCFCFC',
          secondary: "#FFFFFF",
          modalTradeBox: "#ECEFF1",
          glass: "rgba(217, 217, 217, 0.70)",
          menuHover: "#EDF2F2",
          menuActive: "#E4EDED",
          between: "linear-gradient(0deg, rgba(247, 247, 247, 0.84) 0%, rgba(247, 247, 247, 0.84) 100%), #7CC2C7",
          blueLight: "#E4EFF0",
          orangeLight: "#FCF8F5",
          cardMarketWhite: "linear-gradient(90deg, rgba(250, 250, 250, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
          cardMarketGreen: "linear-gradient(90deg, rgba(237, 250, 241, 0.5) 0%, rgba(237, 250, 241, 0) 100%)",
          cardMarketRed: "linear-gradient(90deg, #FFF2F4 0%, rgba(255, 240, 242, 0.02) 100%)",
          loanCalucate: "linear-gradient(315deg, rgba(220, 245, 246, 0.90) 0%, #F1FFFF 100%)",
          textbox: "#E7E7E7",
          orderCard: "rgba(119, 186, 191, 0.7)",
          orderCardLight: "rgba(119, 186, 191, 1)",
          headList: "#F2FFFF",
          search: "#F7F7F7",
          silver: "#B5B5B5",
          card: "var(--card, linear-gradient(315deg, rgba(155, 227, 232, 0.90) 0%, #F1FFFF 100%))",
          copyGalss: "rgba(117, 115, 116, 0.83)",
          greenLightTab: "#F1FFFF",
          modalBack: "linear-gradient(113.79deg, #FFF0E8 2.73%, #E6FDFF 92.4%)",
          mobileMenu: "#FAFAFA"
        },
        text: {
          primary: '#1A1A1A',
          secondary: "#474747",
          grayLight: "#969696",
          grayDark: "#636363",
          greenDark: "#0C5463",
          greenLight: "#7CC2C7",
          greenBlue: "#77BABF",
          orange: "#FF9254",
          white: "#FCFCFC",
          white2: "#FFFFFF",
          blog: "#070707",
          link: "#6A35FF",
          mcc: "#062B33",
          alert: "#EF3914"
        },
        button: {
          primary: "var(--Button, linear-gradient(270deg, #136B7D 0%, rgba(73, 136, 148, 0.97) 100%))",
          primaryHover: "linear-gradient(270deg, #178399 0%, rgba(95, 175, 191, 0.97) 100%)",
          secondary: "var(--Secondary-Button, linear-gradient(270deg, #FF9254 0.15%, #FFA978 100%))",
          secondaryHover: "linear-gradient(270deg, #FFAE80 0.15%, #FFBE99 100%)",
          register: "linear-gradient(270deg, #255F6B 0.4%, rgba(66, 122, 133, 0.91) 99.99%)"
        }
      }
      : {
        // 👇 palette values for dark mode
        background: {
          main: '#062B33',
          secondary: '#000000',
          modalTradeBox: "#ECEFF1",
          glass: "rgba(105,99,99,0.6)",
          menuHover: "#EDF2F2",
          menuActive: "#E4EDED",
          between: "linear-gradient(0deg, rgba(247, 247, 247, 0.84) 0%, rgba(247, 247, 247, 0.84) 100%), #7CC2C7",
          blueLight: "#E4EFF0",
          orangeLight: "#FCF8F5",
          cardMarketWhite: "linear-gradient(90deg, rgba(250, 250, 250, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
          cardMarketGreen: "linear-gradient(90deg, rgba(237, 250, 241, 0.5) 0%, rgba(237, 250, 241, 0) 100%)",
          cardMarketRed: "linear-gradient(90deg, #FFF2F4 0%, rgba(255, 240, 242, 0.02) 100%)",
          loanCalucate: "linear-gradient(315deg, rgba(220, 245, 246, 0.90) 0%, #F1FFFF 100%)",
          textbox: "#E7E7E7",
          orderCard: "rgba(119, 186, 191, 0.7)",
          orderCardLight: "rgba(119, 186, 191, 1)",
          headList: "#F2FFFF",
          search: "#062B33",
          silver: "#B5B5B5",
          grayAuthen: "#BABABA",
          card: "var(--card, linear-gradient(315deg, rgba(155, 227, 232, 0.90) 0%, #F1FFFF 100%))",
          greenLightTab: "#F1FFFF",
          modalBack: "linear-gradient(113.79deg, #FFF0E8 2.73%, #E6FDFF 92.4%)",
          mobileMenu: "#FAFAFA"
        },
        text: {
          primary: '#fff',
          secondary: "#fff",
          grayLight: "#969696",
          grayDark: "#636363",
          greenDark: "#0C5463",
          greenLight: "#7CC2C7",
          greenBlue: "#77BABF",
          orange: "#FF9254",
          white: "#FCFCFC",
          white2: "#FFFFFF",
          blog: "#070707",
          link: "#6A35FF",
          mcc: "#062B33"
        },
        button: {
          primary: "var(--Button, linear-gradient(270deg, #136B7D 0%, rgba(73, 136, 148, 0.97) 100%))",
          primaryHover: "linear-gradient(270deg, #178399 0%, rgba(95, 175, 191, 0.97) 100%)",
          secondary: "var(--Secondary-Button, linear-gradient(270deg, #FF9254 0.15%, #FFA978 100%))",
          secondaryHover: "linear-gradient(270deg, #FFAE80 0.15%, #FFBE99 100%)",
          register: "linear-gradient(270deg, #255F6B 0.4%, rgba(66, 122, 133, 0.91) 99.99%)"
        }
      }),
  },
});
