import { Grid, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import whyCrypto1 from "../image/whyCrypto/whyCrypto1.png";
import whyCrypto2 from "../image/whyCrypto/whyCrypto2.png";
import whyCrypto3 from "../image/whyCrypto/whyCrypto3.png"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import "swiper/css";
import 'swiper/css/pagination';
SwiperCore.use([Pagination, Autoplay]);
const WhyCrypto = ({ authCheck }) => {
  const { t, i18n } = useTranslation();
  const [slides, setSlides] = useState("1");

  useEffect(() => {
    setSlidesPerview();
    window.addEventListener("resize", setSlidesPerview);
    return () => {
      window.removeEventListener("resize", setSlidesPerview);
    };
  });

  const setSlidesPerview = () => {
    setSlides(
      window.innerWidth <= 600
        ? 1
        : window.innerWidth >= 600
          ? 3
          : 0
    );
  }
  const sample = [
    {
      id: "01",
      title: "whyCryptoContant3",
      desc: "whyCryptoContantText3",
      image: whyCrypto1
    },
    {
      id: "02",
      title: "whyCryptoContant2",
      desc: "whyCryptoContantText2",
      image: whyCrypto2
    },
    {
      id: "03",
      title: "whyCryptoContant1",
      desc: "whyCryptoContantText1",
      image: whyCrypto3
    },
  ]

  const swiperWhyCrypto = () => {
    let list = [];
    sample.forEach((item) => {
      list.push(
        <SwiperSlide key={item.id} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
          <Grid
            sx={{
              backgroundColor: 'background.main', borderRadius: '12px', display: 'flex', flexDirection: item.id === "02" ? "column-reverse" : "column", alignItems: 'center', justifyContent: "space-evenly",
              width: { xs: "80%", sm: "180px", md: "290px", lg: "360px" }, maxHeight: { xs: "300px", sm: "280px", md: "420px", lg: "482px" }, minHeight: { xs: "281px", sm: "240px", md: "390px", lg: "420px" }, margin: '5px', padding: "10px", boxShadow: "1px 2px 7px rgba(0, 0, 0, 0.15)", marginBottom: "40px"
            }}>
            <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "16px", sm: "13px", md: "20px", lg: "24px" } : { xs: "14px", sm: "12px", md: "17px", lg: "22px" }, textAlign: "center", color: "text.primary" }}>{t(item.title)}</Typography>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "16px", lg: "16px" } : { xs: "10px", md: "14px", lg: "16px" }, textAlign: "center", color: "text.primary", textJustify: "inter-word", padding: "10px" }}>
                {t(item.desc)}
              </Typography>
            </Grid>
            <Grid sx={{
              display: "flex", justifyContent: "center", background: `url(${item.image})`, backgroundPosition: "center", backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat", height: { xs: "30vh", sm: "15vh", md: "18vh", lg: "25vh", xl: "28vh" }, width: "100%"
            }}></Grid>
          </Grid>
        </SwiperSlide>
      )
    })

    return list;
  }
  return (
    <>
      <Grid sx={{
        width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: "background.secondary",
        alignItems: "center", direction: i18n.language === "fa" ? "rtl" : "ltr"
      }}>
        <Grid sx={{ borderBottom: { xs: "", sm: "1px solid #FF9254" }, margin: { xs: "20px", sm: "0 0 30px", md: "30px 0 50px" }, padding: { xs: "0", sm: "20px 150px" } }} >
          <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "18px", sm: "20px", md: "40px" } : { sm: "20px", md: "38px" }, lineHeight: "32px", color: "text.primary" }}>{t("whyCrypto")}</Typography>
        </Grid>
        <Grid sx={{ width: { xs: "100%", sm: "85%", md: "95%", lg: '73%' }, display: "flex", alignItems: 'center', justifyContent: "center", flexWrap: 'wrap' }}>
          <Grid sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
            <Swiper
              style={{
                "--swiper-pagination-color": '#FF9355',
              }}
              slidesPerView={slides}
              spaceBetween={5}
              navigation
              pagination={{
                clickable: true,
                slidesPerView: 1,
                spaceBetween: 10,
              }}
              autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: true, waitForTransition: true }}
              className="swiperSorinMag"
            >
              {swiperWhyCrypto()}
            </Swiper>
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: { xs: "10px 0", sm: "30px 0" } }}>
          <NavLink to={authCheck === false ? '/Register' : '/Market'} style={{ textDecoration: "none" }}>
            <Button
              sx={{
                background: (theme) => theme.palette.button.primary, color: "text.white", borderRadius: "32px",
                fontSize: { xs: "10px", md: "18px" }, width: { xs: "137px", md: "270px" }, height: { xs: "40px", md: "55px" },
                "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: " 1px 1px 4px rgba(0, 0, 0, 0.25)", transition: "all 0.3s"
              }}
            >
              {authCheck === false ? t("registerAndStart") : t("goExchenge")}
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </>
  );
}
export default WhyCrypto;