import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
      lng: 'fa',
      backend: {
        /* translation file path */
        loadPath: '/language/{{ns}}/{{lng}}.json'
      },
      fallbackLng: 'en',
      debug: false,
      allowMultiLoading: true,
  
      /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
      ns: ['translations'],
      defaultNS: 'translations',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ','
      },
      react: {
        useSuspense: true
      }
  });

export default i18n;