import React from "react";
import { Grid, Typography } from "@mui/material";
import loading from '../image/loading/loading.gif';
const Loading = ({ display }) => {
  return (
    <>
      <Grid style={{ position: 'fixed', display: display, top: '50%', right: '50%', zIndex: "3000", transform: "translate(50%,50%)" }}>
        <Typography component={'img'} src={loading} style={{ width: '100%' }} alt="load-gif"></Typography>
      </Grid>
    </>
  );
}

export default Loading;