import React, { useEffect } from "react";
// import CryptoBuyAndSell from "./CryptoBuyAndSell";
import OurService from "./OurService";
import Header from "./header";
import LivePrice from "./livePrice";
import WhyCrypto from "./WhyCrypto";
import CryptoArticlesLanding from "./CryptoArticlesLanding";
import Footer from "./Footer";
import CryptoProcessWork from "./CryptoProcessWork";
// import DownloadApp from "./DownloadApp";
import { setCanonicalTag } from "./modules/general";
import { Typography, Grid } from "@mui/material";

const Home = ({ authCheck, infoUser, showLoadingInfoCoin }) => {

  useEffect(() => {
    document.title = "بزرگترین صرافی ارز دیجیتال ایران | فروش و خرید 400 رمزارز"
    document.getElementById("main-heading").innerHTML = "معتبرترین صرافی ارز دیجیتال ایران با بیش از 400 رمزارز معتبر | کریپتوکامرس";
    document.getElementsByTagName('meta')["description"].content = "صرافی کریپتوکامرس : معتبرترین و بزرگترین صرافی ارز دیجیتال ایران همراه با خرید و فروش بهترین ارز های دیجیتال دنیا و 5 سال سابقه فعالیت رسمی در کنار شماست.";
    document.getElementsByTagName('meta')["keywords"].content = " معتبرترین صرافی ارز دیجیتال ایران | بهترین صرافی ارز دیجیتال | خرید ارز دیجیتال در صرافی | معرفی قدیمی ترین صرافی رمزارز ایران | بهترین صرافی ترید ارز دیجیتال | بزرگترین صرافی ارز دیجیتال ایرانی | بهترین قیمت خرید ارز دیجیتال | صرافی با کمترین کارمزد | پشتیبانی عالی صرافی | دانلود اپلیکیشن صرافی ارز دیجیتال | صرافی ارز دیجیتال با مجوز";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag();
  }, [])

  return (
    <>
      <Header authCheck={authCheck} infoUser={infoUser} showLoadingInfoCoin={showLoadingInfoCoin} />
      <LivePrice />
      <WhyCrypto authCheck={authCheck} />
      {/* <CryptoBuyAndSell authCheck={authCheck} /> */}
      <CryptoProcessWork authCheck={authCheck} />
      <OurService />
      {/* <DownloadApp /> */}
      <CryptoArticlesLanding />
      <Footer />
      <Grid sx={{ display: "none" }}>
        <Typography component={"h2"} sx={{ display: "none" }} >
          صرافی کریپتوکامرس چرا بهترین صرافی ارز دیجیتال ایران است؟
        </Typography>
        <Typography sx={{ display: "none" }} >
          در این قسمت برآنیم وجه تمایز کریپتوکامرس به عنوان بهترین صرافی ارز دیجیتال ایران را برشماریم و تمام آنها را به صورت کامل توضیح دهیم. فاکتورهایی که یک صرافی ارز دیجیتال باید داشته باشد تا به اعتبار برسد را ذکر می کنیم. در اخر شما به عنوان کاربر صرافی میتوانید تفاوت کریپتوکامرس با بقیه رقبا را اعلام کنید.
        </Typography>
        <Typography component={"h3"} sx={{ display: "none" }} >
          امنیت صرافی ارز دیجیتال
        </Typography>
        <Typography sx={{ display: "none" }} >
          مهم ترین معیار برای انتخاب بهترین صرافی، تامین امنیت دارایی ما توسط صرافی می باشد. درصورتیکه یک صرافی کمترین کارمزد و بهترین خدمات را هم ارائه دهد اما از امنیت کافی بهره نبرد اصلا جای مناسبی برای نگهداری و خرید ارز دیجیتال نیست. اگر صرافی تازه تاسیس باشد کار شما سخت میشود و باید به صورت کامل بررسی کنید. صرافی هر چه قدیمی تر باشد امن تر است و اگر در رزومه خود تاریخچه ای از هک و دزدی نداشته باشد ( مثل کریپتوکامرس) قطعا گزینه ی مناسبی برای ورود و کار می باشد. کریپتوکامرس از سال 98 تا به حال هیچ گونه هک و سرقتی را تجربه نکرده است و همیشه سعی کرده امنیت وب سایت خود را در بالاترین رتبه نگه دارد. این عامل باعث شده کریپتوکامرس به عنوان امن ترین صرافی ارز دیجیتال ایران شناخته شود.
        </Typography>
        <Typography component={"h3"} sx={{ display: "none" }} >
          اعتبار صرافی ارز دیجیتال
        </Typography>
        <Typography sx={{ display: "none" }} >
          اعتبار یک صرافی جزو مهم ترین فاکتورهای انتخاب آن است. ماندگاری پیوسته صرافی کریپتوکامرس با همه شرایط سخت و طاقت فرسا و عدم قانون گذاری حکومت نشان از وجود بیزینسی سالم، قدرتمند و معتبر دارد. دریافت هرگونه مجوز صادر شده توسط مراجع ذیصلاح، ارایه اظهارنامه های معاملاتی و معاملات فصلی اعتبار بالای صرافی را نشان می دهد. هم چنین با پرس و جو از کاربران صرافی کریپتوکامرس متوجه می شوید که صرافی به عنوان یکی از پایدارترین سایت خرید و فروش ارز دیجیتال بشمار میرود. داشتن درگاه مستقیم بانکی زیر نظر شاپرک و عضو فناور انجمن بلاک چین ایران اعتبار کریپتوکامرس را افزایش داده است.
        </Typography>
        <Typography component={"h3"} sx={{ display: "none" }} >
          تجربه کاربری راحت و رابط کاربری زیبا صرافی ارز دیجیتال
        </Typography>
        <Typography sx={{ display: "none" }} >
          یکی از چالش های صرافی های بزرگ ارز دیجیتال همیشه نحوه دسترسی به صفحات سایت و راهنمایی کاربر برای بهترین تجربه معاملاتیست. کریپتوکامرس هم اکنون نسخه چهارم از سایت خود را سپری می کند. آپدیت هر نسخه باعث ارتقای رابط کاربری و تجربه کاری شده است و کار با سایت بسیار روان تر شده است. شرکت سورین با در اختیار داشتن تیم خلاق طراحی رابط کاربری و طراحی تجربه کاربری، همیشه بهترین طرح را برای سایت صرافی ارز دیجیتال ارائه داده است. هم چنین سورین تجربه طراحی و راه اندازی چندین صرافی ارز دیجیتال در ایران را داشته است.
        </Typography>
        <Typography component={"h3"} sx={{ display: "none" }} >
          تنوع خدمات صرافی ارز دیجیتال
        </Typography>
        <Typography sx={{ display: "none" }} >
          خدمات صرافی ارز دیجیتال فقط به خرید و فروش ختم نمی شود و همیشه صرافی های بین المللی برای ارائه خدمات نوین با هم به رقابت سنگین می پردازند. کریپتوکامرس هم به عنوان بهترین و بزرگترین صرافی رمزارز ایران همیشه به دنبال ارائه جدیدترین خدمات در حوزه کریپتوکارنسی و ارز دیجیتال می باشد. کریپتوکامرس به عنوان اولین صرافی ایرانی از توکن های اهرم دار رونمایی کرد. هم چنین امکانات حد سود و ضرر پیشرفته توسط کریپتوکامرس به بازار ایران معرفی شد. هم اکنون خدمات وام دهی ارز دیجیتال و سپرده گذاری هم در صرافی کریپتوکامرس ارائه می شود.
        </Typography>
        <Typography component={"h3"} sx={{ display: "none" }} >
          تنوع رمز ارز و بازار معاملاتی صرافی ارز دیجیتال
        </Typography>
        <Typography sx={{ display: "none" }} >
          همان طور که میدانید بالای هزاران ارز دیجیتال جهانی در بازار وجود دارد. کریپتوکامرس معتبرترین صرافی ارز ایران به عنوان بستر ارایه دهنده رمز ارز های معتبر، بیش از 400 ارز دیجیتال خوب و معتبر را پشتیبانی می کند و  در شبکه بلاک چین های متفاوت قابلیت برداشت دارند. هم چنین وجود 1600 جفت ارز معاملاتی باعث شده راه های متنوعی برای تهیه ارز داشته باشید. قابلیت خرید در قیمت بازار، قیمت دلخواه ، حد سود و ضرر امکاناتی کم نظیر است که توسط بهترین صرافی ارز دیجیتال ایران ارائه می شود.
        </Typography>
        <Typography component={"h3"} sx={{ display: "none" }} >
          پشتیبانی کاربران صرافی ارز دیجیتال
        </Typography>
        <Typography sx={{ display: "none" }} >
          یکی از مهم ترین فاکتور برای صرافی ارز دیجیتال، پاسخگویی به مشکلات کاربران در کمترین زمان است. همیشه کاربران ارز دیجیتال تمایل دارند با سایتی خرید و فروش کنند که پشتیبانی همیشگی را درکنار خود احساس کنند. گزارش مشکل یا ابهام کاربران را دریافت و به سرعت به تیم مورد نظر انتقال دهند و در کمترین زمان مشکل کاربر را حل کنند. حل نشدن مشکلات کاربران صرافی های ارز دیجیتال مثل مغایرت در خرید و یا عدم اجازه برداشت دارایی جزو ترس همیشگی هم وطنانمان بوده است. از انجا که ایرانیان تجربه مسدود سازی حساب در صرافی های خارجی را دارند، ما درکریپتوکامرس افتخار میکنیم که با داشتن تیم پشتیباتی بزرگ و قوی در کمترین زمان به سوالات و مشکلات کاربران پاسخ می دهیم.
        </Typography>
        <Typography sx={{ display: "none" }}>
          کریپتوکامرس به عنوان بزرگترین صرافی ارز دیجیتال ایران از فروش و خرید 400 رمزارز معتبر پشتیبانی میکند به همین دلیل کریپتوکامرس معتبرترین صرافی ارز دیجیتال ایرانی لقب گرفته است.
        </Typography>
      </Grid>
    </>
  )
}
export default Home;