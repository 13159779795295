import React, { useState, useEffect } from "react";
import { Modal, Typography, Grid, TextField, Button, CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { smsValidate, googleValidate } from "./validate";
import GoogleIcon from '@mui/icons-material/Google';
import SmsIcon from '@mui/icons-material/Sms';
import { onlyDigit } from "./general";
import axios from "axios";
import { baseUrl } from "./general";
import AlertMessage from "./AlertMessage";
import { useNavigate } from "react-router-dom";

const ModalPass = ({ open, close, data, type }) => {
  const [google, setGoogle] = useState("");
  const [sms, setSms] = useState("");
  const [sendSms, setSendSms] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const { t, i18n } = useTranslation();
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const [security, setSecurity] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [passBtn, setPassBtn] = useState(false);
  const Navigate = useNavigate();
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId)
    };
  }, [timeLeft, startTimer]);

  useEffect(() => {
    if (timeLeft === 0) {
      setSendSms(false)
    }
  }, [timeLeft]);

  useEffect(() => {
    setSecurity(data.security);
    setEmail(data.email);
    setPass(data.pass);
  }, [data]);

  useEffect(() => {
    if (open === false) {
      setGoogle("");
      setSms("");
      setTouched("");
      setSendSms(false)
      setStartTimer(false)
    }
  }, [open]);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  },);

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const googleHandler = (event) => {
    setErrors(googleValidate(event.target.value, t));
    setGoogle(event.target.value);
  }

  const smsHandler = (event) => {
    setErrors(smsValidate(event.target.value, t));
    setSms(event.target.value)
  }

  const format = (timeLeft) => {
    const date = new Date(timeLeft * 1000);
    let hh = date.getUTCHours();
    let mm = date.getUTCMinutes();
    let ss = date.getSeconds();
    if (hh < 10) { hh = "0" + hh; }
    if (mm < 10) { mm = "0" + mm; }
    if (ss < 10) { ss = "0" + ss; }
    return '00' !== hh ? hh + ":" + mm + ":" + ss : mm + ":" + ss;
  }

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true })
  }

  const sendSmsHandler = () => {
    setLoading(true)
    Promise.resolve(axios({
      url: baseUrl + '/api/v4/otp/sendMessage',
      method: "POST",
      data: { email: email },
    }))
      .then((res) => {
        setLoading(false)
        setTimeLeft(res.data.data)
        setStartTimer(true)
        setSendSms(true);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
        }
        else {
          const { status } = error.response;
          setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
        }
        setLoading(false);
      })
  }

  const postDataResetPass = () => {
    let data = {};
    switch (security) {
      case (2):
        data = { email: email, password: pass, authenticatorCode: google };
        break;
      case (3):
        data = { email: email, password: pass, smsCode: sms };
        break;
      case (4):
        data = { email: email, password: pass, authenticatorCode: google, smsCode: sms };
        break;
      default:
        break;
    }
    Promise.resolve(axios({
      url: baseUrl + '/api/v4/user/resetPassword',
      method: "POST",
      data: data,
    }))
      .then((res) => {
        setPassBtn(false);
        setLoadingBtn(false);
        setAlert({ alertCheck: true, message: t("AM_resetpass"), severity: "success" })
        const timeout = setTimeout(() => {
          Navigate("/Login/" + email)
        }, 3000)
        return () => {
          clearTimeout(timeout)
        };
      })
      .catch(
        (error) => {
          setPassBtn(false);
          setLoadingBtn(false);
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
          }
        }
      )
  }

  const postDataLogin = () => {
    let data = {};
    switch (security) {
      case (2):
        data = { email: email, pass: pass, authenticatorCode: google };
        break;
      case (3):
        data = { email: email, pass: pass, smsCode: sms };
        break;
      case (4):
        data = { email: email, pass: pass, authenticatorCode: google, smsCode: sms };
        break;
      default:
        break;
    }
    Promise.resolve(axios({
      url: baseUrl + '/api/v4/user/login',
      method: "POST",
      data: data,
    }))
      .then((res) => {
        setPassBtn(false);
        setLoadingBtn(false);
        localStorage.setItem('TACC', res.data.data.access_token);
        Navigate("/");
        window.location.reload();
      })
      .catch(
        (error) => {
          setPassBtn(false);
          setLoadingBtn(false);
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
          }
        }
      )
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  }

  const submitHandler = () => {
    let checkF = false;
    if (security === 3) {
      if (sms === "") {
        setAlert({ alertCheck: true, message: t("AM_scurity_null"), severity: "error" })
      }
      else if (errors.hasOwnProperty('sms')) {
        setAlert({ alertCheck: true, message: t("AM_sms_requierd"), severity: "error" })
      }
      else {
        checkF = true;
      }
    }
    if (security === 2) {
      if (google === "") {
        setAlert({ alertCheck: true, message: t("AM_scurity_null"), severity: "error" })
      }
      else if ((errors.hasOwnProperty('google'))) {
        setAlert({ alertCheck: true, message: t("AM_google_requierd"), severity: "error" })
      }
      else {
        checkF = true;
      }
    }
    if (security === 4) {
      if ((sms === "") && (google === "")) {
        setAlert({ alertCheck: true, message: t("AM_scurity_null"), severity: "error" })
      }
      else if (errors.hasOwnProperty('sms')) {
        setAlert({ alertCheck: true, message: t("AM_sms_requierd"), severity: "error" })
      }
      else if ((errors.hasOwnProperty('google'))) {
        setAlert({ alertCheck: true, message: t("AM_google_requierd"), severity: "error" })
      }
      else {
        checkF = true;
      }
    }
    if (checkF) {
      if (type === "L") {
        setPassBtn(true);
        setLoadingBtn(true);
        postDataLogin();
      }
      else if (type === "R") {
        setPassBtn(true);
        setLoadingBtn(true);
        postDataResetPass();
      }
    }

  }
  return (
    <>
      <Modal
        open={open} onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)' }}>
          <Grid sx={{
            backgroundColor: "background.main", boxShadow: " 1px 3px 6px rgba(0, 0, 0, 0.25)", borderRadius: { xs: "28px", sm: "48px", md: "60px" }, padding: "1% 0", width: "100%", margin: "0 5%",
            maxWidth: "570px", display: "flex", alignItems: 'center', justifyContent: 'center'
          }}>
            <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", padding: "2%" }} >
              <Typography >{t("securityTitle")}</Typography>
              <Grid sx={{ margin: "5% 0 2%", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "0 8%" }}>
                <Typography sx={{
                  fontSize: i18n.language === "fa" ? { xs: "12px", md: "15px" } : { xs: "10px", md: "14px" }, textAlign: "center", color: "text.primary"
                }}>{t("security")}
                </Typography>
              </Grid>
              <Grid sx={{ width: "100%", display: security === 2 || security === 4 ? "flex" : "none", alignItems: 'center', justifyContent: "center", padding: "2% 0 0", flexDirection: "column" }}>
                <TextField
                  label={t("google")}
                  variant='outlined'
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" },
                    width: { xs: "80%", sm: "60%" }
                  }}
                  className="textField"
                  name="google"
                  size={textFieldSize}
                  value={onlyDigit(google)}
                  onChange={event => googleHandler(event)}
                  onFocus={focusHandler}
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", md: "1rem" } } }}
                  error={errors.google && touched.google}
                  helperText={errors.google && touched.google && errors.google}
                  inputProps={{ maxLength: 6 }}
                  InputProps={{
                    endAdornment: <GoogleIcon sx={{ color: 'text.greenLight', ml: 1, my: 0.5 }} />
                  }}
                  onKeyDown={(e) => (
                    e.key === "Enter" ? submitHandler(e) : null
                  )}
                />
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "13px" } : { xs: "10px", sm: "12px" }, textAlign: "center", padding: "1% 8%", textJustify: "inter-word", color: "text.primary" }}>
                  {t("googleAuthentication")}
                </Typography>
              </Grid>
              <Grid sx={{ width: "100%", display: security === 3 || security === 4 ? "flex" : "none", alignItems: 'center', justifyContent: "center", padding: "2% 0", flexDirection: "column" }}>
                <TextField
                  label={t("sms")}
                  variant='outlined'
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" },
                    width: { xs: "80%", sm: "60%" }
                  }}
                  className="textField"
                  name="sms"
                  size={textFieldSize}
                  value={onlyDigit(sms)}
                  onChange={event => smsHandler(event)}
                  onFocus={focusHandler}
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", md: "1rem" } } }}
                  error={errors.sms && touched.sms}
                  helperText={errors.sms && touched.sms && errors.sms}
                  inputProps={{ maxLength: 6 }}
                  InputProps={{
                    endAdornment:
                      <Button onClick={sendSmsHandler} disabled={sendSms === true ? true : false}
                        endIcon={<SmsIcon
                          sx={{ display: sendSms === false && loading === false ? "block" : "none", marginRight: i18n.language === "fa" ? "5px" : "0px", fontSize: { xs: "10px", sm: "15px" } }} />}
                        sx={{
                          fontSize: { xs: "10px", sm: "12px", md: "14px" },
                          width: "120px", textAlign: "center", color: "text.white", marginLeft: "5px",
                          transition: "all 0.1s ease-in-out", backgroundColor: "text.greenLight", borderRadius: "10px", "&:hover": { backgroundColor: "text.greenLight" }, "& .MuiButton-endIcon": { marginLeft: 0 }
                        }}
                      >
                        {loading === false ? sendSms === false ? t("send") : format(timeLeft) : <CircularProgress size={20} color="inherit" sx={{ color: "text.white" }} />}
                      </Button>
                  }}
                />
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "13px" } : { xs: "10px", sm: "12px" }, textAlign: "center", padding: "1% 8%", textJustify: "inter-word", color: "text.primary" }}>
                  {sendSms === false ? t("smsCodeSend") : t("smsCodeTitle")}
                </Typography>
              </Grid>
              <Grid sx={{ margin: "2% 0", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                <Button sx={{
                  width: { xs: "50%", sm: "173px", md: "270px" }, height: { xs: "41px", md: "63px" }, background: (theme) => theme.palette.button.primary, borderRadius: "40px !important",
                  "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.3)"
                }}
                  onClick={submitHandler} disabled={passBtn ? true : false}>
                  <Typography sx={{ fontSize: { xs: "11px", sm: "13px", md: "20px" }, color: "text.white", lineHeight: "18px", padding: { xs: "5px", sm: "10px" } }}>
                    {passBtn === true && loadingBtn === true ? <CircularProgress size={20} color="inherit" /> : type === "L" ? t("login") : t("confirmation")}
                  </Typography>
                </Button>
              </Grid>
              <Grid sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "2% 0 0" }}>
                <Typography onClick={close} sx={{ paddingTop: "10px", cursor: "pointer" }}>{t("close")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleCloseAlert} severity={alert.severity} page={"ModalPass"} />
    </>
  );
}
export default ModalPass