import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import logo from '../image/logo/logo.png'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import '../style/Register.css';
import background from '../image/footer/background.png'
import { NavLink } from "react-router-dom";
const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Grid sx={{
        display: "flex", justifyContent: 'space-between', alignItems: "center", flexDirection: 'column', width: "100%",
        direction: i18n.language === "fa" ? "rtl" : "ltr", background: `url(${background})`, backgroundPosition: "center", backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat", height: { xs: "280px", sm: "220px", md: "310px" }
      }}>
        <Grid sx={{ width: { xs: "100%", sm: "90%", md: "85%", lg: '67%' }, display: "flex", flexDirection: { xs: "column", sm: "row" }, marginTop: "3%", justifyContent: { xs: "center", sm: "space-between" } }}>
          <Grid sx={{ width: { xs: "100%", sm: "72%", md: "65%", lg: "75%" }, display: "flex", flexDirection: "column" }}>
            <Grid sx={{ width: "100%", display: "flex", flexDirection: "row", margin: "10px 0", alignItems: "center", justifyContent: { xs: "center", sm: "flex-start" } }}>
              <Grid sx={{ width: { xs: "30%", md: "25%" } }}>
                <NavLink to="/Blog" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("blog")}</Typography>
                </NavLink>
                <NavLink to="/News" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("news")}</Typography>
                </NavLink>
                <NavLink to="/Loan" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("loan")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ width: { xs: "30%", md: "25%" } }}>
                <NavLink to="/Earn" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("Earn")}</Typography>
                </NavLink>
                <NavLink to="/Market" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("marketCrypto")}</Typography>
                </NavLink>
                <NavLink to="/Exchange/BTC/USDT" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("buyCrypto")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ width: { xs: "30%", md: "25%" } }}>
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("byeAndSellBTC")}</Typography>
                </NavLink>
                <NavLink to="/Details/BTC" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("whatBTC")}</Typography>
                </NavLink>
                <NavLink to="/Exchange/BTCUP/USDT" style={{ textDecoration: "none" }}>
                  <Typography sx={{ fontSize: { xs: "10px", md: "14px", lg: "16px" }, color: "text.primary", margin: "10px 0" }}>{t("byeAndSellBTCleverage")}</Typography>
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ width: { xs: "100%", sm: "28%", md: "25%", lg: '15%' }, display: "flex", flexDirection: { xs: "column-reverse", sm: 'column' }, alignItems: { xs: "center", sm: "flex-start" } }}>
            <Grid sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center", width: "100%" }}>
              <Typography sx={{ color: "text.primary", fontSize: { sm: "16px", md: "24px" } }}>{t("contact")}</Typography>
            </Grid>
            <Grid sx={{ width: "100%", margin: "10px 0", display: "flex", flexDirection: "row", justifyContent: { xs: "center", sm: "flex-start" }, alignItems: 'cnter' }}>
              <SupportAgentIcon sx={{ color: "text.primary", padding: "3px", fontSize: { xs: "20px", md: "25px" } }} />
              <Typography sx={{ color: "text.primary", padding: "0px 10px", fontSize: { xs: "12px", md: "19px" } }}>02177442770</Typography>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
              <Typography component={"a"} href="https://t.me/Crypto_Commerce" sx={{ textDecoration: "none" }}>
                <TelegramIcon sx={{ fontSize: { xs: "20px", md: "24px", lg: "25px" }, color: "text.orange", margin: { xs: "5px", md: "5px 10px" } }} />
              </Typography>
              <Typography component={"a"} href="https://twitter.com/mycryptocommerc" sx={{ textDecoration: "none" }}>
                <TwitterIcon sx={{ fontSize: { xs: "20px", md: "24px", lg: "25px" }, color: "text.orange", margin: { xs: "5px", md: "5px 10px" } }} />
              </Typography>
              <Typography component={"a"} href="https://www.instagram.com/mycryptocommerce/" sx={{ textDecoration: "none" }}>
                <InstagramIcon sx={{ fontSize: { xs: "20px", md: "24px", lg: "25px" }, color: "text.orange", margin: { xs: "5px", md: "5px 10px" } }} />
              </Typography>
              <Typography component={"a"} href="http://api.whatsapp.com/send?phone=989194387807" sx={{ textDecoration: "none" }}>
                <WhatsAppIcon sx={{ fontSize: { xs: "20px", md: "24px", lg: "25px" }, color: "text.orange", margin: { xs: "5px", md: "5px 10px" } }} />
              </Typography>
              <Typography component={"a"} href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQERWXz_k2fGxwAAAXoU1R3IeLJJ_AHnIL9m05PTVHgxwYCku4CZfZjfAuP1lU3SiydrDHjRBfuaCn3QA9C1-Z2WsJWBX4KuoVXUQlS5kcrX_yGQR9lyYa_4Ubjr6oxbBPIJJEc=&originalReferer=https://mycryptocommerce.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fpublic-profile%2Fsettings%3Ftrk%3Dd_flagship3_profile_self_view_public_profile"
                sx={{ textDecoration: "none" }}>
                <LinkedInIcon sx={{ fontSize: { xs: "20px", md: "24px", lg: "25px" }, color: "text.orange", margin: { xs: "5px", md: "5px 10px" } }} />
              </Typography>
              <Typography component={"a"} href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2FMyCryptoCommerce" sx={{ textDecoration: "none" }}>
                <FacebookIcon sx={{ fontSize: { xs: "20px", md: "24px", lg: "25px" }, color: "text.orange", margin: { xs: "5px", md: "5px 10px" } }} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: 'center', paddingBottom: "10px", flexDirection: 'column' }}>
          <Typography component={"img"} alt="logo" src={logo} sx={{ width: { xs: "10%", md: "12%" } }} />
          <Typography sx={{ color: "text.primary", fontSize: { xs: "10px", sm: "12px", md: "14px" } }}>{t("rightsWebsite")}</Typography>
        </Grid>
      </Grid>
    </>
  )
}
export default Footer;