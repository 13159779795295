import { Grid, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import account from '../image/cryptoWork/account.png';
import wallet from "../image/cryptoWork/wallet.png";
import trade from '../image/cryptoWork/trade.png';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import "swiper/css";
import 'swiper/css/pagination';
SwiperCore.use([Pagination, Autoplay]);

const CryptoProcessWork = ({ authCheck }) => {
  const { t, i18n } = useTranslation();
  const [slides, setSlides] = useState("1");

  useEffect(() => {
    setSlidesPerview();
    window.addEventListener("resize", setSlidesPerview);
    return () => {
      window.removeEventListener("resize", setSlidesPerview);
    };
  });

  const setSlidesPerview = () => {
    setSlides(
      window.innerWidth <= 600
        ? 1
        : window.innerWidth >= 600
          ? 3
          : 0
    );
  }
  const sample = [
    {
      id: "01",
      title: "createAccount",
      desc: "createAccountTitle",
      image: account
    },
    {
      id: "02",
      title: "chargeWallet",
      desc: "chatgeWalletTitle",
      image: wallet
    },
    {
      id: "03",
      title: "tadeProcess",
      desc: "tadeTitle",
      image: trade
    },
  ]

  const swiperCryptoWork = () => {
    let list = [];

    sample.forEach((item) => {
      list.push(
        <SwiperSlide key={item.id} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
          <Grid
            sx={{
              backgroundColor: "background.main", borderRadius: '12px', display: 'flex', flexDirection: item.id === "02" ? "column-reverse" : "column", alignItems: 'center', justifyContent: "space-evenly",
              width: { xs: "80%", sm: "180px", md: "290px", lg: "360px" }, maxHeight: { xs: "300px", sm: "280px", md: "420px", lg: "482px" }, minHeight: { xs: "281px", sm: "240px", md: "390px", lg: "420px" }, margin: '5px', padding: "10px", boxShadow: "1px 2px 7px rgba(0, 0, 0, 0.15)", marginBottom: "40px"
            }}>
            <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: 'column' }}>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "16px", sm: "13px", md: "20px", lg: "25px" } : { xs: "12px", sm: "12px", md: "17px", lg: "22px" }, textAlign: "justify", color: "text.secondary" }}>{t(item.title)}</Typography>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "14px", lg: "15px", xl: "18px" } : { xs: "10px", md: "14px", lg: "18px" }, textAlign: "justify", color: "text.secondary", padding: "10px" }}>
                {t(item.desc)}
              </Typography>
            </Grid>
            <Grid sx={{
              display: "flex", justifyContent: "center", background: `url(${item.image})`, backgroundPosition: "center", backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat", height: { xs: "25vh", sm: "12vh", md: "18vh", lg: "20vh", xl: "21vh" }, width: "90%"
            }}></Grid>
          </Grid>
        </SwiperSlide >
      )
    })

    return list;
  }
  return (
    <>
      <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", background: (theme) => theme.palette.background.between }}>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "100%", sm: "85%", md: "95%", lg: '73%' }, margin: "4% 0" }}>
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: 'center', paddingBottom: "20px" }}>
            <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "18px", sm: "20px", md: "40px" } : { xs: "16px", sm: "20px", md: "38px" }, color: "text.primary" }}>{t("processWorkTitle")}</Typography>
          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
            <Swiper
              style={{
                "--swiper-pagination-color": '#FF9355',
              }}
              slidesPerView={slides}
              spaceBetween={5}
              navigation
              pagination={{
                clickable: true,
                slidesPerView: 1,
                spaceBetween: 10,
              }}
              autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: true, waitForTransition: true }}
              className="swiperSorinMag"
            >
              {swiperCryptoWork()}
            </Swiper>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: { xs: "10px", sm: "30px" } }}>
            <NavLink to={authCheck === false ? '/Register' : '/Market'} style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  background: (theme) => theme.palette.button.primary, color: " #FCFCFC", borderRadius: "32px",
                  fontSize: { xs: "10px", md: "18px" }, width: { xs: "137px", md: "270px" }, height: { xs: "40px", md: "55px" },
                  "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: " 1px 1px 4px rgba(0, 0, 0, 0.25)", transition: "all 0.3s"
                }}
              >
                {authCheck === false ? t("createAccountCrypto") : t("goExchenge")}
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CryptoProcessWork;