import React, { useState, useEffect, createContext } from 'react';
import { getAllBookTickerWithIrr } from '../services/api';
import AlertMessage from '../component/modules/AlertMessage';
import { useTranslation } from "react-i18next";

export const allBookTickerContext = createContext();

const AllBookTickerContext = ({ children }) => {

  const [allBook, setAllBook] = useState([]);
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAllBookTicker();
    const allBookInterval = setInterval(() => {
      getAllBookTicker()
    }, 401000)
    return () => {
      clearInterval(allBookInterval);
    };
  }, [])

  const getAllBookTicker = () => {
    getAllBookTickerWithIrr().then((res) => setAllBook(res)).catch((error) => {
      if (error.code === "ERR_NETWORK") {
        setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
      }
      else {
        const { status } = error.response;
        setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
      }
    });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  return (
    <>
      <allBookTickerContext.Provider value={allBook}>
        {children}
      </allBookTickerContext.Provider>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleClose} severity={alert.severity} page={"Market"} />
    </>
  )
}

export default AllBookTickerContext;