import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, TextField, Button } from "@mui/material";
import { Blogs } from "./data/Blog/Blogs";
import { NavLink, useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import Footer from "./Footer";
import backCard from '../image/news/backCard.png'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/pagination';
import imageCard from '../image/news/imageCard.png'
import bannerHomeinjaSM from '../image/news/bannerHomeinjaSM.gif';
import bannerHomeinjaMD from '../image/news/bannerHomeinjaMD.gif';
import bannerHomeinjaXS from '../image/news/bannerHomeinjaXS.gif';
import { setCanonicalTag } from "./modules/general";

const Blog = ({ authCheck }) => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("")
  const [lastestBlog, setLastestBlog] = useState(null);
  const [slides, setSlides] = useState("1");
  const [randomBlog, setRandomBlog] = useState(null);
  const [listSuggested, setListSuggested] = useState(null);
  const Navigate = useNavigate();

  useEffect(() => {
    document.title = "مرجع کامل آموزش و تحلیل بازار ارز دیجیتال و بلاکچین | صرافی رمزارز کریپتوکامرس"
    document.getElementById("main-heading").innerHTML = "آموزش کامل ارز دیجیتال و بلاکچین همراه با تحلیل تکنیکال و فاندامنتال رمزارزها ";
    document.getElementsByTagName('meta')["description"].content = "در بلاگ آکادمی صرافی کریپتوکامرس به آموزش کامل ارزهای دیجیتال و بلاکچین می پردازیم و نحوه انجام تحلیل تکنیکال و مطالب فاندامنتال رمزارزها را بررسی می کنیم.";
    document.getElementsByTagName('meta')["keywords"].content = "آموزش رمزارز | آکادمی تخصصی ارز دیجیتال | آموزش تمام ارزهای دیجیتال جهان | بهترین مقالات ارز دیجیتال | اصلی ترین مرجع امورزشی ارز دیجیتال در ایران | آموزش فروش و خرید ارز دیجیتال | آموزش والت و کیف پول ارز دیجیتال | آموزش ترید و معامله رمزارز | آموزش تحلیل تکنیکال ارز دیجیتال | آموزش تحلیل فاندامنتال | مطالب آموزشی بلاکچین | یادگیری ارز دیجیتال | آموزشگاه مجازی ارز دیجیتال";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag();
  }, [])

  useEffect(() => {
    setSlidesPerview();
    window.addEventListener("resize", setSlidesPerview);
    return () => {
      window.removeEventListener("resize", setSlidesPerview);
    };
  });

  useEffect(() => {
    let data = []
    data.push(Blogs[Blogs.length - 1])
    data.push(Blogs[Blogs.length - 2])
    data.push(Blogs[Blogs.length - 3])
    setLastestBlog(data)
  }, [])

  useEffect(() => {
    blogRandom();
    Suggested();
  }, [])

  const setSlidesPerview = () => {
    setSlides(
      window.innerWidth <= 600
        ? 1
        : window.innerWidth <= 900
          ? 2
          : window.innerWidth > 720
            ? 3
            : 0
    );
  }

  const searchHandeler = () => {
    if (search !== "") {
      Navigate("/Blog/" + search)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchHandeler();
    }
  }

  const blogRandom = () => {
    let randomList = [];
    let data = [];
    for (let i = 1; randomList.length < 2; i++) {
      let randomNum = Math.floor(Math.random() * Blogs.length);
      if ((randomList.find((item) => item === randomNum)) === undefined) {
        randomList.push(randomNum);
      }
    }
    for (let i = 0; i < randomList.length; i++) {
      let randomNum = randomList[i];
      data.push(Blogs[randomNum]);
    }
    setRandomBlog(data)
  }

  const Suggested = () => {
    let list = [];
    let randomList = [];
    let data = [];
    Blogs.forEach((item) => {
      item.Suggested === "yes" && list.push(item)
    })
    for (let i = 1; randomList.length < 3; i++) {
      let randomNum = Math.floor(Math.random() * list.length);
      if ((randomList.find((item) => item === randomNum)) === undefined) {
        randomList.push(randomNum);
      }
    }
    for (let i = 0; i < randomList.length; i++) {
      let randomNum = randomList[i];
      data.push(list[randomNum]);
    }
    setListSuggested(data)
  }
  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", paddingTop: "60px", backgroundColor: "background.main" }}>
        <Grid sx={{
          display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" }, width: { xs: "90%", sm: "600px", md: "900px", lg: "1150px", xl: "1260px" }, height: { xs: "unset", sm: "253px", md: "400px", lg: "506px" }, borderRadius: { xs: "unset", sm: "26px" },
          boxShadow: { xs: "unset", sm: "1px 2px 7px 0px rgba(0, 0, 0, 0.15)" }, backgroundColor: { xs: "unset", sm: "background.secondary" }, margin: { xs: "30px 0", sm: "50px 0", md: "100px 0" }
        }}>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "55%" } }}>
            <Grid sx={{
              backgroundSize: "100% 100%", backgroundImage: `url(${Blogs[Blogs.length - 1].image})`, borderRadius: "25px",
              backgroundPosition: "center", backgroundRepeat: "no-repeat", width: { xs: '90%', sm: "315px", md: "470px", lg: "600px", xl: "630px" }, height: { xs: "162px", sm: "193px", md: "300px", lg: "387px", xl: "387px" },
            }}>
            </Grid>
          </Grid>
          <Grid sx={{
            display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: { xs: "90%", sm: "43%" }, flexDirection: "column",
            height: { xs: "unset", sm: "193px", md: "300px", lg: "387px", xl: "387px" }
          }}>
            <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px", lg: "29px" }, color: "text.primary", margin: { xs: "5px", md: "unset" } }}>
              {Blogs[Blogs.length - 1].h1}
            </Typography>
            <Typography sx={{ fontSize: { xs: "12px", md: "19px" }, color: "text.primary", textAlign: "justify", margin: { xs: "5px", md: "30px 0 0 20px" } }}>
              {Blogs[Blogs.length - 1].desc}
            </Typography>
            <Grid sx={{ width: "95%", display: "flex", alignItems: "flex-end", justifyContent: { xs: "flex-start", sm: "flex-end" }, padding: { xs: "0px 10px", md: "20px 0", lg: "40px 0" } }}>
              <NavLink to={`/Blog${Blogs[Blogs.length - 1].link}`} style={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: { xs: "14px", md: "20px" }, color: "text.greenLight" }}>{t("blogText")}</Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', flexDirection: "column", width: { xs: "264px", sm: "310px", md: "621px" }, margin: { xs: "", sm: "", md: "40px" } }}>
          <TextField
            label={t("search")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
            className="textField"
            variant='outlined'
            fullWidth
            size="small"
            InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "16px" : "12px" } } }}
            sx={{
              width: { xs: "264px", sm: "310px", md: "621px" }, background: "background.main", border: "1px solid #77BABF", borderRadius: "100px",
              "& fieldset": { border: 'none' }, boxShadow: "0.5px 0.5px 2px 0px rgba(106, 167, 171, 0.40)"
            }}
            InputProps={{
              endAdornment: <Button onClick={searchHandeler}><SearchIcon sx={{ color: "#969696" }} /></Button>,
            }}
          />
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "100%" }}>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "43px", sm: "47px", md: "97px" }, height: { xs: "17px", sm: "20px", md: "43px" },
              borderRadius: "26px", backgroundColor: "background.blueLight", margin: "10px 3px"
            }}>
              <NavLink to={`/Blog/Suggested`} style={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: { xs: "9px", md: "18px" }, color: "text.grayLight" }}>{t("Suggested")}</Typography>
              </NavLink>
            </Grid>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "43px", sm: "47px", md: "97px" }, height: { xs: "17px", sm: "20px", md: "43px" },
              borderRadius: "26px", backgroundColor: "background.blueLight", margin: "10px 3px"
            }}>
              <NavLink to={`/Blog/Beginner`} style={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: { xs: "9px", md: "18px" }, color: "text.grayLight" }}>{t("Beginner")}</Typography>
              </NavLink>
            </Grid>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "43px", sm: "47px", md: "97px" }, height: { xs: "17px", sm: "20px", md: "43px" },
              borderRadius: "26px", backgroundColor: "background.blueLight", margin: "10px 3px"
            }}>
              <NavLink to={`/Blog/Intermediate`} style={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: { xs: "9px", md: "18px" }, color: "text.grayLight" }}>{t("Intermediate")}</Typography>
              </NavLink>
            </Grid>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "43px", sm: "47px", md: "97px" }, height: { xs: "17px", sm: "20px", md: "43px" },
              borderRadius: "26px", backgroundColor: "background.blueLight", margin: "10px 3px"
            }}>
              <NavLink to={`/Blog/Advanced`} style={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: { xs: "9px", md: "18px" }, color: "text.grayLight" }}>{t("Advanced")}</Typography>
              </NavLink>
            </Grid>
            <Grid sx={{
              display: authCheck ? "flex" : "none", alignItems: "center", justifyContent: "center", width: { xs: "77px", sm: "82px", md: "166px" }, height: { xs: "17px", sm: "20px", md: "43px" },
              borderRadius: "26px", backgroundColor: "background.blueLight", margin: "10px 3px"
            }}>
              <NavLink to={`/Blog/Favorite`} style={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: { xs: "9px", md: "18px" }, color: "text.grayLight" }}>{t("favoriteArticle")}</Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          display: "flex", alignItems: 'center', justifyContent: "center", width: "100%", flexDirection: "column", margin: "15px",
          background: (theme) => theme.palette.background.between, padding: { xs: "10px", sm: "20px", md: "50px 0", lg: "50px" }
        }}>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: { xs: "90%", sm: "600px", md: "900px", lg: "1150px", xl: "1260px" }, marginTop: "20px" }}>
            <Typography sx={{ color: "#636363", fontSize: { xs: "20px", sm: "26px", md: "35px", lg: "44px" } }}>{t("lastest")}</Typography>
            <NavLink to="/Blog/Lastest" style={{ textDecoration: "none" }}>
              <Typography sx={{ color: "#0C5463", fontSize: { xs: "14px", sm: "16px", md: "22px" }, display: { xs: "none", sm: "flex" } }}>{t("moreBlog")}</Typography>
            </NavLink>
          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "20px 0" }}>
            <Swiper
              style={{
                "--swiper-pagination-color": '#FF9355',
              }}
              slidesPerView={slides}
              spaceBetween={5}
              navigation
              pagination={{
                clickable: true,
                slidesPerView: 1,
                spaceBetween: 10,
              }}
              autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: true, waitForTransition: true }}
              className="swiperSorinMag"
            >

              {
                lastestBlog !== null ?
                  lastestBlog.map((item, index) => (
                    <SwiperSlide key={index} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", paddingBottom: "40px" }}>
                      <Grid sx={{
                        display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "263px", sm: "180px", md: "290px", lg: "360px" },
                        height: { xs: "250px", md: "380px", lg: "414px" }, boxShadow: "0.6000000238418579px 1.2000000476837158px 4.200000286102295px 0px rgba(0, 0, 0, 0.15)",
                        backgroundColor: "background.secondary", borderRadius: { xs: "8px", md: "15px" }, margin: { xs: "8px", sm: "10px 0", md: "20px 0" }
                      }}>
                        <Grid sx={{
                          display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "100% 100%", backgroundImage: `url(${item.image})`, borderRadius: { xs: "8px", md: "15px" },
                          backgroundPosition: "center", backgroundRepeat: "no-repeat", width: { xs: "90%", sm: "158px", md: "270px", lg: "317px" },
                          height: { xs: "115px", md: "150px", lg: "206px" }, marginTop: { sm: "10px", md: "20px" }
                        }}>
                          <Grid sx={{
                            display: "flex", alignItems: "flex-start", justifyContent: "center", backgroundSize: "100% 100%", backgroundImage: `url(${backCard})`, borderRadius: { xs: "8px", md: "15px" },
                            backgroundPosition: "center", backgroundRepeat: "no-repeat", width: "100%",
                            height: { xs: "104px", sm: "103px", md: "150px", lg: "206px" }, opacity: "0", "&:hover": { opacity: "1" }, transition: "opacity 0.3s"
                          }}>
                            <Typography sx={{ color: "text.secondary", fontSize: { xs: "12px", md: "20px" }, textAlign: "justify", padding: "30px 10px" }}>{item.h1}</Typography>
                          </Grid>
                        </Grid>
                        <Grid sx={{
                          display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", height: "21px", margin: "5px",
                          borderRight: i18n.language === "fa" ? item.tag === "Advanced" ? "3px solid #FF9254" : item.tag === "Beginner" ? "3px solid #77BABF" : item.tag === "Intermediate" && "3px solid #0C5463" : "unset",
                          borderLeft: i18n.language === "en" ? item.tag === "Advanced" ? "3px solid #FF9254" : item.tag === "Beginner" ? "3px solid #77BABF" : item.tag === "Intermediate" && "3px solid #0C5463" : "unset"
                        }} >
                          <Typography sx={{
                            fontSize: { xs: "11px", md: "17px" }, padding: "0 10px", color: item.tag === "Advanced" ? "text.orange" : item.tag === "Beginner" ? "text.greenBlue" : item.tag === "Intermediate" && "text.greenDark"
                          }}
                          >{t(item.tag)}</Typography>
                        </Grid>
                        <Typography sx={{ fontSize: { xs: "9px", md: "17px" }, color: "text.secondary", padding: "0 20px", textAlign: "justify" }}>{item.desc.length <= 100 ? item.desc : (item.desc.substr(0, 100) + "...")}</Typography>
                        <Grid sx={{ width: "95%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", padding: "10px 20px" }}>
                          <NavLink to={`/Blog${item.link}`} style={{ textDecoration: "none" }}>
                            <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, color: "#7CC2C7" }}>{t("blogText")}</Typography>
                          </NavLink>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))
                  :
                  <Typography key={"data2"}></Typography>
              }
            </Swiper>
          </Grid>
          <Typography sx={{ color: "#0C5463", fontSize: "14px", display: { xs: "flex", sm: "none" }, marginBottom: "20px" }}>{t("moreBlog")}</Typography>
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: 'center', justifyContent: "center", with: "100%", margin: { xs: "30px 0", sm: "50px 0", md: "100px 0" }
        }}>
          <Typography component={"a"} href="https://homeinja.com/" target="_blank">
            <Typography alt="bannerHomeinja" component={"img"} src={slides === 3 ? bannerHomeinjaMD : slides === 2 ? bannerHomeinjaSM : bannerHomeinjaXS}
              sx={{
                width: { xs: "315px", sm: "600px", md: "900px", lg: "1150px", xl: "1260px" }, height: { xs: "unset", sm: "130px", md: "180px", lg: "212px" },
                boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "15px",
              }} />
          </Typography>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" }, justifyContent: { xs: "center", sm: "space-between" }, width: { xs: "90%", sm: "600px", md: "900px", lg: "1150px", xl: "1260px" } }}>
          {
            randomBlog !== null ?
              randomBlog.map((item, index) => (
                <Grid key={index} sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "90%", sm: "48%" }, borderRadius: "15px", backgroundSize: "100% 100%", backgroundImage: `url(${item.image})`,
                  backgroundPosition: "center", backgroundRepeat: "no-repeat", height: { xs: "177px", md: "280px", lg: "320px" }, margin: { xs: "10px 0", sm: "10px" }
                }}>
                  <Grid sx={{
                    display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: "column", width: "100%", borderRadius: "15px", backgroundSize: "100% 100%", backgroundImage: `url(${imageCard})`,
                    backgroundPosition: "center", backgroundRepeat: "no-repeat", height: { xs: "177px", md: "280px", lg: "320px" }, opacity: "0", transition: "opacity 0.3s", "&:hover": { opacity: "1" }
                  }}>
                    <Typography sx={{ fontSize: { xs: "15px", md: "29px" }, color: "text.white", textAlign: "center", width: "90%", margin: "0 auto" }}>{item.h1}</Typography>
                    <Typography sx={{ fontSize: { xs: "10px", md: "20px" }, color: "text.white", textAlign: "center", width: "90%", margin: "0 auto" }}>{item.desc.length <= 100 ? item.desc : (item.desc.substr(0, 100) + "...")}</Typography>
                    <NavLink to={`/Blog${item.link}`} style={{ textDecoration: "none" }}>
                      <Typography sx={{ fontSize: { xs: "11px", md: "20px" }, color: "text.greenLight", textAlign: "justify" }}>{t("blogText")}</Typography>
                    </NavLink>
                  </Grid>
                </Grid>
              ))
              :
              <Typography key={"data3"}></Typography>
          }
        </Grid>
        <Grid sx={{
          display: "flex", alignItems: 'center', justifyContent: "center", width: { xs: "90%", sm: "600px", md: "900px", lg: "1150px", xl: "1260px" }, flexDirection: "column", margin: { xs: "30px 0", sm: "50px 0", lg: "100px 0" }
        }}>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: { xs: "90%", sm: "600px", md: "900px", lg: "1150px", xl: "1260px" }, margin: "20px 0" }}>
            <Typography sx={{ color: "text.grayDark", fontSize: { xs: "20px", sm: "26px", md: "35px", lg: "44px" } }}>{t("SuggestedTitle")}</Typography>
            <NavLink to="/Blog/Suggested" style={{ textDecoration: "none" }} >
              <Typography sx={{ color: "#0C5463", fontSize: { xs: "14px", sm: "16px", md: "22px" } }}>{t("moreBlog")}</Typography>
            </NavLink>
          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
            <Swiper
              style={{
                "--swiper-pagination-color": '#FF9355',
              }}
              slidesPerView={slides}
              spaceBetween={5}
              navigation
              pagination={{
                clickable: true,
                slidesPerView: 1,
                spaceBetween: 10,
              }}
              autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: true, waitForTransition: true }}
              className="swiperSorinMag"
            >
              {
                listSuggested !== null ?
                  listSuggested.map((item, index) => (
                    <SwiperSlide key={index}>
                      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", paddingBottom: "30px" }}>
                        <Grid sx={{
                          backgroundSize: "100% 100%", backgroundImage: `url(${item.image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                          height: "177px", width: "95%", borderRadius: "8px"
                        }}>
                        </Grid>
                        <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", margin: "10px 0", width: "90%" }}>
                          <Typography sx={{
                            fontSize: { xs: "12px", sm: "14px", md: "16px" }, padding: "0 10px", color: item.tag === "Advanced" ? "text.orange" : item.tag === "Beginner" ? "text.greenBlue" : item.tag === "Intermediate" && "text.greenDark"
                          }}
                          >{t(item.tag)}</Typography>
                          <Typography sx={{
                            fontSize: { xs: "10px", sm: "12px", md: "14px" }, padding: "0 10px", color: "text.secondary"
                          }}
                          >{t(item.h1)}</Typography>
                          <Grid sx={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-end", padding: "10px 0" }}>
                            <NavLink to={`/Blog${item.link}`} style={{ textDecoration: "none" }}>
                              <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "14px" }, color: "text.greenLight" }}>{t("blogText")}</Typography>
                            </NavLink>
                          </Grid>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))
                  :
                  <Typography key={"data4"}></Typography>
              }
            </Swiper>
          </Grid>
        </Grid>
        <Grid sx={{ display: "none" }}>
          <Typography sx={{ display: "none" }}>
            بلاک آکادمی صرافی بزرگ ارز دیجیتال کریپتوکامرس به عنوان جامع ترین مرجع آموزش تمامی ارز های دیجیتال معتبر جهان است. از مطالب و مقالات با سطح مبتدی مثل معرفی ارز دیجیتال بیت و یا اتریوم چیست و چگونه کار می کند تا سطح پیشرفته مثل نحوه نوشتن قرارداد هوشمند بر بستر اتریوم را می توانید در بلاگ مطالعه نمایید. بنابراین می توانید یک آموزشگاه مجازی کامل ارز دیجیتال را در اختیار داشته باشید.
          </Typography>
          <Typography sx={{ display: "none" }}>
            ما در بزرگترین صرافی ارز دیجیتال ایرانی کریپتوکامرس سعی کردیم علاوه بر افزایش آگاهی به آموزش کامل تحلیل تکنیکال و تحلیل فاندامنتال ارز های دیجیتال بپردازیم. تا بلاگ کریپتوکامرس به عنوان اصلی ترین مرجع آموزش بلاکچین و رمزارز تبدیل شود. فناوری نوظهور بلاک چین و رمزارز ها که به اصطلاح کریپتوکارنسی گفته می شود به دلیل جدید بودن اطلاعات زیادی در سطح اینترنت وجود ندارد. به همین دلیل اقدام به جمع آوری و تهیه مقالات مرتبط با حوزه بلاک چین کرده ایم.
          </Typography>
          <Typography sx={{ display: "none" }}>
            برای مثال مطالبی از نوع خرید و فروش ارز دیجیتال در ایران چگونه است؟. یا معامله و ترید ارز دیجیتال چیست و یا تحلیل تکنیکال چگونه انجام میشود و یا تحلیل فاندامنتال یعنی چه. به همین دلیل صرافی رمزارز کریپتوکامرس  به عنوان مرجع کامل آموزش و تحلیل بازار ارز دیجیتال و بلاکچین در ایران شاخته شده است.
          </Typography>
          <Typography sx={{ display: "none" }}>
            رخداد اصلی که در بلاگ آکادمی کریپتوکامرس اتفاق می افتد آموزش کامل ارز دیجیتال و بلاکچین همراه با تحلیل تکنیکال و فاندامنتال رمزارزهاست. کریپتوکامرس در قسمت جزئیات رمزارز ها هم مطلب کاملی از ارزهای دیجیتالی که پشتیبانی می کند، ارائه داده است. در صفحه مربوط به بلاگ آموزشی ارز دیجیتال کریپتوکامرس به ارائه جدیدترین و کاملترین و بهترین مقالات و مطالب آموزشی ارز دیجیتال و بلاکچین پرداخته است. همچنین توضیح می دهد که فناوری و تکنولوژی بلاک چین در صنعت و زندگی روزمره چه تاثیر دارد و آینده صنعت بلاک چین به کدام سو می رود.
          </Typography>
          <Typography sx={{ display: "none" }}>
            در بلاگ آکادمی کریپتوکامرس به عنوان کامل ترین مرجع یادگیری و تدریس ارز دیجیتال ، نحوه بوجود آمدن بیت کوین ، تاریخچه ارز های دیجیتال ، خالق اتریوم ، ایلان ماسک و افراد سرشناس بازار و ... صحبت خواهیم کرد. در صفحه بلاگ آکادمی بهترین و معتبرترین صرافی رمزارز داخلی از تمام ارزهای دیجیتال موجود در صرافی کریپتوکامرس صحبت خواهیم کرد. برای مثال مقاله از پیدایش و نحوه کار بیت کوین ، مقاله نحوه استخراج بیت کوین ، توکنومیکیس و نقشه راه بیت کوین صحبت شده است.
          </Typography>
          <Typography sx={{ display: "none" }}>
            هم چنین تیم محتوای شرکت وب گستران سورین که زحمت تهیه و نگارش مقالات و اخبار صرافی ارز دیجیتال کریپتوکامرس را بر عهده دارد سعی بر ساده نوشتن مطالب و درک آسان مطالب توسط کاربران نموده است. همواره به روزترین مقالات حوزه ارز دیجیتال و بلاکچین ( بلاک چین ) را پوشش می دهد. البته به علت تعداد فراوان ارزهای دیجیتال چالش فراوانی پیش روی نویسندگان سورین قرار دارد.
          </Typography>
          <Typography sx={{ display: "none" }}>
            صرافی معتبر و بزرگ کریپتوکامرس علاوه بر در ارائه بستر امن خرید و فروش ارز دیجیتال و همچنین ارائه راهکارهای افزایش سود مثل درامدزایی و وام دهی ، به تهیه و نگارش ارزنده ترین مطالب و مقالات رمزارزها و تحلیل تکنیکال ارز دیجیتال و اخبار فاندامنتال آنها روی آورده است. تیم محتوای سورین که مسئول ارایه مقالات آموزشی حوزه ارز دیجیتال و بلاکچین است سعی کرده روزانه مقالات و اخبار مفید کاربران را منتشر کند و در راستای افزایش اطلاعات کاربران به صورت رایگان کار کند.
          </Typography>
          <Typography sx={{ display: "none" }}>
            آکادمی ارز دیجیتال کریپتوکامرس که به عنوان مرجع اصلی آموزش ارز دیجیتال و بلاک چین در ایران می باشد مقالات دیگری در زمینه تکنولوژی، هوش مصنوعی ، طراحی سایت و اپلیکیشن ، سئو ، بازار املاک و ... دارد. اطلاع رسانی رایگان از طریق انتشار مقالات و مطالب کاربری از جمله رسالت شرکت وب گستران سورین و صرافی کریپتوکامرس است. از طریق آگاهی رسانی و ارائه مطالب مفید سعی در جذب کاربر فعال حوزه ارز دیجیتال و بلاکچین کند.
          </Typography>
          <Typography sx={{ display: "none" }}>
            آموزشگاه آنلاین بلاگ آکادمی صرافی بزرگ ارز دیجیتال کریپتوکامرس به آموزش کلاهبرداری های پانذی حوزه ارز دیجیتال می پردازد و انواع دارایی های دیجیتال را بررسی می کند. همچنین سعی می کند بهترین رمزارزهای جهان روا را به صرافی خود اضافه کند. هر زمان احساس کرد تیم فنی ارز دیجیتال مربوطه به سیاست های خود عمل نکرده اقدام به حذف رمزارز می کند. هم چنان که ارز دیجیتال دلار بایننس را از صرافی خود حذف کرد و دلارهای دیگر را به صرافی اضافه نمود.
          </Typography>
          <Typography sx={{ display: "none" }}>
            از جمله مطالب مرجع جامع آموزش و تدریس نحوه کار و درامدزایی از ارز دیجیتال کریپتوکامرس میتوان به رخدادهای حوزه تکنولوژی مخصوصا بلاک چین و تاثیر قیمتی آنها اشاره کرد.
          </Typography>
          <Typography sx={{ display: "none" }}>
            پس اگر به دنبال سرمایه گذاری و یا ترید ارزهای دیجیتال هستید اما اطلاعاتی در این زمینه ندارید پیشنهاد می کنیم به بلاگ آکادمی ارز دیجیتال صرافی کریپتوکامرس مراجعه نمایید. از مطالعه مقالات با سطح مبتدی شروع کنید تا به مقالات پیشرفته برسید. برای آموزش نحوه خرید و فروش ارز دیجیتال و هم چنین ترید و نوسان گیری از رمزارزها به صفحه اطلاع رسانی جامع صرافی معتبر ارز دیجیتال کریپتوکامرس مراجعه کنید.  درصورتی که مقاله ای در زمینه ارز دیجیتال تالیف کرده اید می توانید برای تیم تولید محتوای سورین و یا نویسندگان کریپتوکامرس به ادرس ایمیل وب سایت های سورین ارسال کنید تا با نام و لینک شما منتشر شود.
          </Typography>
        </Grid>
      </Grid >
      <Footer />
    </>
  )
}

export default Blog;