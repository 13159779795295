const errors = {};
export const emailValidate = (data, t) => {
  if (!data) {
    errors.email = t("emailRequired")
  } else if (! /[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](gmail.com|hotmail.com|yahoo.com)/.test(data)) {
    errors.email = t("emailInvalid");

  } else {
    delete errors.email;
  }
  return errors;
}

export const emailLoginValidate = (data, t) => {
  if (!data) {
    errors.email = t("emailRequired")
  } else if (! /^\S+@\S+\.\S+$/.test(data)) {
    errors.email = t("emailInvalid");

  } else {
    delete errors.email;
  }
  return errors;
}

export const passValidate = (data, t) => {
  if (!data) {
    errors.pass = t("passwordRequierd");
  } else if (data.length < 8) {
    errors.pass = t("passwordShort");
  } else {
    delete errors.pass;
  }
  return errors;
}

export const googleValidate = (data, t) => {
  if (!data) {
    errors.google = t("googleRequierd");
  } else if (data.length < 6) {
    errors.google = t("googleShort");
  } else {
    delete errors.google;
  }
  return errors;
}
export const repeatPassValidate = (data, pass, t) => {
  if (!data) {
    errors.repeatPass = t("repeatPasswordRequierd")
  } else if (data.length < 8) {
    errors.repeatPass = t("RepeatPasswordShort");
  } else {
    delete errors.repeatPass;
  }
  return errors;
}
export const smsValidate = (data, t) => {
  if (!data) {
    errors.sms = t("smsRequierd");
  } else if (data.length < 6) {
    errors.sms = t("smsShort");
  } else {
    delete errors.sms;
  }
  return errors;
}

export const mobileNumberValidate = (data, t) => {
  if (!data) {
    errors.mobile = t("mobileNumberRequierd");
  } else if (data.length < 11) {
    errors.mobile = t("mobileNumberShort");
  }
  else {
    delete errors.mobile;
  }
  return errors;
}

export const phoneNumberValidate = (data, t) => {
  if (!data) {
    errors.phone = t("phoneNumberRequierd");
  } else if (data.length < 11) {
    errors.phone = t("phoneNumberShort");
  }
  else {
    delete errors.phone;
  }
  return errors;
}

export const nationalCodeValidate = (data, t) => {
  if (!data) {
    errors.national = t("nationalCodeRequierd");
  } else if (data.length < 10) {
    errors.national = t("nationalCodeShort");
  }
  else {
    delete errors.national;
  }
  return errors;
}

export const postalCodeValidate = (data, t) => {
  if (!data) {
    errors.postal = t("postalCodeRequierd");
  } else if (data.length < 10) {
    errors.postal = t("postalCodeShort");
  }
  else {
    delete errors.postal;
  }
  return errors;
}

export const cardNumberValidate = (data, t) => {
  if (!data) {
    errors.cardNumber = t("cardNumberRequierd");
  } else if (data.length < 16) {
    errors.cardNumber = t("cardNumberShort");
  }
  else {
    delete errors.cardNumber;
  }
  return errors;
}

export const shebaValidate = (data, t) => {
  if (!data) {
    errors.sheba = t("shebaRequierd");
  } else if (data.length < 24) {
    errors.sheba = t("shebaShort");
  }
  else {
    delete errors.sheba;
  }
  return errors;
}

export const accountNumberValidate = (data, t) => {
  if (!data) {
    errors.accountNum = t("accountNumRequierd");
  } else if (data.length < 10) {
    errors.accountNum = t("accountNumShort");
  }
  else {
    delete errors.accountNum;
  }
  return errors;
}