export const baseUrl = "https://api.mycryptocommerce.com";

export const setCanonicalTag = () => {
  let link = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', window.location.protocol + '//' + window.location.host + window.location.pathname);
  document.head.appendChild(link);
}

export const separator = (value) => {
  value = value.toString();
  var place = 0;
  if (value.includes(".")) {
    place = value.indexOf(".");
    var num1 = value.slice(0, place);
    var num2 = value.slice(place + 1, value.length);
    value = camaInsert(num1, false) + "." + camaInsert(num2, true);
  }
  else {
    value = camaInsert(value);
  }
  return value;
}

function camaInsert(value, after = false) {
  var output = [];
  var outputString = "";
  if (after) {
    for (var i = 1; i <= value.length; i++) {
      if (i % 3 === 0) {
        output.push(value.slice(i - 3, i))
      }
    }
    if (value.length % 3 !== 0) {
      output.push(value.slice(value.length - value.length % 3, value.length))
    }

    for (var j = 0; j < output.length; j++) {
      if (j + 1 < output.length) {
        outputString = outputString + output[j] + ",";
      }
      else {
        outputString = outputString + output[j];
      }
    }
  }
  else {
    for (var p = 1; p <= value.length; p++) {
      if (p % 3 === 0) {
        output.push(value.slice(value.length - p, value.length - p + 3))
      }
    }
    if (value.length % 3 !== 0) {
      output.push(value.slice(0, value.length % 3))
    }
    for (var o = output.length - 1; o >= 0; o--) {
      if (o > 0) {
        outputString = outputString + output[o] + ",";
      }
      else {
        outputString = outputString + output[o];
      }
    }
  }
  return outputString;
}

export function stringValue(value) {
  var output = "";
  value = value.toString();
  value = value.split(",");
  for (var i = 0; i < value.length; i++) {
    output += value[i].toString();
  }
  output = output.replace("/", ".");
  return output;
}

export function replaceNumber(value, la) {
  value = value.toString();
  if (la === "fa") {
    value = value.replace(".", "/");
  }
  return value;
}
export function onlyDigit(value) {
  value = value.trim();
  var regex = new RegExp("^[0-9]*$");
  if (regex.test(value)) {
    return value
  }
  else {
    var len = value.length;
    return value.substr(0, len - 1)
  }
}
export const MainEmail = (value) => {
  var point = value.indexOf("@");
  return value.slice(0, point);
}
export const EmailSecure = (value) => {
  var point = value.indexOf("@");
  var main = value.slice(0, 3);
  var email = main + "***" + value.slice(point - 2, point) + value.slice(point, value.length);
  return email;
}
export const stepSize = (step) => {
  let index = step.indexOf("1") - 1;
  return index;
}
export function onlyFloatnaumber(value) {
  value = value.trim();
  value = stringValue(value);
  var regex = new RegExp("^[0-9]*[.]*[0-9]*$");
  if (regex.test(value)) {
    if (value[0] !== '0') {
      return value;
    }
    else {
      if (value.length === 1) {
        return value;
      }
      else {
        if (value.length === 2) {
          if (value[0] === '0' && value[1] === '.') {
            return value;
          }
          else {
            if (value[0] === '0' && value[1] !== '.') {
              return value[1];
            }
          }
        }
        else {
          if (value.length > 2) {
            if (value.substr(2).includes('.')) {
              return value[0] + value[1];
            }
            else {
              return value;
            }
          }
        }
      }
    }
  }
  else {
    return '';
  }
}

export const fixedNumber = (value) => {
  let data = value.toString().split(".")
  let dataNum = data.length === 2 ? data[1].length > 5 ? parseFloat(value).toFixed(6) : value : value
  return separator(dataNum)
}

export const cardSeprator = (value) => {
  let result = value.replace(/(.{4})/g, "$1 ");
  return result;
}

export const onlyDigitNotZero = (value) => {
  value = value.trim();
  var len;
  if (value[0] === "0") {
    len = value.length;
    return value.substr(0, len - 1)
  }
  var regex = new RegExp("^[0-9,]+$");
  if (regex.test(value)) {
    return value
  }
  else {
    len = value.length;
    return value.substr(0, len - 1)
  }
}

export const fixedNum = (value, fixed) => {
  let data = value.toString().split(".")
  if (data.length === 2) {
    if (data[1].length > fixed) {
      data[1] = data[1].slice(0, fixed)
    }
    if (data[1] !== "") {
      return `${data[0]}.${data[1]}`
    }
    else {
      return `${data[0]}`
    }
  }
  else {
    return value
  }

}


