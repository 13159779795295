import { Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import TradeBox from "./tradeBox";
import { NavLink } from "react-router-dom";
import backgroundHeader from '../image/header/backgroundHeader.png';
import '../style/tradeBox.css';

export default function Header({ authCheck, infoUser }) {
  const [auth, setAuth] = useState(false);
  const { t, i18n } = useTranslation();
  const [pageSize, setPageSize] = useState(window.innerWidth < 600 ? 1 : 2);

  useEffect(() => {
    window.addEventListener('resize', () => resizePageSize());
    return (() => window.removeEventListener('resize', () => resizePageSize()));
  });

  const resizePageSize = () => {
    setPageSize(
      window.innerWidth <= 600
        ? 1
        : 2
    );
  }

  useEffect(() => {
    setAuth(authCheck)
  }, [authCheck])

  return (
    <>
      <Grid sx={{
        background: `url(${backgroundHeader})`, backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", direction: i18n.language === "fa" ? "rtl" : "ltr",
        display: "flex", justifyContent: "center", alignItems: "center", height: { xs: "40vh", sm: "60vh", md: "550px", xl: "60vh" }, marginTop: { xs: "60px", md: "80px" }
      }}>
        <Grid sx={{ width: { sm: "95%", md: "95%", lg: "80%", xl: "100%" }, maxWidth: "1340px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "25px 0", flexDirection: 'row' }}>
          <Grid item xs={12} sm={7} sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", sm: "flex-start" }, flexDirection: "column", margin: "20px", width: "100%" }}>
            <Typography className="headerTitle" sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "15px", sm: "18px", md: "22px", lg: "28px", xl: "32px" } : { xs: "13px", sm: "16px", md: "20px", lg: "24px", xl: "32px" }, textAlign: { xs: "center", sm: i18n.language === "fa" ? "right" : "left" } }}>
              {t("headerTitle")}
            </Typography>
            <Typography className="headerTitle2" sx={{ color: "text.secondary", marginTop: "2%", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px", md: "20px", lg: "24px", xl: "28px" } : { xs: "11px", sm: "13px", md: "18px", lg: "20px", xl: "30px" }, textJustify: "inter-word", textAlign: { xs: "center", sm: i18n.language === "fa" ? "right" : "left" } }}>
              {t("headerSubtitle")}
            </Typography>
            <Grid sx={{ width: "100%", display: "flex", justifyContent: { xs: "center", sm: "flex-start" } }}>
              <NavLink to={auth === false ? '/Register' : '/Market'} className="navlink" >
                <Button
                  className="headerButton"
                  sx={{
                    background: (theme) => theme.palette.button.primary, color: "#FCFCFC", fontSize: i18n.language === "fa" ? { xs: "12px", md: "18px", lg: "20px" } : { xs: "12px", md: "18px", lg: "20px" },
                    height: { xs: "45px", sm: "50px", md: "60px", lg: "68px", xl: "75px" }, width: { xs: "210px", sm: "190px", md: "240px", lg: "300px", xl: "350px" }, margin: "20px 0 0", "&:hover": { background: (theme) => theme.palette.button.primaryHover },
                    transition: "all 0.3s", borderRadius: "69px", boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.28)"
                  }}>
                  {auth === false ? t("registerAndStart") : t("goExchenge")}
                  <Grid sx={{ width: "10px", height: "100%" }}></Grid>
                  <CurrencyExchangeRoundedIcon sx={{ fontSize: { xs: "20px", sm: "16px", md: "25px", lg: "30px" } }} />
                </Button>
              </NavLink>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "column", margin: "20px" }}>
            {
              pageSize === 2 &&
              <Grid sx={{ width: { xs: "90%", sm: "100%", md: "100%", lg: "100%" } }}>
                <TradeBox authCheck={authCheck} infoUser={infoUser} />
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      {
        pageSize === 1 &&
        <Grid sx={{ display: { xs: "flex", sm: "none" }, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <TradeBox authCheck={authCheck} infoUser={infoUser} />
        </Grid>
      }
    </>
  )
}