import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMessage({ open, status, message, duration, close, severity, page }) {
  const { t, i18n } = useTranslation()
  const [messageAlert, setMessageAlert] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    setMessageAlert(message)
  }, [message])

  let error404 = [
    { page: "Withdrawal", message: t("accountNumberFailed") },
    { page: "Loan", message: t("AM_BadRequest") },
    { page: "Stacking", message: t("AM_notFound") },
    { page: "TradeBox", message: t("AM_NotPrice") },
    { page: "Calculate", message: t("AM_NotPrice") },
    { page: "Exchange", message: t("notMarket") },
  ]

  useEffect(() => {
    if ((status !== null) || (status !== undefined)) {
      switch (status) {
        case (401):
          setMessageAlert(t("tokenExpire"))
          localStorage.clear("TACC")
          localStorage.clear("CryptoInfoUser")
          const timeout = setTimeout(() => {
            window.location.pathname = "Login"
            //navigate('/Login');
          }, 3000)
          return () => {
            clearTimeout(timeout)
          };
        case (498):
          setMessageAlert(t("invalidToken"))
          localStorage.clear("TACC")
          localStorage.clear("CryptoInfoUser")
          const timeout498 = setTimeout(() => {
            window.location.pathname = "Login"
            //navigate('/Login');
          }, 3000)
          return () => {
            clearTimeout(timeout498)
          };
        case (403):
          window.location.pathname = "Register"
          //navigate("/Register")
          break;
        case (405):
          setMessageAlert(t("accessLevel"))
          break;
        case (406):
          setMessageAlert(t("accessLevel"))
          break;
        case (407):
          setMessageAlert(t("accessLevel"))
          break;
        case (408):
          setMessageAlert(t("userDeactive"))
          break;
        case (500):
          setMessageAlert(t("AM_intervalServer"))
          break;
        case (402):
          page === "Withdrawal" ? setMessageAlert(t("WithdrawalDisabled")) : setMessageAlert(t("AM_noinventory"))
          break;
        case (400):
          setMessageAlert(t("AM_BadRequest"))
          break;
        case (430):
          setMessageAlert(t("AM_wrong_google"))
          break;
        case (431):
          setMessageAlert(t("AM_wrong_sms"))
          break;
        case (404):
          setMessageAlert(errorPage(page, error404))
          break;
        case (437):
          setMessageAlert(t("AM_noinventory"))
          break;
        case (448):
          setMessageAlert(t("AM_exchangeNoInventory"));
          break;
        case (452):
          setMessageAlert(t("mr"));
          break;
        case (409):
          setMessageAlert(t("AM_conflit"))
          const timeout409 = setTimeout(() => {
            window.location.pathname = "Login"
            //navigate('/Login');
          }, 3000)
          return () => {
            clearTimeout(timeout409)
          };
        case (445):
          setMessageAlert(t("AM_cancelOrderFaild"))
          break;
        case (450):
          setMessageAlert(t("AM_Email_confirmation"))
          break;
        case (422):
          setMessageAlert(t("AM_lowestLoan"))
          break;
        case (429):
          setMessageAlert(t("toManyRequest"))
          break;
        case (410):
          setMessageAlert(t("withdrawBlocked"))
          break;
        case (413):
          setMessageAlert(t("largefile"))
          break;
        case (411):
          setMessageAlert(t("AM_userLevelLow"))
          break;
        case (504):
          setMessageAlert(t("cantAccount"))
          break;
        default:
          break;
      }
    }

  }, [status])

  const errorPage = (page, data) => {
    let message;
    data.forEach((item) => {
      if (item.page === page) { message = item.message }
      if (item.page === "Exchange") {
        const timeout498 = setTimeout(() => {
          navigate('/Market');
        }, 3000)
        return () => {
          clearTimeout(timeout498)
        };
      }
    })
    return message
  }

  return (
    <Stack spacing={2} sx={{ ".MuiSnackbar-root": { direction: i18n.language === "fa" ? "rtl" : "ltr", textAlign: "center" } }}>
      <Snackbar open={open} autoHideDuration={duration} onClose={close} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={close} severity={severity} >
          {messageAlert}
        </Alert>
      </Snackbar>
    </Stack>
  );
}