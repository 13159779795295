import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import notFound from '../image/notFound/notFound.png';
import notFoundEn from '../image/notFound/notFoundEn.png';
import { useTranslation } from "react-i18next";
import banner from '../image/notFound/banner.png'
import { setCanonicalTag } from "./modules/general";

const NotFound = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // document.title = ""
    // document.getElementById("main-heading").innerHTML = "";
    // document.getElementsByTagName('meta')["description"].content ="";
    // document.getElementsByTagName('meta')["keywords"].content = "";
    setCanonicalTag();
  }, [])

  return (
    <>
      <Grid sx={{
        background: `url(${banner})`, backgroundPosition: "top", backgroundSize: { xs: "100% 10%", sm: "100% 30%", md: "100% 50%" },
        backgroundRepeat: "no-repeat", backgroundColor: "background.main", display: "flex", justifyContent: "center", alignItems: "center",
        width: "100vw", height: "100vh"
      }}>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", top: "30vh", position: "absolute" }}>
          <Typography component={"img"} alt="NotFound" src={i18n.language === "fa" ? notFound : notFoundEn} sx={{ width: "80%" }} />
        </Grid>
      </Grid>
    </>
  );
}
export default NotFound;