import React from "react";
import ArrowTopIcon from '../image/arrowTop/ArrowTop.png';
import ArrowTopXs from '../image/arrowTop/ArrowTopXs.png';
import { Grid , Typography } from "@mui/material";
const ArrowTop = () =>{
  const scrollTop =() =>{
    window.scrollTo({top:0, behavior:"smooth"});
  }
  return(
    <> 
      <Grid sx={{display:"flex",justifyContent:"flex-start",alignItems:"center",cursor:"pointer",position:'fixed',zIndex:"100",left:{xs:"0",sm:"20px"},top:"90vh"}}>
          <Typography onClick={scrollTop} component={"img"} alt="ArrowTop" src={ArrowTopIcon} sx={{width:{sm:"49px",md:"60px"},height:{sm:"49px",md:"60px"},display:{xs:"none",sm:"flex"}}}/>
          <Typography onClick={scrollTop} component={"img"} alt="ArrowTop" src={ArrowTopXs} sx={{width:"24px",height:"50px",display:{xs:"flex",sm:"none"},}}/>
      </Grid>
    </>
  )
}
export default ArrowTop;