import indexPhoto1 from '../../../image/blog/01/indexPhoto1.jpg'
import indexPhoto2 from '../../../image/blog/02/indexPhoto2.jpeg';
import indexPhoto3 from '../../../image/blog/03/indexPhoto3.jpeg';
import indexPhoto4 from '../../../image/blog/04/indexPhoto4.jpg';
import indexPhoto5 from '../../../image/blog/05/indexPhoto5.jpg';
import indexPhoto6 from '../../../image/blog/06/indexPhoto6.jpg';
import indexPhoto7 from '../../../image/blog/07/indexPhoto7.jpg';
import indexPhoto8 from '../../../image/blog/08/indexPhoto8.jpg';
import indexPhoto9 from '../../../image/blog/09/indexPhoto9.png';
import indexPhoto10 from '../../../image/blog/10/indexPhoto10.jpg';
import indexPhoto11 from '../../../image/blog/11/indexPhoto11.png';
import indexPhoto12 from '../../../image/blog/12/indexPhoto12.jpg';
import indexPhoto13 from '../../../image/blog/13/indexPhoto13.jpg';
import indexPhoto14 from '../../../image/blog/14/indexPhoto14.png';
import indexPhoto15 from '../../../image/blog/15/indexPhoto15.jpg';
import indexPhoto16 from '../../../image/blog/16/indexPhoto16.jpg';
import indexPhoto17 from '../../../image/blog/17/indexPhoto17.jpg';
import indexPhoto18 from '../../../image/blog/18/indexPhoto18.jpg';
import indexPhoto19 from '../../../image/blog/19/indexPhoto19.jpg';
import indexPhoto20 from '../../../image/blog/20/indexPhoto20.jpg';
import indexPhoto21 from '../../../image/blog/21/indexPhoto21.jpeg';
import indexPhoto22 from '../../../image/blog/22/indexPhoto22.jpg';
import indexPhoto23 from '../../../image/blog/23/indexPhoto23.webp';
import indexPhoto24 from '../../../image/blog/24/indexPhoto24.jpg';

// const tag = ["Beginner", "Advanced", "Intermediate"]

export const Blogs = [
  {
    id: 1,
    title: "بلاکچین چیست؟ توضیح فناوری بلاک چین به زبان ساده | کریپتوکامرس",
    h1: "بلاکچین چیست؟ توضیح فناوری بلاک چین به زبان ساده",
    desc: "بلاکچین زنجیره بلوکی از داده‌ها می باشد که اطلاعات خاصی دارد. این بلوک‌ها داده‌هایی را ذخیره می‌کنند که برای اکثر تراکنش ها استفاده می شوند.",
    keywords: "بلاکچین یعنی چی | توضیح فناوری پیچیده بلاکچین به زبان ساده | انقلاب تکنولوژی بلاکچین | بلاکچین چگونه کار می کند | مزایا و معایب بلاکچین |نقش بلاکچین در صنعت | فناوری زنجیره بلوکی چیست",
    image: indexPhoto1,
    author: "نیلوفر پورقلی",
    date: "1402,04,18",
    link: "/What-Is-Blockchain",
    Suggested: "yes",
    tag: "Beginner",
    timeRead: "8"
  },
  {
    id: 2,
    title: "ارز دیجیتال بیت کوین Bitcoin (BTC) چیست ؟ راهنمای کامل بیتکوین به زبان ساده ",
    h1: "بیت کوین چیست و چگونه کار می کند",
    desc: "بیت کوین پول الکترونیکی می باشد که بدون واسطه منتشر و منتقل می شود و هیچ نهادی در مدیریت آن دخالت ندارد و کنترل آن با مردم است.",
    keywords: "bitcoin )btc) چیست | بیت کوین به زبان ساده | فروش و خرید بیت کوین | تاریخچه بیت کوین | مخترع بیت کوین | استخراج BTC | کیف پول بیت کوین |بیت کوین رایگان | سایت بیت کوین | معاملات بیت کوین | طرافی ارز دیجیتال بیت کوین | رمز ارز بیت کوین چیست | صفر تا صد بیت کوین | ساتوشی ناکاموتو خالق بیت کوین",
    image: indexPhoto2,
    author: "تیم محتوای سورین",
    date: "1402,04,22",
    link: "/What-Is-Bitcoin",
    Suggested: "yes",
    tag: "Beginner",
    timeRead: "10"
  },
  {
    id: 3,
    title: "شبکه بلاکچین اتریوم چیست و چگونه کار می کند | کریپتوکامرس",
    h1: "اتریوم چیست؟ معرفی کامل شبکه اتریوم و ارز دیجیتال اتر",
    desc: " اتریوم یک سیستم آزاد و عمومی می باشد که بر پایه‌ی فناوری بلاکچین است و مرورگر،‌ زبان برنامه‌نویسی و سیستم پرداخت مخصوص به خود را دارد. ",
    keywords: "صفر تا صد اتریوم | اتریوم به زبان ساده | راهنمای کامل رمزارز اتر | قرارداد هوشمند اتریوم چیست | همه چیز در مورد اتریوم",
    image: indexPhoto3,
    author: "تیم محتوای سورین",
    date: "1402,04,27",
    link: "/What-Is-Ethereum",
    Suggested: "yes",
    tag: "Beginner",
    timeRead: "12"
  },
  {
    id: 4,
    title: "سایت های برتر برای تحقیق و بررسی ارزهای دیجیتال | کریپتوکامرس",
    h1: "در مورد رمزارزها از چه سایت‌هایی اطلاعات بگیریم؟!",
    desc: "با توجه به خیل عظیم اطلاعات در مورد ارز دیجیتال باید توانایی این را داشته باشیم که اطلاعات موثقی در مورد رمزارزها کسب کنیم. ",
    keywords: "بهترین سایت خبری ارزدیجیتال | معرفی سایت های پرکاربرد در زمینه اطلاعات ارزهای دیجیتال | نحوه ارزیابی پروژه های ارز دیجیتال",
    image: indexPhoto4,
    author: "نیلوفر پورقلی",
    date: "1402,05,03",
    link: "/The-Best-Sites-To-Get-Information-From-Cryptocurrencies",
    Suggested: "yes",
    tag: "Intermediate",
    timeRead: "8"
  },
  {
    id: 5,
    title: "معرفی کامل دوج کوین | آکادمی رمزارز کریپتوکامرس",
    h1: "زیر و بم دوج کوین را بدانید",
    desc: "هر کدام از لوگوهای معروف جهان داستانی را در خود دارند. ما در این مقاله از سورین به بررسی داستان های مربوط به بهترین لوگوهای جهان می پردازیم. ",
    keywords: "رمزارز پرطرفدار دوج کوین | قیمت دوج کوین | خرید DOGE | خرید دوج کوین | فروش دوج | اینده DOGE | تاریخچه دوج کوین | تحلیل دوج ",
    image: indexPhoto5,
    author: "تیم محتوای سورین",
    date: "1402,05,15",
    link: "/What-Is-DOGE-Coin",
    Suggested: "yes",
    tag: "Beginner",
    timeRead: "12"
  },
  {
    id: 6,
    title: "معرفی کامل مونرو | آکادمی رمزارز کریپتوکامرس",
    h1: "رمز و راز رمزارز مونرو چیست؟!",
    desc: " رمزارز مونرو (Monero) یک ارز دیجیتال متن باز می باشد که در حوزه حریم خصوصی فعالیت می کند و در سال ۲۰۱۴ راه‌اندازی شد. ",
    keywords: "رمزارز پرطرفدار  | قیمت مونرو | خرید XMR | خرید مونرو | فروش مونرو | اینده XRM  | تاریخچه مونرو | تحلیل دوج ",
    image: indexPhoto6,
    author: "تیم محتوای سورین",
    date: "1402,05,26",
    link: "/What-Is-Monero",
    Suggested: "yes",
    tag: "Beginner",
    timeRead: "12"
  },
  {
    id: 7,
    title: "همه چیز در مورد توکن NFT | آکادمی کریپتوکامرس ",
    h1: "توکن های غیر قابل تعویض یا  NFT ، دارایی های دیجیتال حیرت انگیز!",
    desc: "توکن های غیر قابل تعویض یا NFT دارایی های دیجیتالی یکتا و غیرتکراری می باشند که در هر فرمتی مانند موسیقی  بازی یا ویدئو ایجاد می شوند. ",
    keywords: "توکن های غیر قابل تقسیم | توکن های تقسیم ناپذیر | توکن های غیر قابل معاوضه | توکن های غیر مثلثی | NFT چیست | NFT چگونه کار می کند",
    image: indexPhoto7,
    author: "تیم محتوای سورین",
    date: "1402,06,08",
    link: "/What-Is-NFT",
    Suggested: "no",
    tag: "Beginner",
    timeRead: "10"
  },
  {
    id: 8,
    title: "معرفی کامل اتریوم کلاسیک | آکادمی کریپتوکامرس",
    h1: "اتریوم کلاسیک ، از بدو تولد تا چند سال آینده!",
    desc: "اتریوم کلاسیک یک هارد فورک اتریوم می باشد که در سال 2016 راه اندازی شد و عملکرد اتریوم کلاسیک به عنوان یک شبکه قرارداد هوشمند می باشد. ",
    keywords: "رمزارز پرطرفدار  | قیمت اتریوم کلاسیک | خرید ETC | خرید اتریوم کلاسیک | فروش اتریوم کلاسیک | اینده ETC  | تاریخچه اتریوم کلاسیک | تحلیل اتریوم کلاسیک ",
    image: indexPhoto8,
    author: "تیم محتوای سورین",
    date: "1402,06,17",
    link: "/What-Is-Ethereum-Classic",
    Suggested: "no",
    tag: "Beginner",
    timeRead: "10"
  },
  {
    id: 9,
    title: "معرفی صفر تا صد ساخت یک DAO | اکادمی کریپتوکامرس",
    h1: "DAO چیست و چگونه یک دائو ایجاد کنیم؟",
    desc: " DAO یا سازمان غیرمتمرکز خودمختار از گروهی افراد تشکیل می‌شود که تصمیم می‌گیرند از قوانین خاصی برای رسیدن به اهداف مشترک پیروی کنند. ",
    keywords: "سازمان مستقل غیرمتمرکز | نحوه کار سازمان خودگردان غیرمتمرکز | معرفی بهترین ارزهای دیجیتال DAO | بهترین پروژه های سازمان مستقل غیرمتمرکز",
    image: indexPhoto9,
    author: "تیم محتوای سورین",
    date: "1402,06,25",
    link: "/What-Is-DAO",
    Suggested: "no",
    tag: "Advanced",
    timeRead: "8"
  },
  {
    id: 10,
    title: "معرفی صفر تا صد ApeCoin | آکادمی رمزارز کریپتوکامرس",
    h1: "ارز دیجیتال ایپ کوین (Apecoin - APE) چیست؟",
    desc: " رمزارز ApeCoin توکنی است که روی شبکه اتریوم ایجاد شده است و نماد آن APE می باشد. این توکن  یک توکن حاکمیتی و کاربردی می باشد.",
    keywords: "بررسی آینده ارز دیجیتال APE | ارزدیجیتال میمون های خسته | ApeCoin چگونه کار می کند؟ | خرید ApeCoin | فروش ایپ کوین | تاریخچه ارز APE | تحلیل ارز APE",
    image: indexPhoto10,
    author: "تیم محتوای سورین",
    date: "1402,07,01",
    link: "/What-Is-ApeCoin",
    Suggested: "yes",
    tag: "Beginner",
    timeRead: "7"
  },
  {
    id: 11,
    title: "ارز دیجیتال SLP چیست و چگونه کار می کند؟ | کریپتوکامرس",
    h1: "SLP چیست و صفر تا صد بررسی ارزدیجیتال اس ال پی",
    desc: " SLP یا معجون عشق صاف یک توکن بازی می باشد که در بازی اینفینیتی از آن برای تولید موجوداتی به نام آکسی می توان استفاده کرد.",
    keywords: "بررسی آینده ارز دیجیتال SLP | ارزدیجیتال اس ال پی | SLP چگونه کار می کند؟ | خرید SLP | فروش اس ال پی | تاریخچه ارز SLP | تحلیل ارز SLP",
    image: indexPhoto11,
    author: "تیم محتوای سورین",
    date: "1402,07,11",
    link: "/What-Is-SLP",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "8"
  },
  {
    id: 12,
    title: "معرفی انواع دستگاه استخراج ارز دیجیتال | کریپتوکامرس",
    h1: "بررسی چند دستگاه استخراج ارز دیجیتال معروف",
    desc: "دستگاه های استخراج رمزارز یا ماینرها ابزارهای سخت‌افزاری قدرتمندی هستند که برای حل مسائل ریاضی پیچیده استفاده می‌شوند.",
    keywords: "آشنایی با ماینر رمزارز | کسب درامد با ماینر | آشنایی با دستگاه ماینینگ | آموزش استخراج ارز دیجیتال | آشنایی با استخراج انواع رمزارز",
    image: indexPhoto12,
    author: "تیم محتوای سورین",
    date: "1402,07,18",
    link: "/Introduce-Some-Crypto-Miners",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "10"
  },
  {
    id: 13,
    title: "معرفی ارز دیجیتال LTC | آکادمی کریپتوکامرس",
    h1: "لایت کوین چیست و همه چیز در مورد LTC",
    desc: "لایت کوین یک فورک از شبکه بیت کوین است که امکان پرداخت‌های فوری و کم هزینه را فراهم می کند و  LTC ارز دیجیتال بومی شبکه لایت کوین می باشد.",
    keywords: "بررسی آینده ارز دیجیتال LTC | ارزدیجیتال لایت کوین |خرید LTC | فروش لایت کوین | تاریخچه ارز LTC | تحلیل ارز LTC",
    image: indexPhoto13,
    author: "تیم محتوای سورین",
    date: "1402,07,25",
    link: "/What-Is-Lite-Coin",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "12"
  },
  {
    id: 14,
    title: "معرفی ارز دیجیتال NEM | آکادمی رمزارز کریپتوکامرس",
    h1: "رمزارز نم (NEM) ، رقیبی برای بیت کوین + رتبه و قیمت",
    desc: "رمزارز NEM برخلاف برخی از ارزهای دیجیتال مانند لایت کوین که از تغییرات کدنویسی بیت کوین بوجود آمد بصورت منحصر بفرد وارد دنیای رمزارزها شد. ",
    keywords: "بررسی آینده ارز دیجیتال NEM | ارزدیجیتال نم |خرید NEM | فروش نم | تاریخچه ارز NEM | تحلیل ارز NEM",
    image: indexPhoto14,
    author: "تیم محتوای سورین",
    date: "1402,07,28",
    link: "/What-Is-NEM",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "12"
  },
  {
    id: 15,
    title: "معرفی ارز دیجیتال XLM | آکادمی رمزارز کریپتوکامرس",
    h1: "  ارز دیجیتال XLM چیست؟ پیش ‌بینی قیمت استلار",
    desc: "استلار یک پروتکل متن باز است که جهت توکنایز کردن دارایی ها استفاده می شود و ارز دیجیتال لومن یا XML رمز ارز بومی شبکه استلار می باشد.",
    keywords: "بررسی آینده ارز دیجیتال XLM | ارزدیجیتال استلار |خرید XLM | فروش استلار | تاریخچه ارز XLM | تحلیل ارز XLM",
    image: indexPhoto15,
    author: "تیم محتوای سورین",
    date: "1402,07,30",
    link: "/What-Is-XLM",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "10"
  },
  {
    id: 16,
    title: "معرفی ارز دیجیتال MATIC | آکادمی رمزارز کریپتوکامرس",
    h1: "همه چیز در مورد ارز دیجیتال متیک یا پالیگان و پیش بینی قیمت متیک",
    desc: "پالیگان پروتکلی برای ساخت و اتصال شبکه‌های بلاک چین سازگار با اتریوم است که ارز دیجیتال MATIC رمز ارز بومی شبکه پالیگان می باشد.",
    keywords: "بررسی آینده ارز دیجیتال MATIC | ارزدیجیتال متیک |خرید MATIC | فروش پالیگان | تاریخچه ارز MATIC | تحلیل ارز MATIC",
    image: indexPhoto16,
    author: "تیم محتوای سورین",
    date: "1402,08,02",
    link: "/What-Is-MATIC",
    Suggested: "yes",
    tag: "Intermediate",
    timeRead: "12"
  },
  {
    id: 17,
    title: "معرفی کامل صرافی بایننس و ارز دیجیتال BNB | آکادمی کریپتوکامرس",
    h1: "همه چیز در مورد صرافی رمز ارز بایننس و ارز دیجیتال BNB",
    desc: "صرافی بایننس بزرگترین صرافی ار دجیتال دنیا می باشد که ارز دیجیتال BNB رمز ارز بومی شبکه بایننس اسمارت است.",
    keywords: "بررسی آینده ارز دیجیتال BNB | ارزدیجیتال بایننس کوین |خرید BNB | فروش بایننس کوین | تاریخچه ارز BNB | تحلیل ارز BNB",
    image: indexPhoto17,
    author: "تیم محتوای سورین",
    date: "1402,08,06",
    link: "/What-Is-BNB",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "15"
  },
  {
    id: 18,
    title: "معرفی کامل ارز دیجیتال اینترنت کامپیوتر | آکادمی کریپتوکامرس",
    h1: "همه چیز در مورد ارز دیجیتال ICP یا اینترنت کامپیوتر",
    desc: "پروتکل اینترنت کامپیوتر یک شبکه بلاکچین متن باز می باشد که انعقاد قرارداد های هوشمند را آسان تر و ارزان تر می کند. ارز دیجیتال ICP رمز ارز بومی آن می باشد.",
    keywords: "بررسی ارز دیجیتال ICP | ارزدیجیتال اینترنت کامپیوتر | خرید ICP | فروش اینترنت کامپیوتر | تاریخچه ارز ICP | تحلیل ارز ICP",
    image: indexPhoto18,
    author: "تیم محتوای سورین",
    date: "1402,08,08",
    link: "/What-Is-ICP",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "15"
  },
  {
    id: 19,
    title: "معرفی کامل اندیکاتور و اسیلاتور | آکادمی کریپتوکامرس",
    h1: "با 1 + 10 اندیکاتور و اسیلاتور معروف در تحلیل تکنیکال آشنا شوید!",
    desc: "اندیکاتورها ابزار هایی برای تحلیل تکنیکال هستند که روند قیمت را بررسی می کنند و اسیلاتورها نیز انواع خاصی از اندیکاتور می باشند.",
    keywords: "اندیکاتور و اسیلاتور | انواع اندیکاتور | معروف ترین اندیکاتور ها و اسیلاتور ها | تفاوت اندیکاتور و اسیلاتور | تحلیل تکنیکال",
    image: indexPhoto19,
    author: "تیم محتوای سورین",
    date: "1402,08,10",
    link: "/Introduce-10-Technical-Indicators-And-Oscillators",
    Suggested: "no",
    tag: "Advanced",
    timeRead: "15"
  },
  {
    id: 20,
    title: "معرفی کامل ارز دیجیتال XTZ | آکادمی رمزارز کریپتوکامرس",
    h1: "همه چیز در مورد ارز دیجیتال XTZ  و پیش بینی قیمت تزوس",
    desc: "تزوس پروتکلی برای ساخت و اتصال شبکه‌های بلاک چین سازگار با اتریوم و بیت کوین است که ارز دیجیتال XTZ رمز ارز بومی شبکه تزوس می باشد.",
    keywords: "بررسی آینده ارز دیجیتال XTZ | ارزدیجیتال تزوس |خرید XTZ | فروش تزوس | تاریخچه ارز XTZ | تحلیل ارز XTZ",
    image: indexPhoto20,
    author: "تیم محتوای سورین",
    date: "1402,08,13",
    link: "/What-Is-XTZ",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "12"
  },
  {
    id: 21,
    title: "معرفی کامل شبکه الروند Elrond و رمزارز EGLD  | آکادمی کریپتوکامرس",
    h1: "همه چیز در مورد ارز دیجیتال الروند و پیش بینی قیمت EGLD",
    desc: "پروژه الروند سابق و مولتی ورس ایکس با تمرکز بر فضای متاورس تغییر نام داد و ارز دیجیتال EGLD و رمز ارز بومی MultiversX می باشد.",
    keywords: "بررسی آینده ارز دیجیتال EGLD | ارزدیجیتال الروند |خرید EGLD | فروش الروند | تاریخچه ارز EGLD | تحلیل ارز EGLD",
    image: indexPhoto21,
    author: "تیم محتوای سورین",
    date: "1402,08,21",
    link: "/What-Is-EGLD",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "10"
  },
  {
    id: 22,
    title: "معرفی کامل بیت کوین کش | آکادمی کریپتوکامرس",
    h1: "همه چیز در مورد ارز دیجیتال بیت کوین کش و پیش بینی قیمت BCH",
    desc: "ارز دیجیتال بیت کوین کش یک هارد فورک از بیت‌کوین است که برای ایجاد بلوک‌های بزرگتر در مقایسه با بیت کوین ایجاد شد.",
    keywords: "بررسی آینده ارز دیجیتال BCH | ارزدیجیتال بیت کوین کش |خرید BCH | فروش بیت کوین کش | تاریخچه ارز BCH | تحلیل ارز BCH",
    image: indexPhoto22,
    author: "تیم محتوای سورین",
    date: "1403,02,09",
    link: "/What-Is-BCH",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "13"
  },
  {
    id: 23,
    title: "معرفی کامل هولو | آکادمی کریپتوکامرس",
    h1: "همه چیز در مورد ارز دیجیتال هولو و پیش بینی قیمت HOT",
    desc: "هولو با نماد HOT یک ارز دیجیتال است که در تاریخ 1397لانچ شد و پلتفرمی توزیع شده و همتا به همتا برای میزبانی اپلیکیشن های غیرمتمرکزی می باشد.",
    keywords: "بررسی آینده ارز دیجیتال HOT | ارزدیجیتال هولو |خرید HOT | فروش هولو | تاریخچه ارز HOT | تحلیل ارز HOT",
    image: indexPhoto23,
    author: "تیم محتوای سورین",
    date: "1403,02,12",
    link: "/What-Is-HOT",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "10"
  },
  {
    id: 24,
    title: "معرفی کامل فانتوم | آکادمی کریپتوکامرس",
    h1: "همه چیز در مورد ارز دیجیتال فانتوم و پیش بینی قیمت FTM",
    desc: "فانتوم یک رمز ارز توسعه‌یافته بر پلتفرم فانتوم است که یک محیط قوی، سریع و مقیاس‌پذیر برای توسعه برنامه‌های غیرمتمرکز فراهم می‌کند.",
    keywords: "بررسی آینده ارز دیجیتال FTM | ارزدیجیتال فانتوم |خرید FTM | فروش فانتوم | تاریخچه ارز FTM | تحلیل ارز FTM",
    image: indexPhoto24,
    author: "تیم محتوای سورین",
    date: "1403,02,17",
    link: "/What-Is-FTM",
    Suggested: "no",
    tag: "Intermediate",
    timeRead: "15"
  },
]
