import { Grid, Typography } from "@mui/material";
import faieldEmail from '../image/verify/faieldEmail.png'
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { setCanonicalTag } from "./modules/general";

const NotVerifiedEmail = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // document.title = ""
    // document.getElementById("main-heading").innerHTML = "";
    // document.getElementsByTagName('meta')["description"].content ="";
    // document.getElementsByTagName('meta')["keywords"].content = "";
    setCanonicalTag();
  }, [])
  return (
    <>
      <Grid sx={{
        direction: i18n.language === "fa" ? "rtl" : "ltr", backgroundColor: "background.modalTradeBox", width: "100%", height: "100%",
        display: "flex", alignContent: "center", justifyContent: "center", padding: { xs: "20px", sm: "50px", md: "100px 50px" }
      }}>
        <Grid sx={{ backgroundColor: "background.secondary", width: { xs: "100%", md: "40%" }, height: '100%', padding: { xs: "20px 10px", sm: "30px 40px", md: "50px" }, borderRadius: "13px" }} >
          <Grid sx={{ display: "block", textAlign: "center", paddingTop: "20px" }}>
            <Typography component={"img"} alt="faield_email" src={faieldEmail} sx={{ width: "30%" }} />
          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", padding: "3px", justifyContent: "center" }}>
            <Typography component={"p"} sx={{ fontSize: { xs: "15px", md: "20px" }, fontWeight: "bold", color: "text.primary" }}>
              {t("NotVerifiedEmail")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default NotVerifiedEmail;