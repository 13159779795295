import React from "react";
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, Menu, Switch, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, TextField, Modal, Badge } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../style/nav.css";
import AppsIcon from '@mui/icons-material/Apps';
import RuleRoundedIcon from '@mui/icons-material/RuleRounded';
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, fixedNum, separator } from "./modules/general";
import logo from '../image/logo/logo.png';
import market from '../image/nav/market.png';
import blog from '../image/nav/blog.png';
import change from '../image/nav/change.png';
import contact from '../image/nav/contact.png';
import earn from '../image/nav/earn.png';
import guide from '../image/nav/guide.png';
import language from '../image/nav/language.png';
import loan from '../image/nav/loan.png';
import terms from '../image/nav/terms.png';
import { styled } from '@mui/material/styles';
import light from '../image/nav/light.png';
import dark from '../image/nav/dark.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LanguageIcon from '@mui/icons-material/Language';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import WebIcon from '@mui/icons-material/Web';
import LoginIcon from '@mui/icons-material/Login';
import userType1 from "../image/nav/userType1.png";
import userType2 from "../image/nav/userType2.png";
import userType3 from "../image/nav/userType3.png";
import { EmailSecure } from "./modules/general";
import orders from '../image/nav/orders.png';
import FeedIcon from '@mui/icons-material/FeedOutlined';
import CallIcon from '@mui/icons-material/Call';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import GroupIcon from '@mui/icons-material/Group';
import { DataPanelUser, dataSupporter, dataAdmin } from "./data/PanelData";
import AlertMessage from './modules/AlertMessage';
import OutboxIcon from '@mui/icons-material/Outbox';
import ArchiveIcon from '@mui/icons-material/Archive';
import HistoryIcon from '@mui/icons-material/History';
import WalletIcon from '@mui/icons-material/Wallet';
import gift from "../image/nav/gift.png";


function Navbar({ authCheck, infoUser, darkMode, onchangeDarkMode }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showMenu, setShowMenu] = useState('flex');
  const [auth, setAuth] = useState(false);
  const { t, i18n } = useTranslation();
  const [langBtn, setLangBtn] = useState(false);
  const [anchorProfile, setAnchorProfile] = useState(false);
  const [anchorWallet, setAnchorWallet] = useState(false);
  const [anchorNotification, setAnchorNotification] = useState(false);
  const [openModalExit, setOpenModalExit] = useState(false);
  const [dataNotification, setDataNotification] = useState(null);
  const [dataPanel, setDataPanel] = useState(null)
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });

  const navigate = useNavigate();

  useEffect(() => {
    setAuth(authCheck)
  }, [authCheck]);

  useEffect(() => {
    if (authCheck) { getNotification() }
  }, [authCheck])

  useEffect(() => {
    if (infoUser) {
      infoUser.user_type_id === 4 ? setDataPanel(dataSupporter) : infoUser.user_type_id === 5 ? setDataPanel(dataAdmin) : setDataPanel(DataPanelUser);
    }
  }, [infoUser]);

  // useEffect(() => {
  //   if (infoUser) {
  //     infoUser.user_type_id === 4 ? setDataPanel(dataSupporter) : infoUser.user_type_id === 5 ? setDataPanel(DataPanelUser) : setDataPanel(dataAdmin);
  //   }
  // }, [infoUser]);


  useEffect(() => {
    window.addEventListener('resize', () => resizeMenu());
    return () => {
      window.removeEventListener("resize", () => resizeMenu());
    };
  });

  const handleOpenModalExit = () => setOpenModalExit(true);
  const handleCloseModalExit = () => setOpenModalExit(false);

  const clickAppMenuProfile = () => {
    setAnchorProfile(true);
    anchorWallet && closeAppMenuWallet()
    anchorNotification && closeAppMenuNotification()
  }
  const closeAppMenuProfile = () => {
    setAnchorProfile(false)
  }
  const toggleProfile = () => {
    anchorProfile ? closeAppMenuProfile() : clickAppMenuProfile()
  }

  const clickAppMenuWallet = () => {
    setAnchorWallet(true);
    anchorProfile && closeAppMenuProfile()
    anchorNotification && closeAppMenuNotification()
  }
  const closeAppMenuWallet = () => {
    setAnchorWallet(false)
  }
  const toggleWallet = () => {
    anchorWallet ? closeAppMenuWallet() : clickAppMenuWallet()
  }

  const clickAppMenuNotification = () => {
    setAnchorNotification(true);
    anchorProfile && closeAppMenuProfile()
    anchorWallet && closeAppMenuWallet()
  }
  const closeAppMenuNotification = () => {
    setAnchorNotification(false)
  }
  const toggleNotification = () => {
    anchorNotification ? closeAppMenuNotification() : clickAppMenuNotification()
    mobileMenu && setMobileMenu(false)
  }

  const openAppMenu = Boolean(anchorEl);
  const clickAppMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAppMenu = () => {
    setAnchorEl(null);
    setLangBtn(false);
  };

  function resizeMenu() {
    if (window.innerWidth > 900 && showMenu === '') {
      setShowMenu('none')
      setMobileMenu(false)
    }
  }

  const clickMobileMenu = () => {
    let toggle = !mobileMenu;
    setMobileMenu(toggle);
    setShowMenu('flex')
  };

  const clickOtherLocation = (e) => {
    if (e.target.id === 'mobile_menu') {
      let toggle = !mobileMenu;
      setMobileMenu(toggle);
      setShowMenu('flex')
    }
  }

  const onChangeLanguage = (lang) => {
    if (lang === "en") {
      i18n.changeLanguage("en");
      document.documentElement.setAttribute('lang', 'en');
    } else {
      i18n.changeLanguage("fa");
      document.documentElement.setAttribute('lang', 'fa');
    }
    closeAppMenu();
  };

  const logoutHandler = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v4/user/logout`,
      method: "POST",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        navigate("/")
        window.location.reload();
        localStorage.setItem("TACC", "")
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
        }
        else {
          const { status } = error.response;
          setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
        }
      })
  }

  const DarkMode = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} checked={darkMode} onChange={onchangeDarkMode} />
  ))(({ theme }) => ({
    width: 55,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        left: "11px",
        '& + .MuiSwitch-track': {
          backgroundColor: '#FF9355',
          backgroundImage: `url(${dark})`,
          backgroundPosition: "5px",
          backgroundSize: "45%",
          backgroundRepeat: "no-repeat",
          opacity: 1,
          border: 0,

        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      right: 0
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: "#7CC2C7",
      backgroundImage: `url(${light})`,
      backgroundPosition: "25px",
      backgroundSize: "40%",
      backgroundRepeat: "no-repeat",
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  // const stringToColor = (string) => {
  //   let hash = 0;
  //   let i;
  //   for (i = 0; i < string.length; i += 1) {
  //     hash = string.charCodeAt(i) + ((hash << 5) - hash);
  //   }

  //   let color = '#';
  //   for (i = 0; i < 3; i += 1) {
  //     const value = (hash >> (i * 8)) & 0xff;
  //     color += `00${value.toString(16)}`.slice(-2);
  //   }
  //   return color;
  // }

  const clickOtherLocationModal = (e) => {
    if (e.target.id === 'logout_modal') {
      handleCloseModalExit()
    }
  }

  const getNotification = () => {
    const AuthStr = 'Bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/notification/show",
      method: "GET",
      headers: { 'Authorization': AuthStr }
    }))
      .then((res) => {
        setDataNotification(res.data.data)
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
        }
        else {
          const { status } = error.response;
          setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
        }
      })
  }

  const valueType = (type) => {
    let value;
    switch (type) {
      case ("D"):
        value = "Deposit";
        break;
      case ("W"):
        value = "Withdrawal";
        break;
      case ("T"):
        value = "Trade";
        break;
      case ("C"):
        value = "CancelOrder";
        break;
      case ("F"):
        value = "refundLoan";
        break;
      case ("L"):
        value = "LiquidLoan";
        break;
      default:
        break;
    }
    return value;
  }

  const cardNotification = (id, type) => {
    const AuthStr = 'Bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/notification/view",
      method: "POST",
      headers: { 'Authorization': AuthStr },
      data: { id: id }
    }))
      .then((res) => {
        switch (type) {
          case 'D':
            window.location.href = '/Wallet/History'
            break;
          case 'W':
            window.location.href = '/Wallet/History'
            break;
          case 'T':
            window.location.href = '/Orders/Trade'
            break;
          case 'C':
            window.location.href = '/Orders/CancelOrder'
            break;
          case 'F':
            window.location.href = '/Orders/Loan'
            break;
          case 'L':
            window.location.href = '/Orders/Loan'
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
        }
        else {
          const { status } = error.response;
          setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
        }
      })
  }

  const removeNotification = () => {
    const AuthStr = 'Bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/notification/viewAll",
      method: "POST",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        getNotification()
        closeAppMenuNotification()
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
        }
        else {
          const { status } = error.response;
          setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
        }
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };
  return (

    <>
      <Grid container
        sx={{
          height: "60px", direction: i18n.language === "fa" ? "rtl" : "ltr", display: "flex", zIndex: "200",
          justifyContent: "space-between", alignItems: "center", background: { xs: "unset", md: "linear-gradient(270deg, rgba(124, 194, 199, 0.9) 0%, #F1FFFF 100%)" },
          position: "fixed", top: "0", right: "0", width: "100%", padding: { xs: "10px 20px 10px 5px", sm: "10px 30px 10px 30px" }, overflow: "hidden", backgroundColor: { xs: "text.greenLight", md: "unset" }
        }}
      >
        <Grid item xs={10} sm={10} md={10} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Grid container sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "row-reverse", md: 'row' }, justifyContent: "space-between" }}>
            <Grid item xs={10} md={i18n.language === 'fa' ? 2 : 2.3} lg={i18n.language === "fa" ? 1.5 : 2} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', md: "center" }, }}>
              <NavLink to="/" style={{ textDecoration: "none", display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "center" }}>
                <Grid sx={{ display: "flex", alignItems: "center" }}>
                  <Typography component={"img"} src={logo} alt="logo" sx={{ width: "33px", height: "33px" }} />
                  <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? "18px" : "16px", fontWeight: "700", lineHeight: "20px", color: "text.mcc", marginRight: "3px", display: { xs: "none", sm: "flex" } }}>{t("mccLogo")}</Typography>
                </Grid>
              </NavLink>
            </Grid>
            <Grid item xs={2} md={i18n.language === 'fa' ? 10 : 9.7} lg={i18n.language === "fa" ? 10.5 : 10} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'flex-start', md: "flex-start" } }}>
              <Grid sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}>
                <AppsIcon sx={{ color: "text.primary", fontSize: "35px" }} onClick={clickMobileMenu} />
              </Grid>
              <Grid sx={{ display: { xs: "none", md: "flex" }, flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Grid className="navlink" onClick={clickAppMenu}
                  sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
                >
                  <AppsIcon sx={{ transform: openAppMenu ? " rotateZ(45deg)" : " rotateZ(180deg)", color: "text.primary" }} />
                </Grid>
                <Menu
                  id="nav_menu"
                  sx={{ direction: i18n.language === "fa" ? "rtl" : "ltr", top: "25px !important", left: "0px !important", "& .MuiPaper-root": { borderRadius: "12px", backgroundColor: "background.main" }, "& .MuiMenu-list": { backgroundColor: "background.main" } }}
                  anchorEl={anchorEl}
                  open={openAppMenu}
                  onClose={closeAppMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={i18n.language === "fa" ?
                    {
                      vertical: 'bottom',
                      horizontal: 'right',
                    }
                    :
                    {
                      vertical: 'bottom',
                      horizontal: 'left',
                    }
                  }
                  transformOrigin={i18n.language === "fa" ?
                    {
                      vertical: 'top',
                      horizontal: 'right',
                    }
                    :
                    {
                      vertical: 'top',
                      horizontal: 'left',
                    }
                  }
                >
                  <Grid sx={{ display: "flex", height: "320px", flexDirection: "column", width: "184px", backgroundColor: "background.main" }}>
                    <Link to="/News" style={{ textDecoration: "none", overflow: "hidden" }} onClick={closeAppMenu}>
                      <Grid
                        sx={{
                          display: langBtn ? "none" : "flex", flexDirection: "row", alignItems: "center", padding: "5px 0", transition: "0.3s all", margin: "3px", "&:hover": { background: "background.menuHover", borderRadius: "18px" },
                          '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }
                        }}
                      >
                        <FeedIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 5px" }} />
                        <Typography component={"p"} sx={{ margin: "0 10px", color: "text.primary" }}>
                          {t("news")}
                        </Typography>
                      </Grid>
                    </Link>
                    <Link to="/Blog" style={{ textDecoration: "none", overflow: "hidden" }} onClick={closeAppMenu}>
                      <Grid
                        sx={{
                          display: langBtn ? "none" : "flex", flexDirection: "row", alignItems: "center", padding: "5px 0", transition: "0.3s all", margin: "3px", "&:hover": { background: "background.menuHover", borderRadius: "18px" },
                          '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }
                        }}
                      >
                        <WebIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 5px" }} />
                        <Typography component={"p"} sx={{ margin: "0 10px", color: "text.primary" }}>
                          {t("blog")}
                        </Typography>
                      </Grid>
                    </Link>
                    <Link to="/Guide" style={{ textDecoration: "none", overflow: "hidden" }} onClick={closeAppMenu}>
                      <Grid
                        sx={{
                          display: langBtn ? "none" : "flex", flexDirection: "row", alignItems: "center", padding: "5px 0", transition: "0.3s all", margin: "3px", "&:hover": { background: "background.menuHover", borderRadius: "18px" },
                          '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }
                        }}
                      >
                        <HelpOutlineIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 5px" }} />
                        <Typography component={"p"} sx={{ margin: "0 10px", color: "text.primary" }}>
                          {t("guide")}
                        </Typography>
                      </Grid>
                    </Link>
                    <Link to="/Terms" style={{ textDecoration: "none", overflow: "hidden" }} onClick={closeAppMenu}>
                      <Grid
                        sx={{
                          display: langBtn ? "none" : "flex", flexDirection: "row", alignItems: "center", padding: "5px 0", transition: "0.3s all", margin: "3px", "&:hover": { background: "background.menuHover", borderRadius: "18px" },
                          '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }
                        }}
                      >
                        <RuleRoundedIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 5px" }} />
                        <Typography component={"p"} sx={{ margin: "0 10px", color: "text.primary" }}>
                          {t("terms")}
                        </Typography>
                      </Grid>
                    </Link>
                    <Link to="/ContactUs" style={{ textDecoration: "none", overflow: "hidden" }} onClick={closeAppMenu}>
                      <Grid
                        sx={{
                          display: langBtn ? "none" : "flex", flexDirection: "row", alignItems: "center", padding: "5px 0", transition: "0.3s all", margin: "3px", "&:hover": { background: "background.menuHover", borderRadius: "18px" },
                          '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }
                        }}
                      >
                        <CallIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 5px" }} />
                        <Typography component={"p"} sx={{ margin: "0 10px", color: "text.primary" }}>
                          {t("contact")}
                        </Typography>
                      </Grid>
                    </Link>
                    <Link to="/AboutUs" style={{ textDecoration: "none", overflow: "hidden" }} onClick={closeAppMenu}>
                      <Grid
                        sx={{
                          display: langBtn ? "none" : "flex", flexDirection: "row", alignItems: "center", padding: "5px 0", transition: "0.3s all", margin: "3px", "&:hover": { background: "background.menuHover", borderRadius: "18px" },
                          '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }
                        }}
                      >
                        <GroupIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 5px" }} />
                        <Typography component={"p"} sx={{ margin: "0 10px", color: "text.primary" }}>
                          {t("aboutUs")}
                        </Typography>
                      </Grid>
                    </Link>
                    <Grid onClick={() => setLangBtn(true)}
                      sx={{
                        display: langBtn ? "none" : "flex", flexDirection: "row", alignItems: "center", padding: "5px 0", transition: "0.3s all", margin: "3px", "&:hover": { background: "background.menuHover", borderRadius: "18px" },
                        '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }
                      }}
                    >
                      <LanguageIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 5px" }} />
                      <Typography component={"p"} sx={{ margin: "0 10px", color: "text.primary" }}>
                        {t("chooselang")}
                      </Typography>
                    </Grid>
                    <FormControlLabel
                      disabled
                      control={<DarkMode />}
                      sx={{ display: langBtn ? "none" : "flex", margin: "10px" }}
                    />
                    <Grid sx={{ display: langBtn ? "flex" : "none", flexDirection: "column" }}>
                      <ArrowForwardIosIcon sx={{ fontSize: "13px", cursor: "pointer", paddingRight: "10px", transform: i18n.language === "en" ? "rotateZ(180deg)" : "rotateZ(360deg)" }} onClick={() => setLangBtn(false)} />
                      <TextField variant="standard" fullWidth size="small" placeholder="En"
                        sx={{ width: "140px", margin: "0px 20px", 'input': { padding: "0px", '&::placeholder': { textAlign: "left" } } }}
                        InputProps={{
                          endAdornment: <SearchIcon sx={{ color: "text.grayLight", fontSize: "23px" }} />,
                        }} />
                      <Typography onClick={() => onChangeLanguage('en')} sx={{ fontSize: "16px", textAlign: "center", color: i18n.language === "fa" ? "text.primary" : "text.orange", marginTop: "10px", cursor: "pointer" }}>{t("englishStatic")}</Typography>
                      <Typography onClick={() => onChangeLanguage('fa')} sx={{ fontSize: "16px", textAlign: "center", color: i18n.language === "fa" ? "text.orange" : "text.primary", marginTop: "10px", cursor: "pointer" }}>{t("persianStatic")}</Typography>
                    </Grid>
                  </Grid>
                </Menu>
                <Grid sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                  <NavLink className="navlink" to="/Market">
                    <Typography component={"p"} sx={{ color: "text.primary", fontSize: { md: i18n.language === "fa" ? "13px" : "10px", lg: "16px" } }}>
                      {t("market")}
                    </Typography>
                  </NavLink>
                  <NavLink className="navlink" to="/" >
                    <Typography component={"p"} sx={{ color: "text.primary", fontSize: { md: i18n.language === "fa" ? "13px" : "10px", lg: "16px" } }}>
                      {t("fastChange")}
                    </Typography>
                  </NavLink>
                  <NavLink className="navlink" to="/Earn" style={{ display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "flex" }}>
                    <Typography component={"p"} sx={{ color: "text.primary", fontSize: { md: i18n.language === "fa" ? "13px" : "10px", lg: "16px" } }}>
                      {t("Earn")}
                    </Typography>
                  </NavLink>
                  <NavLink className="navlink" to="/Loan" style={{ display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "flex" }}>
                    <Typography component={"p"} sx={{ color: "text.primary", fontSize: { md: i18n.language === "fa" ? "13px" : "10px", lg: "16px" } }}>
                      {t("loan")}
                    </Typography>
                  </NavLink>
                  <NavLink className="navlink" to="/Orders/Order" style={{ display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "flex" }}>
                    <Typography component={"p"} sx={{ color: "text.primary", fontSize: { md: i18n.language === "fa" ? "13px" : "10px", lg: "16px" }, display: authCheck ? "flex" : "none" }}>
                      {t("orders")}
                    </Typography>
                  </NavLink>
                  <NavLink className="navlink" to="/Prize" style={{ display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "flex" }}>
                    <Typography component={"p"} sx={{ color: "text.primary", fontSize: { md: i18n.language === "fa" ? "13px" : "10px", lg: "16px" }, display: authCheck ? "flex" : "none" }}>
                      {t("myPrice")}
                    </Typography>
                  </NavLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} sm={2} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <Grid sx={{ display: auth ? "none" : { xs: "none", sm: "flex" }, direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center", justifyContent: "space-around" }}>
            <NavLink to={'/Login'} className="nav_link"
              style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: 'center', marginLeft: i18n.language === "fa" ? "10px" : "", marginRight: i18n.language === "en" ? "10px" : "" }}>
              <Button sx={{
                border: "1px solid #3D7884", borderRadius: "23px", backgroundColor: "background.main", boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.25)", width: "86px", height: "37px",
                display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "center", "&:hover": { backgroundColor: "background.modalTradeBox" }
              }}>
                <Typography component={"p"} className="nav_btn" sx={{ color: "text.primary", margin: "0 3px", fontSize: i18n.language === "fa" ? "14px" : "11px" }}>
                  {t("login")}
                </Typography>
                <LoginIcon sx={{ fontSize: "20px", color: "text.primary" }} />
              </Button>
            </NavLink>
            <NavLink to={'/Register'} className="nav_link" style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: 'center' }}>
              <Button sx={{
                background: (theme) => theme.palette.button.register, width: "86px", height: "37px",
                "&:hover": { background: (theme) => theme.palette.button.register }, borderRadius: "23.5px", boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.25)"
              }}>
                <Typography component={"p"} className="nav_btn" sx={{ color: "text.white", margin: "0 3px", fontSize: i18n.language === "fa" ? "14px" : "11px" }}>
                  {t("register")}
                </Typography>
                <PersonAddIcon sx={{ fontSize: "20px", color: "text.white" }} />
              </Button>
            </NavLink>
          </Grid>
          <Grid sx={{ display: auth ? "none" : { xs: "flex", sm: "none" }, direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center" }} >
            <NavLink to={'/Login'} className="nav_link" style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
              <Typography component={"p"} sx={{ color: "text.primary", fontSize: "12px", minWidth: "100px", textAlign: 'center' }}>{t("login")}/{t("register")}</Typography>
            </NavLink>
          </Grid>
          <Grid sx={{ display: auth ? "flex" : "none", alignItems: 'center', flexDirection: "row" }}>
            <Grid sx={{ display: { xs: "none", md: "inline-block" }, padding: "0px 15px", alignItems: 'center' }}>
              <LogoutIcon onClick={handleOpenModalExit}
                sx={{
                  transform: i18n.language === "fa" ? "rotate(180deg)" : "rotate(0deg)",
                  cursor: "pointer", color: openModalExit ? "text.orange" : "text.primary"
                }} />
            </Grid>
            <Grid onClick={toggleNotification} sx={{ display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : { xs: "none", md: "inline-block" }, position: "relative" }}>
              <NotificationsNoneIcon sx={{ color: anchorNotification ? "text.orange" : "text.greenDark", margin: "0px 5px", cursor: "pointer", fontSize: "24px" }} />
              <Badge badgeContent={dataNotification !== null && dataNotification.length > 0 ? dataNotification.length : 0} color="secondary"
                sx={{
                  display: dataNotification !== null && dataNotification.length > 0 ? "flex" : "none", position: "absolute", zIndex: "200", top: "20px", right: "5px",
                  "& .MuiBadge-badge": { backgroundColor: "text.orange", minWidth: "18px", height: "18px", padding: "0 4px" }
                }}>
              </Badge>
            </Grid>
            <Grid onMouseEnter={clickAppMenuWallet} onClick={toggleWallet} sx={{ display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "inline-block" }} >
              <AccountBalanceWalletIcon sx={{ color: anchorWallet ? "text.orange" : "text.primary", margin: "0px 5px", cursor: "pointer" }} />
            </Grid>
            <Grid onMouseEnter={clickAppMenuProfile} onClick={toggleProfile} >
              <AccountCircleIcon sx={{ margin: "0px 5px", color: anchorProfile ? "text.orange" : "text.primary", cursor: "pointer" }} />
            </Grid>
            <Grid onClick={closeAppMenuProfile} id="nav_menu" sx={{
              display: anchorProfile ? "flex" : "none", alignItems: { xs: "flex-end", md: "center" }, flexDirection: "column", width: "100vw", top: "50px", left: "0", position: "fixed", zIndex: "1500",
              height: "100vh", justifyContent: { xs: "flex-end", md: "space-between" }, flexWrap: "nowrap", background: "transparent"
            }}>
              <Grid onMouseLeave={closeAppMenuProfile}
                sx={{
                  display: "flex", alignItems: "center", flexDirection: "column", justifyContent: { xs: "flex-end", md: "space-between" }, width: { xs: "260px", md: "194px" },
                  height: { xs: "100vh", md: "auto" }, top: "0px", left: { xs: "unset", md: i18n.language === "fa" ? "2%" : "unset" }, padding: "10px 0",
                  right: { xs: "unset", md: i18n.language === "en" ? "2%" : "unset" }, position: "absolute", backgroundColor: "background.main", boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)", borderRadius: { xs: "unset", md: "12px" },
                }}>
                <Grid sx={{ display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "flex", flexDirection: i18n.language === "fa" ? "row" : "row-reverse", alignItems: "center", justifyContent: "space-evenly" }}>
                  <Typography component={"p"} lang="en" sx={{ color: "text.primary", fontSize: "12px" }}>{infoUser !== null ? EmailSecure(infoUser.email) : ''}</Typography>
                  <Typography component={"img"} alt="userType"
                    src={infoUser !== null ? infoUser.user_type_id === 1 ? userType1 : infoUser.user_type_id === 2 ? userType2 : infoUser.user_type_id === 3 ? userType3 : null : null} />
                </Grid>
                <Grid item xs={9} sx={{ display: 'flex', width: "100%", flexDirection: "column" }}>
                  {
                    dataPanel !== null &&
                    dataPanel.map((item, index) => (
                      item.text !== "Cards" &&
                      <Link key={index} to={`/Panel/${item.text}`} style={{ textDecoration: "none", overflow: "hidden" }}>
                        <Grid
                          sx={{
                            display: "flex", flexDirection: "row", alignItems: "center", transition: "0.3s all", "&:hover": { backgroundColor: "background.menuHover", borderRadius: "18px" },
                            '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }, marginRight: i18n.language === "fa" ? "5px" : "",
                            marginLeft: i18n.language === "en" ? "5px" : "", padding: { xs: "10px", md: "8px" }
                          }}
                        >
                          <item.icon sx={{ color: "text.primary", fontSize: "25px", margin: "0 5px" }} />
                          <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? "16px" : "13px" }}>
                            {t(item.text)}
                          </Typography>
                        </Grid>
                      </Link>
                    ))
                  }
                </Grid>
                <Grid item xs={3} sx={{ display: { xs: "flex", md: "none" }, alignItems: "center", justifyContent: "flex-start", flexDirection: "column", margin: "0 auto" }}>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "row" }}>
                    <Typography component={"img"} alt="logo" src={logo} sx={{ width: "25px" }} />
                    <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "18px" }, fontWeight: "700", lineHeight: "20px", color: "background.main", marginRight: "3px" }}>{t("mccLogo")}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid onClick={closeAppMenuWallet} id="nav_menu" sx={{
              display: anchorWallet ? "flex" : "none", alignItems: { xs: "flex-end", md: "center" }, flexDirection: "column", width: "100vw", top: "50px", left: "0", position: "fixed", zIndex: "1500",
              height: "100vh", justifyContent: { xs: "flex-end", md: "space-between" }, flexWrap: "nowrap", background: "transparent"
            }}>
              <Grid onMouseLeave={closeAppMenuWallet} sx={{
                display: "flex", alignItems: "center", flexDirection: "column", justifyContent: { xs: "flex-end", md: "space-between" }, width: { xs: "260px", md: "194px" },
                height: { xs: "100vh", md: "220px" }, top: "0px", left: { xs: "unset", md: i18n.language === "fa" ? "3%" : "unset" },
                right: { xs: "unset", md: i18n.language === "en" ? "3%" : "unset" }, position: "absolute", backgroundColor: "background.main", boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)", borderRadius: { xs: "unset", md: "12px" },
              }} >

                <Grid sx={{ display: "flex", flexDirection: i18n.language === "fa" ? "row" : "row-reverse", alignItems: "center", justifyContent: "space-evenly" }}>
                  <Typography lang="en" sx={{ color: "text.primary", fontSize: "12px" }}>{infoUser !== null ? EmailSecure(infoUser.email) : ''}</Typography>
                  <Typography component={"img"} alt="userType"
                    src={infoUser !== null ? infoUser.user_type_id === 1 ? userType1 : infoUser.user_type_id === 2 ? userType2 : infoUser.user_type_id === 3 ? userType3 : null : null} />
                </Grid>
                <Grid item xs={9} sx={{ display: 'flex', width: "100%", flexDirection: "column", paddingBottom: "20px" }}>
                  <Link to="/Wallet/MyAsset" style={{ textDecoration: "none", overflow: "hidden" }}>
                    <Grid
                      sx={{
                        display: "flex", flexDirection: "row", alignItems: "center", transition: "0.3s all", marginRight: i18n.language === "fa" ? "5px" : "",
                        marginLeft: i18n.language === "en" ? "5px" : "", padding: { xs: "10px", md: "8px" }, "&:hover": { backgroundColor: "background.menuHover", borderRadius: "18px" },
                        '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }
                      }}
                    >
                      <WalletIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 3px" }} />
                      <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? "14px" : "13px" }}>
                        {t("MyAsset")}
                      </Typography>
                    </Grid>
                  </Link>
                  <Link to="/Wallet/Deposit" style={{ textDecoration: "none", overflow: "hidden" }}>
                    <Grid
                      sx={{
                        display: "flex", flexDirection: "row", alignItems: "center", transition: "0.3s all", "&:hover": { backgroundColor: "background.menuHover", borderRadius: "18px" },
                        '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }, marginRight: i18n.language === "fa" ? "5px" : "",
                        marginLeft: i18n.language === "en" ? "5px" : "", padding: { xs: "10px", md: "8px" }
                      }}
                    >
                      <ArchiveIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 3px" }} />
                      <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? "14px" : "13px" }}>
                        {t("Deposit")}
                      </Typography>
                    </Grid>
                  </Link>
                  <Link to="/Wallet/Withdrawal" style={{ textDecoration: "none", overflow: "hidden" }}>
                    <Grid
                      sx={{
                        display: "flex", flexDirection: "row", alignItems: "center", transition: "0.3s all", "&:hover": { backgroundColor: "background.menuHover", borderRadius: "18px" },
                        '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }, marginRight: i18n.language === "fa" ? "5px" : "",
                        marginLeft: i18n.language === "en" ? "5px" : "", padding: { xs: "10px", md: "8px" }
                      }}
                    >
                      <OutboxIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 3px" }} />
                      <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? "14px" : "13px" }}>
                        {t("Withdrawal")}
                      </Typography>
                    </Grid>
                  </Link>
                  <Link to="/Wallet/History" style={{ textDecoration: "none", overflow: "hidden" }}>
                    <Grid
                      sx={{
                        display: "flex", flexDirection: "row", alignItems: "center", transition: "0.3s all", "&:hover": { backgroundColor: "background.menuHover", borderRadius: "18px" },
                        '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" }, marginRight: i18n.language === "fa" ? "5px" : "",
                        marginLeft: i18n.language === "en" ? "5px" : "", padding: { xs: "10px", md: "8px" }
                      }}
                    >
                      <HistoryIcon sx={{ color: "text.primary", fontSize: "25px", margin: "0 3px" }} />
                      <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? "14px" : "13px" }}>
                        {t("History")}
                      </Typography>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item xs={3} sx={{ display: { xs: "flex", md: "none" }, alignItems: "center", justifyContent: "flex-start", flexDirection: "column" }}>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row" }}>
                    <Typography component={"img"} alt="logo" src={logo} sx={{ width: "25px" }} />
                    <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "18px" }, fontWeight: "700", lineHeight: "20px", color: "background.main", marginRight: "3px" }}>{t("mccLogo")}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Modal
              open={anchorNotification}
              onClose={closeAppMenuNotification}>
              <Grid onClick={closeAppMenuNotification}
                sx={{
                  display: anchorNotification ? "flex" : "none", alignItems: "center", flexDirection: "column", width: "100%", zIndex: "1500",
                  height: "100vh", justifyContent: "center", flexWrap: "nowrap",
                }}
              >
                <Grid onMouseLeave={closeAppMenuNotification} sx={{
                  display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-start", width: { xs: "90%", sm: "80%", md: "45%", xl: "30%" }, direction: i18n.language === "fa" ? "rtl" : "ltr",
                  background: (theme) => theme.palette.background.between, boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "12px", padding: "20px"
                }} >
                  <Grid sx={{ display: "flex", alignItems: 'flex-start', justifyContent: "flex-start", width: "95%" }}>
                    <CloseRoundedIcon onClick={closeAppMenuNotification} sx={{ fontSize: { xs: "20px", md: "24px" }, color: "text.greenDark", cursor: "pointer" }} />
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: "100%", marginBottom: "10px" }}>
                    <Typography sx={{ fontSize: { xs: "14px", md: "20px" }, color: "text.orange" }}>{t("notifications")}</Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: 'column', width: "100%", maxHeight: { xs: "300px", md: "400px" }, overflow: "scroll" }}>
                    {
                      dataNotification !== null && dataNotification.length > 0 ?
                        dataNotification.map((item, index) => (
                          <Grid onClick={() => cardNotification(item.id, item.type)} key={index} sx={{
                            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", "&:hover": { backgroundColor: "background.menuHover" }, width: "90%",
                            '&:active': { backgroundColor: "background.menuActive" }, padding: "5px", margin: "5px", cursor: "pointer", backgroundColor: "background.secondary", borderRadius: "10px"
                          }}>
                            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", flexDirection: "row", width: "100%" }}>
                              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: { xs: "25%", md: "20%" } }}>
                                <Typography component={"p"} sx={{ fontSize: { xs: '12px', md: "14px" }, color: "text.secondary" }}>{t(valueType(item.type))}</Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: { xs: "25%", md: "20%" } }}>
                                <Typography component={"p"} sx={{ fontSize: { xs: '12px', md: "14px" }, color: "text.secondary" }}>{separator(fixedNum(item.amount, 8))}</Typography>
                              </Grid>
                              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: { xs: "25%", md: "20%" } }}>
                                <Typography component={"p"} sx={{ fontSize: { xs: '12px', md: "14px" }, color: "text.secondary" }}>{item.symbol}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                        :
                        <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                          <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, color: "text.secondary" }}>{t("notNotif")}</Typography>
                        </Grid>
                    }
                  </Grid>
                  <Grid sx={{ display: dataNotification !== null && dataNotification.length > 0 ? "flex" : "none", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "20px" }}>
                    <Typography onClick={removeNotification} sx={{ fontSize: { xs: "14px", md: "18px" }, color: "text.greenDark", cursor: "pointer" }}>{t("deleteNotification")}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Modal>
          </Grid>
        </Grid>
      </Grid >
      <Grid id="mobile_menu" onClick={(e) => clickOtherLocation(e)}
        sx={{
          width: mobileMenu ? "100%" : "0%", height: "100%", transition: "0.4s all ease", direction: i18n.language === "fa" ? "rtl" : "ltr",
          display: showMenu, overflowX: "hidden", backgroundColor: 'rgba(105,99,99,0.6)', zIndex: "800 !important"
        }}
      >
        <Grid sx={{
          width: { xs: '80%', sm: '50%' }, height: "100%", padding: "15px 10px", opacity: mobileMenu ? "1" : "0", transition: mobileMenu ? "opacity 0.9s" : "opacity 0.1s", alignItems: "flex-start",
          direction: i18n.language === "fa" ? "rtl" : "ltr", backgroundColor: "background.mobileMenu", overflow: "scroll", display: 'flex', flexDirection: "column", position: "relative",
          boxShadow: "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px", flexWrap: "nowrap",
        }}>
          <Grid item xs={0.5} sx={{ display: "flex", alignItems: "flex-start", flexDirection: "row", justifyContent: "space-between", padding: { xs: i18n.language === "fa" ? "0px 20px" : "0px", sm: "0px 20px" }, width: "100%" }}>
            <CloseRoundedIcon sx={{ color: "text.primary", fontSize: "38px" }} onClick={clickMobileMenu} />
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
              <NotificationsNoneIcon onClick={toggleNotification} sx={{ color: "text.greenDark", fontSize: "25px", display: auth ? mobileMenu ? { xs: "flex", md: "none" } : "none" : "none" }} />
              <Badge badgeContent={dataNotification !== null && dataNotification.length > 0 ? dataNotification.length : 0} color="secondary"
                sx={{
                  display: dataNotification !== null && dataNotification.length > 0 ? "flex" : "none", position: "absolute", zIndex: "200", top: "20px", right: i18n.language === "fa" ? "0px" : "unset", left: i18n.language === "en" ? "0px" : "unset",
                  "& .MuiBadge-badge": { backgroundColor: "text.orange", minWidth: "18px", height: "18px", padding: "0 4px" }
                }}>
              </Badge>
            </Grid>
          </Grid>
          <Grid item xs={9} sx={{ display: "flex", flexDirection: "column", padding: { xs: "0px 30px 0px 10px", sm: "10px 30px" }, width: '100%' }}>
            <Link to="/Market" style={{ textDecoration: "none" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="market" src={market} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("market")}
                </Typography>
              </Grid>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="change" src={change} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("fastChange")}
                </Typography>
              </Grid>
            </Link>
            <Link to="/Earn" style={{ textDecoration: "none", display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "flex" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="earn" src={earn} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("Earn")}
                </Typography>
              </Grid>
            </Link>
            <Link to="/Loan" style={{ textDecoration: "none", display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "flex" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="loan" src={loan} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("loan")}
                </Typography>
              </Grid>
            </Link>
            <Link to="/Orders/Order" style={{ textDecoration: "none", display: infoUser && infoUser.user_type_id !== 4 && infoUser.user_type_id !== 5 ? "flex" : "none" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="orders" src={orders} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("orders")}
                </Typography>
              </Grid>
            </Link>
            <NavLink to="/Prize" style={{ display: infoUser && infoUser.user_type_id === 4 ? "none" : infoUser && infoUser.user_type_id === 5 ? "none" : "flex", textDecoration: "none", }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="orders" src={gift} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("myPrice")}
                </Typography>
              </Grid>
            </NavLink>
            <Link to="/News" style={{ textDecoration: "none" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <FeedIcon sx={{ color: "text.primary", fontSize: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("news")}
                </Typography>
              </Grid>
            </Link>
            <Link to="/Blog" style={{ textDecoration: "none" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="blog" src={blog} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("blog")}
                </Typography>
              </Grid>
            </Link>
            <Link to="/Guide" style={{ textDecoration: "none" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="guide" src={guide} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("guide")}
                </Typography>
              </Grid>
            </Link>
            <Link to="/Terms" style={{ textDecoration: "none" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="terms" src={terms} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("terms")}
                </Typography>
              </Grid>
            </Link>
            <Link to="/contactUs" style={{ textDecoration: "none" }} onClick={clickMobileMenu}>
              <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: { xs: "12px 0", sm: "15px 0" }, '&:active': { backgroundColor: "background.menuActive", borderRadius: "18px" } }}>
                <Typography component={"img"} alt="contact" src={contact} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                  {t("contact")}
                </Typography>
              </Grid>
            </Link>
            <Grid sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', padding: { xs: "12px 0", sm: "15px 0" } }}>
              <Accordion sx={{ boxShadow: "none", border: 'none', backgroundColor: "transparent" }}>
                <AccordionSummary sx={{ minHeight: "2px !important", padding: "0px !important", ".MuiAccordionSummary-content": { margin: "0px !important" } }}
                >
                  <Typography component={"img"} alt="language" src={language} sx={{ width: { xs: "18px", sm: "23px" }, marginLeft: i18n.language === "fa" ? "10px" : "0px", marginRight: i18n.language === "en" ? "10px" : "0px" }} />
                  <Typography component={"p"} sx={{ color: "text.primary", fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px" } : "14px" }}>
                    {t("chooselang")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px 15px !important" }}>
                  <Typography onClick={() => onChangeLanguage('fa')} sx={{ padding: "10px", color: i18n.language === "fa" ? "text.orange" : "text.primary", fontSize: { xs: "12px", sm: "16px" } }}>فارسی</Typography>
                  <Typography onClick={() => onChangeLanguage('en')} sx={{ padding: "0px 10px", color: i18n.language === "en" ? "text.orange" : "text.primary", fontSize: { xs: "12px", sm: "16px" } }}>English</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: auth ? { xs: "space-between", sm: "flex-start" } : "flex-start", alignItems: 'center', padding: { xs: "8px 0", sm: "10px 0" } }}>
              <FormControlLabel
                disabled

                // onChange={() => setDarkMode(!darkMode)}
                control={<DarkMode />}
                sx={{ margin: "0" }}
              />
              <Grid onClick={handleOpenModalExit} sx={{ display: auth ? { xs: "flex", sm: "none" } : "none", justifyContent: 'flex-end', alignItems: "flex-end", flexDirection: "row", margin: "0 20px" }}>
                <LogoutIcon sx={{ color: "text.grayDark", fontSize: "20px" }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "column", width: "100%", height: "100%" }}>
            <Grid onClick={handleOpenModalExit}
              sx={{ display: auth ? { xs: "none", sm: "flex", md: "none" } : "none", justifyContent: 'center', alignItems: "center", flexDirection: "row", marginBottom: "10px", width: "200px" }}>
              <LogoutIcon sx={{ color: "text.grayDark", fontSize: "18px", margin: "3px" }} />
              <Typography sx={{ color: "text.grayDark", fontSize: "13px" }}>{t("logOut")}</Typography>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "row", paddingTop: "10px" }}>
              <Typography component={"img"} alt="logo" src={logo} sx={{ width: "25px" }} />
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "18px" }, fontWeight: "700", lineHeight: "20px", color: "text.mcc", marginRight: "3px" }}>{t("mccLogo")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openModalExit}
        onClose={handleCloseModalExit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid onClick={(e) => clickOtherLocationModal(e)} id="logout_modal" sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)' }}>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column', background: (theme) => theme.palette.background.modalBack, width: "253px",
            height: "169px", borderRadius: "12px",
          }}>
            <Typography component={"p"} sx={{ fontSize: "16px", color: "text.primary" }}>{t("logOutModal")}</Typography>
            <Grid sx={{ display: 'flex', alignItems: "center", flexDirection: "row", margin: "15px 0 0" }}>
              <Button onClick={handleCloseModalExit} sx={{
                width: "75px", height: "30px", background: (theme) => theme.palette.button.secondary,
                boxShadow: " 0.5px 0.5px 3px rgba(0, 0, 0, 0.4)", borderRadius: "21.5px", fontSize: "14px", color: "text.white",
                "&:hover": { background: (theme) => theme.palette.button.secondaryHover }, marginRight: "15px"
              }}>
                {t("no")}
              </Button>
              <Button onClick={logoutHandler} sx={{
                width: "75px", height: "30px", backgroundColor: "transparent", border: "1px solid #136B7D",
                fontSize: "14px", color: "text.primary", borderRadius: "21.5px", "&:hover": { backgroundColor: "transparent" }
              }}>
                {t("yes")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleClose} severity={alert.severity} page={"Navbar"} />
    </>
  )
}

export default Navbar;