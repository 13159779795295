import React, { useState, useEffect, createContext } from 'react';
// import { allInformationCoin } from '../services/api';
import AlertMessage from '../component/modules/AlertMessage';
import { useTranslation } from "react-i18next";
import { allInformationCoin } from '../component/modules/AllInformationCoin';


export const infoCoinContext = createContext();
const CryptoContextProvider = ({ children }) => {
  const [infoCoin, setInfoCoin] = useState(allInformationCoin);
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const { t, i18n } = useTranslation();


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     getInformationCoin();
  //   }, 5000);
  //   return () => clearTimeout(timer);
  // }, [])

  // const getInformationCoin = () => {
  //   allInformationCoin().then((res) => setInfoCoin(res)).catch((error) => {
  //     if (error.code === "ERR_NETWORK") {
  //       setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
  //     }
  //     else {
  //       const { status } = error.response;
  //       setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
  //     }
  //   });
  // }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };
  return (
    <>
      <infoCoinContext.Provider value={infoCoin}>
        {children}
      </infoCoinContext.Provider>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleClose} severity={alert.severity} page={"Withdrawal"} />
    </>
  )
}

export default CryptoContextProvider;