import axios from "axios";
import { baseUrl } from "../component/modules/general";

const authCheck = async () => {
  const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
  const response = Promise.resolve(axios({
    url: `${baseUrl}/api/v4/user/authCheck`,
    method: "GET",
    headers: { 'Authorization': AuthStr },
  }))
    .then((response) => {
      return (response.data.data)
    })
    .catch(
      (error) => {
        if (error.hasOwnProperty('status')) {
          const { status } = error.status;
          if ((status > 500) || (status < 600)) {
            const timeout = setTimeout(() => {
              return Promise.reject(error)
            }, 15000)
            return () => {
              clearTimeout(timeout)
            };
          }
        }
        else {
          return (Promise.reject(error));
        }
      }
    )
  return (response);
}

// const allInformationCoin = async () => {
//   const response = Promise.resolve(axios({
//     url: `${baseUrl}/api/v4/general/allInformationCoin`,
//     method: "GET"
//   }))
//     .then((response) => {
//       return (response.data)
//     })
//     .catch(
//       (error) => {
//         const { status } = error.response;
//         if ((status > 500) || (status < 600)) {
//           const timeout = setTimeout(() => {
//             return Promise.reject(error)
//           }, 15000)
//           return () => {
//             clearTimeout(timeout)
//           };
//         }
//       }
//     )
//   return (response);
// }
const getInfoUser = () => {
  const AuthStr = 'Bearer ' + (localStorage.getItem('TACC'));
  const Response = Promise.resolve(axios({
    url: baseUrl + "/api/v4/user/get",
    method: "GET",
    headers: { 'Authorization': AuthStr }
  }))
    .then((res) => {
      return (res.data.data)
    })
  return Response;
}

const getAllBookTickerWithIrr = () => {
  const Response = Promise.resolve(axios({
    url: baseUrl + "/api/v4/general/allBookTickerWithIrr",
    method: "GET",
  }))
    .then((res) => {
      return (res.data.data)
    })
  return Response;
}

const allProduct = () => {
  const Response = Promise.resolve(axios({
    url: baseUrl + "/api/v4/general/allProduct",
    method: "GET",
  }))
    .then((res) => {
      return (res.data.data)
    })
  return Response;
}



export { authCheck, getInfoUser, getAllBookTickerWithIrr, allProduct };