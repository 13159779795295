import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import StorefrontIcon from '@mui/icons-material/Storefront';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CancelIcon from '@mui/icons-material/Cancel';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import BlockIcon from '@mui/icons-material/Block';
import OutboxIcon from '@mui/icons-material/Outbox';
import ArchiveIcon from '@mui/icons-material/Archive';
import HistoryIcon from '@mui/icons-material/History';
import WalletIcon from '@mui/icons-material/Wallet';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

export const DataPanelUser = [
  {
    id: 1,
    text: "Dashboard",
    icon: DashboardOutlinedIcon
  },
  {
    id: 2,
    text: "UserInformation",
    icon: InfoOutlinedIcon
  },
  {
    id: 3,
    text: "Authentication",
    icon: VerifiedOutlinedIcon
  },
  {
    id: 4,
    text: "Cards",
    icon: CreditCardOutlinedIcon,
  },
  {
    id: 5,
    text: "Privacy",
    icon: PrivacyTipOutlinedIcon,
  },
  {
    id: 6,
    text: "InviteFriends",
    icon: Diversity1Icon,
  },
  {
    id: 7,
    text: "Ticket",
    icon: ConfirmationNumberOutlinedIcon,
  },
]

export const dataSupporter = [
  {
    id: 1,
    text: "NewUser",
    icon: AccountCircleOutlinedIcon
  },
  {
    id: 2,
    text: "SearchUser",
    icon: PersonSearchRoundedIcon
  },
  {
    id: 3,
    text: "Ticket",
    icon: ConfirmationNumberOutlinedIcon,
  },
  {
    id: 4,
    text: "SupporterPrize",
    icon: CardGiftcardIcon
  },
  {
    id: 5,
    text: "OperationHistory",
    icon: HistoryIcon
  },
  {
    id: 6,
    text: "WalletAccounting",
    icon: AccountBalanceWalletIcon
  },
  {
    id: 7,
    text: "RecentTrades",
    icon: WorkHistoryIcon
  }
]

export const dataAdmin = [
  {
    id: 1,
    text: "NewUser",
    icon: AccountCircleOutlinedIcon
  },
  {
    id: 2,
    text: "SearchUser",
    icon: PersonSearchRoundedIcon
  },
  {
    id: 3,
    text: "WithdrawalAdmin",
    icon: BorderColorIcon,
  },
  {
    id: 5,
    text: "Loan",
    icon: RequestPageIcon,
  },
  {
    id: 6,
    text: "Blocked",
    icon: BlockIcon
  },
  {
    id: 7,
    text: "Prize",
    icon: CardGiftcardIcon
  },
  {
    id: 8,
    text: "OperationHistory",
    icon: HistoryIcon
  },
  {
    id: 9,
    text: "WalletAccounting",
    icon: AccountBalanceWalletIcon
  },
  {
    id: 10,
    text: "RecentTrades",
    icon: WorkHistoryIcon
  }
]



export const DataWallet = [
  {
    id: 1,
    icon: WalletIcon,
    text: "MyAsset"
  },
  {
    id: 2,
    icon: ArchiveIcon,
    text: "Deposit",
    dropDown: ["Cryptocurrency", "Rials"]
  },
  {
    id: 3,
    icon: OutboxIcon,
    text: "Withdrawal",
    dropDown: ["Rials", "Cryptocurrency", "InternalWithdraw"]
  },
  {
    id: 4,
    icon: HistoryIcon,
    text: "History",
  },
]
export const DataOrder = [
  {
    id: 1,
    icon: BorderColorIcon,
    text: "Order"
  },
  {
    id: 2,
    icon: StorefrontIcon,
    text: "Market"
  },
  {
    id: 3,
    icon: OpenInNewIcon,
    text: "OpenOrder"
  },
  {
    id: 4,
    icon: CancelIcon,
    text: "CancelOrder",
  },
  {
    id: 4,
    icon: CurrencyExchangeIcon,
    text: "Trade",
  },
  {
    id: 5,
    icon: PublishedWithChangesIcon,
    text: "Conversions",
  },
  {
    id: 6,
    icon: RequestPageIcon,
    text: "Loan"
  },
  {
    id: 7,
    icon: StackedLineChartIcon,
    text: "Earn"
  }
]