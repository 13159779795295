import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button, Checkbox, CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { emailLoginValidate } from "./modules/validate";
import { NavLink } from "react-router-dom";
import signinIcon from '../image/register/signin.png';
import axios from "axios";
import { baseUrl } from "./modules/general";
import { useNavigate } from "react-router-dom";
import AlertMessage from './modules/AlertMessage';
import { setCanonicalTag } from "./modules/general";
const Login = () => {
  const [captcha, setCaptcha] = useState(false);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("en");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const [loading, setLoading] = useState(false);
  const [loginBtn, setLoginBtn] = useState(false);
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const Navigate = useNavigate();

  useEffect(() => {
    i18n.language === "fa" ? setLang("fa") : setLang("en")
  }, [lang])

  useEffect(() => {
    document.title = "ورود امن به معتبرترین صرافی ارز دیجیتال ایران | بزرگترین بازار رمزارز ایرانی";
    document.getElementById("main-heading").innerHTML = "ورود ایمن به بهترین صرافی ارز دیجیتال ایران ؛ کریپتوکامرس";
    document.getElementsByTagName('meta')["description"].content = "ورود امن به کریپتوکامرس ؛ بزرگترین صرافی ارز دیجیتال ایران با ایمیل معتبر و رمز یکبار مصرف امکان پذیر است.";
    document.getElementsByTagName('meta')["keywords"].content = "ورود به حساب کاربری صرافی کریپتوکامرس | ورود به بزرگترین بازار ارز دیجیتال ایران | ورود امن به اولین صرافی حرفه ای ایرانی | ورود آسان و سریع به کریپتوکامرس | ورود به پنل کاربری کریپتوکامرس";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag();
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  function resizeTextFiled() {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }
  //focus in textFiled
  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true })
  }
  //onChange Recaptcha
  const onChangeRechapta = (value) => {
    value != null ? setCaptcha(true) : setCaptcha(false)
  }
  const emailHandeler = (event) => {
    setErrors(emailLoginValidate(event.target.value, t))
    setEmail(event.target.value);
  }
  //submit Button SignIn 
  const submitHandler = () => {
    let existEmail;
    if ((Object.keys(errors).length) || (email === "")) {
      setAlert({ alertCheck: true, message: t("AM_email_invalide"), severity: "error" })
    }
    else if (!Object.keys(errors).length) {
      setLoginBtn(true);
      setLoading(true);
      Promise.resolve(axios({
        url: baseUrl + "/api/v4/user/existEmail?email=" + email,
        method: "GET",
      }))
        .then((res) => {
          setLoginBtn(false);
          setLoading(false);
          existEmail = res.data.data;
          if ((existEmail === false) && (captcha === true)) {
            Navigate("/Login/" + email)
          }
          else if (captcha === false) {
            setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
          }
          else if (existEmail === true) {
            setAlert({ alertCheck: true, message: t("AM_NotRegister"), severity: "warning" })
            const timeout = setTimeout(() => {
              Navigate("/Register")
            }, 3000)
            return () => {
              clearTimeout(timeout)
            };
          }
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
          }
        })
    }
  }
  //landle close Alert
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  return (
    <>
      <Grid sx={{
        direction: i18n.language === "fa" ? "rtl" : "ltr", width: "100%", height: "100vh", justifyContent: "center",
        display: "flex", flexDirection: { xs: "column", sm: "row" }, alignContent: "center", padding: { xs: "160px 0", sm: "260px 0", md: "250px 0" }, backgroundColor: "background.main"
      }}>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, height: { sm: "400px", md: "500px" }, display: "flex", justifyContent: { xs: "center", md: "end" } }}>
          <Grid sx={{
            backgroundColor: "background.secondary", maxWidth: { xs: "95%", sm: "306px", md: "478px" }, minWidth: { xs: "95%", sm: "280px", md: "390px" }, height: '100%',
            boxShadow: "1px 3px 6px rgba(0, 0, 0, 0.25)", borderRadius: { xs: "44px", sm: "50px", md: "15%" }, padding: { xs: "0", sm: "10% 0", md: "5% 0" }
          }}>
            <Grid sx={{ display: "flex", flexDirection: "row", padding: "10% 0 5%", alignItems: "center", justifyContent: "center" }}>
              <Typography component={"p"} sx={{
                fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px", md: "25px" } : { xs: "17px", md: "15px", lg: "20px" }, color: "textprimary"
              }}>{t("titleLogin")}
              </Typography>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px", md: "25px" } : { xs: "17px", md: "15px", lg: "20px" },
                color: "text.greenDark", margin: i18n.language === "fa" ? "0 5px 0 0" : "0 0 0 5px"
              }}>{t("mcc")}
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", padding: "5% 0 0" }} >
              <TextField
                className="textField"
                fullWidth
                label={t("email")}
                name="email"
                value={email}
                // inputMode="email"
                type="email"
                size={textFieldSize}
                onChange={event => emailHandeler(event)}
                onFocus={focusHandler}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email && errors.email}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                variant="outlined"
                sx={{ "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" }, minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" } }}
              />
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", margin: "5% 0" }} className="g-recaptcha-inner">
              <ReCAPTCHA
                sitekey="6Le7R9UUAAAAAFklnUhPVV2qiSfeLXJHpDgnv6V3"
                onChange={(value) => { onChangeRechapta(value) }}
                className="g-recaptcha-login"
                hl={lang}
              />
            </Grid>
            <Grid sx={{ margin: "0 0 5%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button sx={{
                width: { xs: "50%", sm: "173px", md: "270px" }, height: { xs: "41px", md: "63px" }, background: (theme) => theme.palette.button.primary, borderRadius: "40px !important",
                "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.3)"
              }}
                onClick={submitHandler} disabled={loginBtn ? true : false}>
                <Typography component={"p"} sx={{ fontSize: { xs: "11px", sm: "13px", md: "20px" }, color: "text.white", lineHeight: "18px", padding: { xs: "5px", sm: "10px" } }}>
                  {loginBtn === true && loading === true ? <CircularProgress size={20} color="inherit" /> : t("login")}
                </Typography>
              </Button>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "0 0 10%" }}>
              <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "8px", sm: "10px", md: "16px" } : { xs: "13px", sm: "11px" }, margin: i18n.language === "fa" ? "0 0 0 5px" : "0 5px 0 0" }}>{t("NotRegister")}</Typography>
              <NavLink to="/register" className="loginNav">
                <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "8px", sm: "10px", md: "16px" } : { xs: "13px", sm: "11px" }, color: "text.greenDark", borderBottom: "1px solid #0C5463" }}>{t("signUpNow")}</Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, display: "flex", justifyContent: { xs: "center", md: "start" }, margin: { xs: "0px", md: "0px 30px" } }}>
          <Grid sx={{
            width: { xs: "100%", sm: "98%", md: "95%", lg: "80%" }, display: "flex", padding: { xs: "30px 0px", sm: "0px 2%", md: "20px 5%", lg: "30px" },
            flexDirection: "column", alignItems: "center"
          }}>
            <Grid sx={{ width: "100%", display: { xs: "none", sm: "flex" }, alignItems: "center", justifyContent: "center" }}>
              <Typography component={"img"} alt="signin_Icon" src={signinIcon} sx={{ maxWidth: { sm: "200px", md: "200px" }, maxHeight: { sm: "200px", md: "200px" }, minWidth: { sm: "180px", md: "200px" }, minHeight: { sm: "180px", md: "200px" } }} />
            </Grid>
            <Grid sx={{
              display: "flex", flexDirection: "column", alignItems: "start", minWidth: { xs: "95%", sm: "220px", md: "360px" }, maxWidth: { xs: "95%", sm: "328px", md: "512px" },
              borderRadius: { xs: "18px", sm: "25px", md: "35px" }, maxHeight: { xs: "160px", sm: "174px", md: "272px" }, backgroundColor: "background.secondary", boxShadow: ' 1px 3px 6px rgba(0, 0, 0, 0.25)',
              padding: { xs: i18n.language === "fa" ? "20px 10px 20px 20px" : "20px 20px 10px 20px", sm: i18n.language === "fa" ? "30px 20px 30px 30px" : "20px 20px 20px 10px" }
            }}>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "15px" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }} >{t("roulsPart1")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography component={"p"} sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }}>{t("roulsPart2")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: "none" }}>
          <Typography sx={{ display: "none" }}>شما در حال ورود به بزرگترین و معتبرترین صرافی ارز دیجیتال ایرانی یعنی صرافی رمزارز کریپتوکامرس هستید. بدین معنی که شما قبلا در معتبرترین صرافی ارز دیجیتال ایران ثبت نام کردید.  اکنون تمایل دارید به حساب کاربری خود در کریپتوکامرس اولین صرافی تمام حرفه ای ارز دیجیتال ایرانی وارد شوید.</Typography>
          <Typography sx={{ display: "none" }}> پس از ساخت حساب شما می توانید هر زمان به حساب کاربری خود ورود کنید. ما برای ورود امن و ایمن شما به پنل کاربری صرافی دو مرحله امنیتی تدارک دیده ایم.</Typography>
          <Typography sx={{ display: "none" }}> مرحله اول رمز یکبار مصرف اپلیکیشن گوگل اتنتیکیتور است . این اپلیکیشن برای ورود امن شما به صرافی بزرگ رمزارز کریپتوکامرس تعبیه شده است. دومین مرحله برای ارتقای امنیت حساب کاربری شما در بهترین صرافی ارز دیجیتال ایران رمز یکبار مصرف پیامکی پیاده سازی شده است. رمز یکبار مصرف پیامکی یکی از راه های احراز هویت کاربر توسط شماره همراه به نام خودش می باشد. لازم به ذکر است برای کار و فعالیت در بزرگترین بازار رمزارز ایرانی باید احراز هویت خود را تکمیل کنید. برای ورود به صرافی باید دکمه ورود را فشار دهید.  سپس ایمیل کاربری خود که پیش تر با آن در بزرگترین صرافی ارز دیجیتال ثبت نام کردید را وارد کنید. سپس رمز ورود به حساب کاربری خود در کریپتوکامرس را وارد نمایید. حتما به دامنه صرافی کریپتوکامرس دقت کنید که دقیقا این عبارت باشد: .https://mycryptocommerce.com </Typography>
          <Typography sx={{ display: "none" }}>سپس برای ورود امن به بهترین صرافی ارز دیجیتال ایرانی لطفا هر دو مرحله امنیتی گوگل اتنتیکیتور و پیامکی را فعال کنید. تا حساب کاربری و پنل شخصی شما در معتبرترین صرافی رمزارز ایرانی به بالاترین رتبه امنیتی وارد شود. </Typography>
          <Typography sx={{ display: "none" }}> درصورتی که هر دو مرحله امنیتی حساب کاربری خود را در کریپتوکامرس؛ قدیمی ترین بازار خرید و فروش ارز دیجیتال در ایران فعال کرده باشید نیاز است تا هنگام ورود هم رمز شش رقمی گوگل اتنتیکیتور و رمز شش رقمی پیامکی را ثبت کنید. بعد از ورود موفق به پنل کاربری خود در صرافی رمز ارز کریپتوکامرس، صفحه اصلی سایت که دارای قسمت تبدیل سریع می باشد را مشاهده می فرمایید. قسمت های متنوعی برای شما تعبیه شده است. برای مثال قسمت دارایی های من و تاریخچه واریز و برداشت دارایی های شما در پنل کاربری شما نمایان است. هم چنین برای ورود به سایت رسمی شرکت وب گستران سورین ، حامی مادی و معنوی صرافی بزرگ ارز دیجیتال کریپتوکامرس به فوتر سایت مراجعه فرمایید. سپس  نام " وب گستران سورین" را کلیک کنید تا به وب سایت رسمی شرکت سورین ؛ طراح و پیاده ساز انواع سایت، اپلیکیشن، پلتفرم، سئو و ارتقای رتبه گوگل، برنامه نویسی اختصاصی، پروژه کدنویسی دانشگاهی، طراحی رابط کاربری و تجربه کاربری و ... وارد شوید.</Typography>
          <Typography sx={{ display: "none" }}> ورود به بازار فروش و خرید ارز دیجیتال یعنی ورود امن به معتبرترین صرافی ارز های دیجیتال در کشور است. ورود به دنیای پر رمز و راز دنیای رمزارز فقط با صرافی امن کریپتوکامرس امکان پذیر است. با انتخاب صرافی کریپتوکامرس و ورود به بازار رمزارزها با خیال راحت به معامله جفت ارزهای دلخواه خود بپردازید. با ثبت نام و ورود به صرافی امن کریپتوکامرس از مزایای زیر استفاده بفرمایید. خرید و فروش بیش از 400 ارز دیجیتال معتبر جهان روا، مبادله و نوسان گیری در بیش از 1500 جفت ارز مبادلاتی جهانی، ترید اهرم دار ارز دیجیتال های معتبر؛ قابلیت های معاملاتی صرافی کریپتوکامرس است. عمق بالای بازارهای کریپتوکامرس هرنوع مبادله با هر حجمی را پشتیبانی می کند. از زیبایی های ورود به بهترین صرافی ارز دیجیتال ایران ؛ کریپتوکامرس میتوان به درامدزایی از ارز دیجیتال اشاره کرد. شما می توانید با انتخاب پلن، ارز دیجیتال تتر را به مدت معلوم قفل کنید تا به کاربران مشتاق وام تعلق گیرد. از بهره وام دریافتی از معامله گرها، درصدی به کاربران استیکینگ و درصدی به صرافی تعلق می گیرد.</Typography>
          <Typography sx={{ display: "none" }}> هم چنین کاربران ثبت نامی می توانند به صورت حضوری به شرکت وب گستران سورین مراجعه کرده و ارز دیجیتال خود را به صورت حضوری خریداری کنند. برای ورود و کار در بزرگترین بازار ارز دیجیتال ایران تنها یک ایمیل و شماره همراه نیاز دارید. کریپتوکامرس که طبق دستورات پلیس فتا عمل می کند سعی داشته از ورود اتباع بیگانه جلوگیری کند. پس ورود اتباع بیگانه و افراد ایرانی زیر 18 سال به صرافی امن کریپتوکامرس ممنوع است. بهترین روش برای ورود به صرافی کریپتوکامرس، جستجوی نام بهترین صرافی ارز دیجیتال کریپتوکامرس می باشد تا به وب سایت رسمی صرافی رمز ارز کریپتوکامرس برخورد کنید. برای ورود به کریپتوکامرس، پذیرفتن قوانین و مقررات سایت الزامی می باشد. لطفا پس از مطالعه کامل صفحه مربوط به قوانین و مقررات پنل کاربری و فعالیت در بازار امن خرید و فروش ارز دیجیتال کریپتوکامرس، تیک مطالعه و پذیرش را بزنید. هم چنین برای ورود به صرافی ذکر و یاد خدارا فراموش نکنید. سعی کنید همیشه شکرگذار نعمت های خدا باشید. برای دریافت راهنمایی برای ثبت نام، ورود و کار با صرافی حرفه ای ارز دیجیتال کریپتوکامرس با ما در ارتباط باشید.</Typography>
        </Grid>
      </Grid>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleClose} severity={alert.severity} page={"Login"} />
    </>
  );
}
export default Login;